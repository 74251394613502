import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { ApiContainer } from "../utils/api";
import {
  LOADING_CHANGE,
  ON_FORM_CHANGE,
  SET_API_DATA,
} from "../redux/constants";
import { equal } from "../utils/javascript";
import { adminPoints, assessmentIds, endPoints } from "../utils/constant";
import { tabs } from "../description/dashboard.description";
import { toggleModelShow } from "../shared/CustomFunc";

export const assessmentReportContainer = ({ getFrom }) => {
  const searchRef = useRef(null);
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const [currentRow, setCurrentRow] = useState({});
  const [showDialog, setShowDialog] = useState(false);

  const [deleteRow, setDeleteRow] = useState(null);
  const activeTab =
    useSelector((state) => state.api?.tabs?.summary) || tabs?.[0]?.value;
  const { form, modelStates } = useSelector((state) => state);
  const loaders = useSelector((state) => {
    return {
      [activeTab]: state.api?.loader?.[activeTab],
      csvLoader: state.api?.loader?.csvLoader,
      refetchOrgData: state.api?.loader?.refetchOrgData,
      expandRowOfOrg: state.api?.loader?.expandRowOfOrg,
      getAssessment: state.api?.loader?.getAssessment,
      organizationTypesLoader: state.api?.loader?.organizationTypesLoader,
    };
  });
  const apiLoaders = useSelector((state) => state?.api?.loader);
  const filters = useSelector((state) => state.form.formData?.[activeTab]);
  const commonFilter = useSelector(
    (state) => state.form.formData?.commonFilter
  );
  const assessmentReports = useSelector(
    (state) => state.api?.[activeTab]?.data
  );
  const deleteUser = useSelector((state) => state?.api?.loader?.deleteUser);
  const paginationData = useSelector((state) => state.api?.[activeTab]?.page);
  const orgFilters = useSelector((state) => state.api?.orgFilters);
  const orgType = useSelector((state) => state.api?.orgType);
  const locationFilters = useSelector((state) => state.api?.locationFilters);
  const { accessOfAssessmentsForOrg } = useSelector((state) => state.api);
  const reportData = useSelector((state) => state.api?.[activeTab]);

  const getOrgFilter = async () => {
    const data = await api({
      method: "GET",
      endPoint: adminPoints?.organizationDetail,
      showToastMessage: false,
      urlencoded: false,
    });
    if (data?.status) {
      let orgFilters = [];
      orgFilters = [
        ...orgFilters,
        ...data.data.map(({ name, original_id }) => ({
          key: `${name} (${original_id})`,
          value: original_id,
        })),
      ];
      dispatch({
        type: SET_API_DATA,
        payload: {
          orgFilters,
        },
      });
    }
  };

  const getLocationFilter = async (id) => {
    const data = await api({
      method: "GET",
      endPoint: `organization/${id}/locations/list`,
      showToastMessage: false,
      urlencoded: false,
      parent: "locationFilters",
    });
    if (data?.status) {
      let locationFilters = [];
      locationFilters = [
        ...locationFilters,
        ...data.data.map(({ location_name, location_original_id }) => ({
          key: `${location_name} (${location_original_id})`,
          value: location_original_id,
        })),
      ];
      dispatch({
        type: SET_API_DATA,
        payload: {
          locationFilters,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          locationFilters: [],
        },
      });
    }
  };

  const getOrgTypeFilter = async () => {
    const data = await api({
      method: "GET",
      endPoint: adminPoints?.organizationType,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "organizationTypesLoader",
    });
    if (data?.status) {
      const orgType = [
        ...data?.data.map(({ type, id }) => ({
          key: type,
          value: id,
        })),
      ];
      dispatch({
        type: SET_API_DATA,
        payload: {
          orgType,
        },
      });
    }
  };

  useEffect(() => {
    if (activeTab === "location" || activeTab === "departments") {
      if (filters?.organization || commonFilter?.organization) {
        getLocationFilter(filters?.organization || commonFilter?.organization);
      }
    }
    dispatch({ type: SET_API_DATA, payload: { locationFilters: [] } });
  }, [commonFilter?.organization]);

  const handlePageChange = (event, value) => {
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [activeTab]: { ...filters, page: value + 1 },
        },
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const { value } = event.target;

    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [activeTab]: {
            ...filters,
            rowsPerPage: equal(value, -1)
              ? Number(paginationData?.total_count)
              : Number(value),
            page: 1,
          },
        },
      },
    });
  };

  const resetFilters = () => {
    searchRef.current.value = "";

    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [activeTab]: {
            organizationType: "",
            organization: "",
            sortBy: "",
            page: 1,
            rowsPerPage: 10,
            keyword: "",
          },
        },
      },
    });
  };

  const getReports = async ({ endPoint, formPath }) => {
    const {
      organization,
      sortBy,
      page,
      rowsPerPage,
      keyword,
      vasana_filter,
      assessment_given,
      date_filter,
      last_signin_at,
      orgLinkSortBy,
      organizationType,
      location,
    } = filters;
    const { organization: organization_id } = commonFilter ?? {};
    const reportsData = await api({
      method: "GET",
      endPoint: `platform-admin/${endPoint}?page=${page}${
        organization || organization_id
          ? `&organization_id=${organization || organization_id}`
          : ``
      }${location ? `&location_id=${location}` : ``}
      ${organizationType ? `&type_id=${organizationType}` : ``}
      ${sortBy ? `&sort_by=${sortBy}` : ``}${
        orgLinkSortBy ? `&sort_by=${orgLinkSortBy}` : ``
      }${keyword ? `&keyword=${keyword}` : ``}${
        rowsPerPage ? `&limit=${rowsPerPage}` : ``
      }${vasana_filter ? `&vasana_filter=true` : ``}${
        assessment_given
          ? `&assessment_given=${assessment_given === "given"}`
          : ``
      }${
        assessment_given === "not-given"
          ? ``
          : `${
              date_filter?.startDate
                ? `&start_date=${date_filter?.startDate}`
                : ``
            }${date_filter?.endDate ? `&end_date=${date_filter?.endDate}` : ``}`
      }${
        last_signin_at?.startDate
          ? `&last_siginin_start_date=${last_signin_at?.startDate}`
          : ``
      }${
        last_signin_at?.endDate
          ? `&last_siginin_end_date=${last_signin_at?.endDate}`
          : ``
      }`,
      showToastMessage: false,
      needFullResponse: true,
      urlencoded: false,
      needLoader: true,
      parent: formPath?.parent,
    });

    if (reportsData?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          [formPath?.parent]: reportsData.data,
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          [formPath?.parent]: [],
        },
      });
    }
  };

  const onEditClick = async (org) => {
    if (activeTab === "organizations") {
      const orgData = await api({
        method: "GET",
        endPoint: `platform-admin/organizations/${org?.original_id}`,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "organizationInfo",
      });
      if (orgData?.status) {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              organizationInfo: orgData?.data,
            },
          },
        });
      }
      dispatch({
        type: SET_API_DATA,
        payload: {
          orgToEdit: orgData?.data,
        },
      });
      dispatch(toggleModelShow("editOrg"));
    } else if (activeTab === "locations") {
      const locationData = await api({
        method: "GET",
        endPoint: `platform-admin/locations/${org?.location_original_id}`,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "locationInfo",
      });
      if (locationData?.status) {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              locationInfo: locationData?.data,
            },
          },
        });
      }
      dispatch({
        type: SET_API_DATA,
        payload: {
          locationToEdit: locationData?.data,
        },
      });
      dispatch(toggleModelShow("editLocation"));
    } else {
      const departmentData = await api({
        method: "GET",
        endPoint: `platform-admin/departments/${org?.department_original_id}`,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "departmentInfo",
      });
      if (departmentData?.status) {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              departmentInfo: departmentData?.data,
            },
          },
        });
      }
      dispatch({
        type: SET_API_DATA,
        payload: {
          departmentToEdit: departmentData?.data,
        },
      });
      dispatch(toggleModelShow("editDepartment"));
    }
  };

  const onSendClick = (row) => {
    dispatch(toggleModelShow("sendLink"));
    setCurrentRow(row);
  };

  useEffect(() => {
    searchRef.current.value = filters?.keyword;
  }, [filters?.keyword]);

  useEffect(() => {
    if (filters) {
      getReports({
        endPoint: endPoints?.[activeTab],
        formPath: { parent: activeTab },
      });
    }
  }, [filters, commonFilter]);

  useEffect(() => {
    if (!orgFilters) {
      getOrgFilter();
    }
    if (!orgType) {
      getOrgTypeFilter();
    }
    if (!filters) {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData?.[activeTab],
            commonFilter,
            [activeTab]: {
              organizationType: "",
              organization: "",
              location: "",
              sortBy: "",
              page: 1,
              rowsPerPage: 10,
              keyword: "",
              ...filters,
            },
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (loaders?.refetchOrgData) {
      getReports({
        endPoint: endPoints?.[activeTab],
        formPath: { parent: activeTab },
      });
      getOrgFilter();
      if (commonFilter?.organization) {
        getLocationFilter(commonFilter?.organization);
      }
      dispatch({ type: LOADING_CHANGE, payload: { refetchOrgData: false } });
    }
  }, [loaders?.refetchOrgData]);

  const onFilterChange = (e) => {
    const { name, value } = e.target;
    if (JSON.stringify(value) !== JSON.stringify(filters?.[name])) {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            [activeTab]: {
              ...filters,
              ...(equal(name, "organization") && { page: 1 }),
              ...(equal(name, "organizationType") && { page: 1 }),
              [name]: value,
            },
          },
        },
      });
    }
  };

  const onSearch = (e) => {
    const { value } = e.target;
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [activeTab]: {
            ...filters,
            page: 1,
            keyword: value,
            vasana_filter: false,
          },
        },
      },
    });
  };

  const getDownload = (pdfBlob, filename) => {
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = filename;
    link.click();
  };

  const onSearchChange = useCallback(debounce(onSearch, 500), [filters]);

  const getCSV = async ({ endPoint }) => {
    const {
      organizationType,
      organization,
      sortBy,
      page,
      keyword,
      vasana_filter,
      assessment_given,
      date_filter,
      orgLinkSortBy,
      location,
    } = filters;

    const data = await api({
      method: "GET",
      endPoint: `platform-admin/${endPoint}?${
        location ? `&location__id=${location}` : ``
      }${organization ? `&organization_id=${organization}` : ``}${
        organizationType ? `&type_id=${organizationType}` : ``
      }
      ${sortBy ? `&sort_by=${sortBy}` : ``}${
        keyword ? `&keyword=${keyword}` : ``
      }&page=${page}${orgLinkSortBy ? `&sort_by=${orgLinkSortBy}` : ``}${
        vasana_filter ? `&vasana_filter=true` : ``
      }${
        assessment_given
          ? `&assessment_given=${assessment_given === "given"}`
          : ``
      }${
        assessment_given === "not-given"
          ? ``
          : `${
              date_filter?.startDate
                ? `&start_date=${date_filter?.startDate}`
                : ``
            }${date_filter?.endDate ? `&end_date=${date_filter?.endDate}` : ``}`
      }`,
      showToastMessage: false,
      urlencoded: false,
      responseType: "arraybuffer",
      needLoader: true,
      parent: "csvLoader",
    });

    if (data?.status) {
      const pdfBlob = new Blob([data.data], { type: "application/csv" });
      const filename =
        data?.headers["content-disposition"]?.split("filename=")[1];
      getDownload(pdfBlob, filename);
    }
  };

  const downloadData = () => {
    getCSV({ endPoint: endPoints?.[`${activeTab}Download`] });
  };

  const downloadReport = async (row) => {
    try {
      const reportData = await api({
        method: "GET",
        endPoint: `assessment/${assessmentIds?.vasanaType}/download-report?submission_id=${row?.submission_id}&user_id=${row?.user_id}`,
        needLoader: true,
        parent: row?.user_id,
      });
      if (reportData?.data[0]?.url) {
        dispatch({ type: LOADING_CHANGE, payload: { [row?.user_id]: true } });
        const response = await fetch(reportData.data[0].url);
        if (!response.ok) {
          throw new Error(`Failed to fetch file: ${response.statusText}`);
        }
        const blob = await response.blob();
        const a = document.createElement("a");
        const filename =
          reportData?.data[0]?.file_name.replace(/"/g, "") || "report.pdf"; // Fallback filename if none provided
        a.download = filename;

        const pdfBlobUrl = URL.createObjectURL(blob);
        a.href = pdfBlobUrl;
        a.click();
        URL.revokeObjectURL(pdfBlobUrl);
        dispatch({
          type: LOADING_CHANGE,
          payload: { [row?.user_id]: false },
        });
      }
    } catch (error) {
      console.error("Error downloading report:", error);
      dispatch({
        type: LOADING_CHANGE,
        payload: { [row?.user_id]: false },
      });
    }
  };

  const onDelete = async () => {
    const data = await api({
      method: "DELETE",
      endPoint: `platform-admin/${endPoints?.[`delete${activeTab}`]}/${
        deleteRow?.user_id
      } `,
      showToastMessage: true,
      urlencoded: false,
      parent: "deleteUser",
      needLoader: true,
    });
    if (data.status) {
      getReports({
        endPoint: endPoints?.[activeTab],
        formPath: { parent: activeTab },
      });
      setShowDialog(null);
    }
  };

  const onDeleteClick = (row) => {
    setDeleteRow(row);
    setShowDialog(!showDialog);
  };

  const onExpandRow = async (row) => {
    let clonedAccessOfAssessmentsForOrg = [...(assessmentReports || [])];
    const currentRowIndex = clonedAccessOfAssessmentsForOrg?.findIndex(
      (el) => el?.original_id === row?.original_id
    );
    const currentRow = clonedAccessOfAssessmentsForOrg[currentRowIndex];
    if (currentRow?.expanded) {
      currentRow.expanded = false;
      clonedAccessOfAssessmentsForOrg[currentRowIndex] = currentRow;
      dispatch({
        type: SET_API_DATA,
        payload: {
          [activeTab]: {
            ...reportData,
            data: clonedAccessOfAssessmentsForOrg || [],
          },
        },
      });
    } else {
      clonedAccessOfAssessmentsForOrg = clonedAccessOfAssessmentsForOrg?.map(
        (val) => {
          if (val?.original_id !== row?.original_id) {
            return { ...val, expanded: false };
          }
          return { ...val, expanded: true };
        }
      );
      dispatch({
        type: SET_API_DATA,
        payload: {
          [activeTab]: {
            ...reportData,
            data: clonedAccessOfAssessmentsForOrg || [],
          },
        },
      });
      const reportsData = await api({
        method: "GET",
        endPoint: `platform-admin/organization/${row?.original_id}/assessments`,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "expandRowOfOrg",
      });
      const clonedAssessmentReports = [];
      if (reportsData.status) {
        reportsData.data?.optional_assessments.forEach((el) => {
          clonedAssessmentReports.push({
            ...el,
            enabled: false,
          });
        });
        reportsData.data?.required_assessments.forEach((el) => {
          clonedAssessmentReports.push({
            ...el,
            enabled: true,
          });
        });
        reportsData.data?.mandatory_assessments.forEach((el) => {
          clonedAssessmentReports.push({
            ...el,
            enabled: true,
            nonChangeable: true,
          });
        });
      }
      dispatch({
        type: SET_API_DATA,
        payload: {
          accessOfAssessmentsForOrg: {
            assessments: clonedAssessmentReports || [],
            organizationId: row?.original_id,
          },
        },
      });
    }
  };

  const handleSwitchChange = async (event, rowData) => {
    const clonedAssessmentReports = [
      ...(accessOfAssessmentsForOrg?.assessments || []),
    ];
    const index = clonedAssessmentReports?.findIndex(
      (el) => el?.id === rowData?.id
    );
    let clonedAssessment = { ...clonedAssessmentReports?.[index] };
    clonedAssessment = {
      ...clonedAssessment,
      enabled: event.target.checked,
    };
    clonedAssessmentReports[index] = { ...clonedAssessment };
    dispatch({
      type: SET_API_DATA,
      payload: {
        accessOfAssessmentsForOrg: {
          ...accessOfAssessmentsForOrg,
          assessments: [...clonedAssessmentReports],
        },
      },
    });
    const updatedStateArray = clonedAssessmentReports
      ?.filter((el) => el?.enabled)
      .map((val) => val?.id);

    const data = await api({
      method: "POST",
      endPoint: `platform-admin/organization/${accessOfAssessmentsForOrg?.organizationId}/assessments`,
      data: {
        required_assessments: updatedStateArray,
      },
      showToastMessage: true,
      urlencoded: false,
    });

    if (!data.status) {
      clonedAssessment = {
        ...clonedAssessment,
        enabled: !event?.target?.checked,
      };
      clonedAssessmentReports[index] = { ...clonedAssessment };
      dispatch({
        type: SET_API_DATA,
        payload: {
          accessOfAssessmentsForOrg: {
            ...accessOfAssessmentsForOrg,
            assessments: [...clonedAssessmentReports],
          },
        },
      });
    }
  };

  const resendInvitation = async (organizationInfo) => {
    await api({
      method: "POST",
      endPoint: adminPoints?.resendEmail,
      data: {
        organization_id: organizationInfo?.original_id,
      },
      showToastMessage: true,
      urlencoded: false,
      parent: "resendEmail",
      needLoader: true,
    });
  };

  return {
    activeTab,
    filters,
    data: assessmentReports,
    onFilterChange,
    paginationData,
    handlePageChange,
    resetFilters,
    onSearchChange,
    downloadData,
    orgFilters,
    locationFilters,
    orgType,
    loaders,
    handleChangeRowsPerPage,
    searchRef,
    handleAction: {
      onEditClick,
      onSendClick,
      onDeleteClick,
      downloadReport,
      onExpandRow,
      handleSwitchChange,
      resendInvitation,
    },
    modelStates,
    currentRow,
    apiLoaders,
    showDialog,
    onDelete,
    onDeleteClick,
    accessOfAssessmentsForOrg,
    deleteUser,
    dispatch,
  };
};
