import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Stack } from "@mui/material";
import VSNTypography from "../../shared/VSNTypography";
import VSNPaper from "../../shared/VSNPaper";
import { ReactComponent as GiftOpen } from "../../assets/assesments/svg/gift-open.svg";
import VSNLoader from "../../shared/VSNLoader";

const useStyles = makeStyles((theme) => ({
  assesmentInfo: {
    maxWidth: 890,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    minHeight: 576,
    padding: [[45, 70, 40, 70]],
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: [[24, 16, 30, 16]],
      minHeight: "496px",
    },

    "& h5": {
      fontSize: 20,
      color: theme.palette.black.main,
      fontWeight: 700,
      marginBottom: 20,
      [theme.breakpoints.down("370")]: {
        fontSize: 17,
      },
    },
    "& p": {
      fontSize: 14,
      color: theme.palette.secondary.grey,
      marginBottom: 30,
      lineHeight: "18px",
    },
  },
  openGift: {
    marginBottom: 25,
    height: 102,
    [theme.breakpoints.down("sm")]: {
      height: 90,
    },
    "& img": {
      [theme.breakpoints.down("sm")]: {
        width: 130,
      },
    },
  },
  innerMainContent: {
    maxWidth: 472,
    width: "100%",
  },
  emailAdd: {
    marginBottom: 52,
    [theme.breakpoints.down("md")]: {
      marginBottom: 36,
    },
    "& .MuiStack-root": {
      "& label": {
        textAlign: "left",
        color: theme.palette.secondary.grey,
        fontSize: 14,
        marginBottom: 0,
      },
      "& .MuiFormControl-root": {
        "& .MuiInputBase-root": {
          "& input": {
            fontSize: 18,
            color: "#202024",
            fontWeight: 700,
          },
        },
      },
    },
  },
  otpButton: {
    "&.MuiButtonBase-root.MuiButton-root": {
      maxWidth: 293,
      width: "100%",
      marginBottom: 30,
    },
  },
  socialMedia: {
    maxWidth: 293,
    width: "100%",
    margin: "auto",
  },
  orContent: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginBottom: 0,
      padding: [[0, 10]],
      color: "#86650A",
      fontSize: 14,
      fontWeight: 700,
    },
  },
  line: {
    borderTop: "1px solid  #EAEDF0",
    width: "100%",
  },
  socialMediaIcon: {
    position: "static",
    "&.MuiStack-root": {
      [theme.breakpoints.down("sm")]: {
        marginTop: 20,
        gap: 30,
      },
      "& button": {
        padding: 0,
        "& span": {
          "& img": {
            width: 30,
          },
        },
      },
    },
  },
  OTPContainer: {
    marginTop: 50,
  },
  redText: {
    "&.MuiTypography-root": {
      color: "red ",
      margin: 0,
      textAlign: "left",
      marginTop: 3,
    },
  },
  completetionLoader: {
    "& .MuiBox-root": {
      position: "static",
    },
  },
}));
const AssessmentCompletion = () => {
  const classes = useStyles();
  return (
    <Stack padding={2.5} alignItems="center">
      <VSNPaper className={classes.assesmentInfo}>
        <Box className={classes.innerMainContent}>
          <Box className={classes.openGift}>
            <GiftOpen />
          </Box>
          <VSNTypography variant="h5" component="h5">
            Congratulations on completing the Assessment!
          </VSNTypography>
          <VSNTypography variant="body1" component="p">
            We&apos;re almost done! Your results will ready in just a few
            seconds.
          </VSNTypography>

          <Box mt={8} className={classes.completetionLoader}>
            <VSNLoader variant="inline" />
          </Box>
        </Box>
      </VSNPaper>
    </Stack>
  );
};

export default AssessmentCompletion;
