import { makeStyles } from "@mui/styles";
import React from "react";
import { Box } from "@mui/material";
import VSNConfirmBox from "../../shared/VSNConfirmBox";
import VSNTable from "../../shared/VSNTable";
import { assessmentReportContainer } from "../../container/assessmentResult.container";
import {
  organizationsFiler,
  assessmentStatusFilter,
  defaultOptions,
  organizationsTypeFiler,
  locationFiler,
} from "../../description/filters.description";
import { rawsPerPageOption } from "../../description/dashboard.description";
import FiltersContainer from "./FiltersContainer";
import ExpandTableRow from "./ExpandTableRow";
import VSNModel from "../../shared/VSNModel";
import { toggleModelShow } from "../../shared/CustomFunc";
import AssessmentPreference from "./AssessmentPreference";
import CreateOrgModal from "./createOrg";

const useStyles = makeStyles((theme) => ({
  assessmentPreferencesModal: {
    "&.MuiDialog-root": {
      "& .MuiPaper-root": {
        maxWidth: 900,
      },
    },
  },
  assesmentTable: {
    boxShadow:
      "0px 1px 4px rgba(37, 69, 105, 0.05), 0px 2px 8px rgba(37, 69, 105, 0.05)",
    borderRadius: 8,
    border: "1px solid #EAEDF0",
    overflow: "hidden",
    "& .MuiPaper-root": {
      boxShadow:
        "0px 1px 4px rgba(37, 69, 105, 0.05), 0px 2px 8px rgba(37, 69, 105, 0.05)",
      borderRadius: 0,
      border: "0",
      maxHeight: 500,

      overflow: "auto",
      "& .MuiTable-root": {
        "& thead": {
          "& tr": {
            "& th": {
              fontSize: 18,
              fontWeight: 700,
              lineHeight: "23px",
              color: theme.palette.secondary.main,
              whiteSpace: "nowrap",
              height: 64,
              padding: [[10, 16]],
              borderColor: "#EAEDF0",
              textAlign: "left",
              "&:nth-child(6),&:nth-child(7),&:nth-child(8),&:nth-child(9) ": {
                minWidth: 76,
                "& img": {
                  height: 25,
                  width: "auto",
                },
              },
              [theme.breakpoints.down("md")]: {
                fontSize: 16,
                height: 60,
              },
            },
          },
        },
        "& tbody": {
          "& tr": {
            "&:nth-child(even)": {
              background: "#F8F8FE",
            },
            "& td": {
              lineHeight: "normal",
              padding: [[7, 16]],
              fontSize: 16,
              height: 64,
              color: theme.palette.secondary.main,
              whiteSpace: "nowrap",
              border: 0,
              textAlign: "left",
              transition: "height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              "&:nth-child(5)": {
                "& img": {
                  height: 30,
                  width: "auto",
                },
              },
              [theme.breakpoints.down("md")]: {
                padding: [[10, 16]],
                fontSize: 16,
                height: "auto",
              },
              "&:empty": {
                height: 0,
                padding: 0,
              },
              "& .MuiCollapse-root": {
                margin: "0 4.7%",
                [theme.breakpoints.down("sm")]: {
                  margin: "0 4px",
                },
              },
              "&.heightAuto": {
                padding: 0,
                height: "auto",
              },
            },
          },
        },
        "&.adminOrganizations": {
          "& tr": {
            "& td": {
              "&:last-child": {
                width: 240,
              },
            },
          },
        },
      },
    },
    "& .MuiTablePagination-root": {
      "& .MuiToolbar-root": {
        [theme.breakpoints.down("450")]: {
          padding: 0,
        },
        "& .MuiTablePagination-actions": {
          [theme.breakpoints.down("450")]: {
            marginLeft: 5,
          },
          "& .MuiButtonBase-root": {
            [theme.breakpoints.down("450")]: {
              padding: [[8, 4]],
            },
          },
        },
      },
      "& .MuiTablePagination-selectLabel": {
        fontSize: 16,
        fontWeight: "500",
        [theme.breakpoints.down("450")]: {
          fontSize: 13,
        },
      },
      "& .MuiInputBase-root": {
        [theme.breakpoints.down("450")]: {
          marginRight: 5,
          marginLeft: 5,
        },
        "& .MuiSelect-select": {
          fontSize: 16,
          fontWeight: "500",
          minHeight: "auto",
          paddingBottom: 2,
          [theme.breakpoints.down("450")]: {
            fontSize: 13,
            paddingLeft: 0,
          },
        },
        "& .MuiSvgIcon-root": {
          top: 2,
        },
      },
      "& .MuiTablePagination-displayedRows": {
        fontSize: 16,
        fontWeight: "500",
        [theme.breakpoints.down("450")]: {
          fontSize: 13,
        },
      },
    },
    "& .expandTableWrapper": {
      "& .MuiPaper-root": {
        backgroundColor: "transparent",
        boxShadow: "none",
        maxHeight: "unset",
        overflow: "unset",
        "& table": {
          minWidth: "auto",
          "& thead": {
            "& tr": {
              "& th": {
                zIndex: 1,
                backgroundColor: "transparent",
                borderBottom: 0,
                fontWeight: 500,
                height: "fit-content",
              },
            },
          },
          "& tbody": {
            "& tr": {
              "& td": {
                height: "56px !important",
                backgroundColor: "#fff",
                borderBottom: "1px solid #EAEDF0",
                "&:last-child": {
                  width: "20%",
                },
              },
            },
          },
        },
      },
    },
  },
  tablePagination: {
    marginTop: 20,
    "& .MuiStack-root": {
      "& nav": {
        "& ul": {
          justifyContent: "center",
        },
      },
    },
  },
  deleteDialog: {
    "& .MuiDialog-paper": {
      maxWidth: 570,
      width: "100%",
    },
    "& .MuiDialogTitle-root": {
      borderBottom: "1px solid #e46a30",
      padding: "10px 24px",
      fontSize: 22,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .MuiDialogContent-root": {
      padding: "20px 24px !important",
      fontSize: 17,
    },
    "& .MuiButton-root": {
      maxWidth: "15%",
      width: "100%",
    },
  },
  tableCard: {
    backgroundColor: theme.palette.primary.light,
    padding: "13px 0",
    borderRadius: "10px 10px 0 0",
    marginTop: 15,
  },
}));

const AssessmentResult = ({
  columns,
  sortBy,
  getFrom,
  isDownload,
  isSortFilter,
  showAssessmentSort,
  showLocationFilter,
  showResetFilter,
  showOrgTypeFilter,
  showOrgFilter,
  showLastVisitedFilter,
  showDownload,
  showSearchInput,
  hasExpandableRow,
  expandedColumns,
  className,
}) => {
  const {
    activeTab,
    data,
    onFilterChange,
    paginationData,
    handlePageChange,
    resetFilters,
    filters,
    onSearchChange,
    downloadData,
    orgFilters,
    locationFilters,
    orgType,
    keyword,
    loaders,
    handleChangeRowsPerPage,
    searchRef,
    handleAction,
    apiLoaders,
    onDelete,
    onDeleteClick,
    showDialog,
    accessOfAssessmentsForOrg,
    deleteUser,
    modelStates,
    dispatch,
  } = assessmentReportContainer({ getFrom });
  const classes = useStyles();
  return (
    <Box className={classes.tableCard}>
      <FiltersContainer
        {...{
          sortBy,
          organizationsFiler,
          locationFiler,
          organizationsTypeFiler,
          assessmentStatusFilter,
          onFilterChange,
          resetFilters,
          filters,
          onSearchChange,
          downloadData,
          orgFilters,
          locationFilters,
          orgType,
          keyword,
          csvLoader: loaders.csvLoader,
          searchRef,
          orgDefaultOptions: defaultOptions,
          isDownload,
          isSortFilter,
          activeTab,
          showOrgTypeFilter,
          showOrgFilter,
          showLastVisitedFilter,
          showResetFilter,
          showAssessmentSort,
          showLocationFilter,
          showDownload,
          disableDownload: !data?.length,
          showSearchInput,
        }}
      />
      <Box className={classes.assesmentTable}>
        <VSNTable
          className={className}
          handleAction={handleAction}
          columns={columns}
          data={data}
          isLoading={loaders?.[activeTab]}
          paginationData={{
            ...paginationData,
            onPageChange: handlePageChange,
            onRowsPerPageChange: handleChangeRowsPerPage,
            rawsPerPageOption,
          }}
          apiLoaders={apiLoaders}
          disablePagination={
            loaders?.locations ||
            loaders?.users ||
            loaders?.organizations ||
            loaders?.assessmentReports
          }
          hasExpandableRow={hasExpandableRow}
          expandedRow={
            <ExpandTableRow
              {...{
                data: accessOfAssessmentsForOrg?.assessments || [],
                handleAction,
                isLoading: loaders?.expandRowOfOrg,
                columns: expandedColumns,
                hidePagination: true,
                activeTab,
              }}
            />
          }
        />
      </Box>

      <VSNConfirmBox
        isOpen={showDialog}
        title="Are You Sure?"
        msg="Are you sure you want to delete the user?"
        handleOk={onDelete}
        handleCancel={onDeleteClick}
        okBtnName="Delete"
        cancelBtnName="Cancel"
        className={classes.conformModel}
        isLoading={deleteUser}
      />

      {modelStates.createOrg && (
        <VSNModel
          open={modelStates.createOrg}
          onClose={() => dispatch(toggleModelShow("createOrg"))}
          mobileDrawer
        >
          <CreateOrgModal
            assessmentData={{
              data: accessOfAssessmentsForOrg?.assessments || [],
              handleAction,
              isLoading: loaders?.getAssessment,
              columns: expandedColumns,
              activeTab,
            }}
            toggleModelShow={(val) => dispatch(toggleModelShow(val))}
          />
        </VSNModel>
      )}
    </Box>
  );
};

export default AssessmentResult;
