import LinearProgress from "@mui/material/LinearProgress";
import { Container, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import { Fragment } from "react";
import gift from "../../assets/assesments/svg/gift.svg";
import small1 from "../../assets/assesments/svg/small-1.svg";
import small2 from "../../assets/assesments/svg/small-2.svg";
import small3 from "../../assets/assesments/svg/small-3.svg";
import small4 from "../../assets/assesments/svg/small-4.svg";
import small5 from "../../assets/assesments/svg/small-5.svg";
import small6 from "../../assets/assesments/svg/small-6.svg";
import { questionSetContainer } from "../../container/questionSet.container";
import VSNButton from "../../shared/VSNButton";
import VSNImage from "../../shared/VSNImage";
import VSNLoader from "../../shared/VSNLoader";
import VSNTypography from "../../shared/VSNTypography";
import AssessmentCompletion from "./AssessmentCompletion";
import { findPer, length } from "../../utils/javascript";
import VSNPrompt from "../../shared/VSNPrompt";
import LeaveAssessment from "./LeaveAssessment";
import SEO from "../../shared/SEO";

const useStyles = makeStyles((theme) => ({
  linear: {
    textAlign: "center",
    paddingBottom: 20,
    "& .MuiLinearProgress-root": {
      height: 8,
      borderRadius: 16,
      background: "#D9D9D9",
      "& .MuiLinearProgress-bar": {
        background: "#82C799",
      },
    },
  },
  procccessbar: {
    maxWidth: 660,
    margin: [[0, "auto", 70]],
    position: "relative",
    marginTop: 30,
    [theme.breakpoints.down("md")]: {
      margin: [[30, "auto", 62]],
    },
  },
  information: {
    position: "relative",
    minHeight: 160,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& h4": {
      fontSize: 22,
      lineHeight: "26px",
      fontWeight: 700,
      color: theme.palette.secondary.darkGrey,
      textAlign: "center",
      maxWidth: 800,
      margin: "0 auto 30px",

      [theme.breakpoints.down("md")]: {
        fontSize: 24,
        lineHeight: "31px",
        marginBottom: 45,
      },
      [theme.breakpoints.down("800")]: {
        padding: [[0]],
        marginBottom: 45,
      },
    },
  },
  twoOptions: {
    display: "flex",
    alignItems: "center",
    marginRight: 13,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    "& span": {},
  },
  optionInfo: {
    "&.MuiButtonBase-root": {
      background: "#F7F3FB",
      color: theme.palette.secondary.darkGrey,
      margin: [[0]],
      lineHeight: "23px",
      padding: [[26, 66]],
      position: "relative",
      width: 500,
      maxWidth: "100%",
      minHeight: 100,
      [theme.breakpoints.down("lg")]: {
        minHeight: 122,
      },
      [theme.breakpoints.down("md")]: {
        padding: [[16, 18]],
        fontSize: 16,
        minHeight: 80,
      },
      "&:hover": {
        background: "#EAEBFB",
      },
      "&:active": {
        background: "#EAEBFB",
        transition: "auto",
        WebkitTransition: "auto",
      },
      "&:after": {
        position: "absolute",
        content: '""',
        borderRadius: 8,
        width: "100%",
        height: "100%",
        border: "1px solid #CCCBFF",
        top: 10,
        left: 10,
        zIndex: -1,
      },
    },
  },
  gift: {
    position: "absolute",
    bottom: "-4px",
    zIndex: 5,
    "& img": {
      width: 70,
      [theme.breakpoints.down("sm")]: {
        width: 60,
      },
    },
  },
  ImgWrapper: {
    position: "relative",
    padding: [[0, 45]],
    maxWidth: 710,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  small: {
    position: "absolute",
  },
  small1: {
    top: "-122px",
    left: "30%",
    [theme.breakpoints.down("md")]: {
      top: "-100px",
    },
    [theme.breakpoints.down("sm")]: {
      width: 20,
    },
  },
  small2: {
    top: "-6%",
    left: "10%",
    zIndex: -1,
    [theme.breakpoints.down("1200")]: {
      left: "9%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  small3: {
    top: "-58px",
    left: "8%",
    zIndex: -1,
    // [theme.breakpoints.down("md")]: {
    //   top: "20%",
    // },
    [theme.breakpoints.down("sm")]: {
      width: 10,
    },
  },
  small4: {
    top: "-70px",
    right: "30%",
    [theme.breakpoints.down("md")]: {
      top: "-55px",
    },
    [theme.breakpoints.down("sm")]: {
      width: 12,
    },
  },
  small5: {
    bottom: "40px",
    right: "-5%",
    [theme.breakpoints.down("md")]: {
      // bottom: "40px",
      right: "2%",
    },
    [theme.breakpoints.down("sm")]: {
      width: 15,
    },
  },
  small6: {
    top: "-14%",
    right: "17%",
    zIndex: -1,
    [theme.breakpoints.down("1200")]: {
      right: "9%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    color: "#1a90ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
  disabled: {
    pointerEvents: "none",
    opacity: "0.6",
  },
  enabled: {
    pointerEvents: "auto",
    opacity: "1",
  },
  orOption: {
    padding: [[0, 60]],
    color: "#86650A",
    fontSize: 24,
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      padding: [[28, 0, 16, 0]],
    },
  },
  progressBtn: {
    "&.MuiButton-root": {
      background: theme.palette.primary.light,
      minHeight: 67,
      color: theme.palette.secondary.grey,
      border: "1px solid #EAEDF0",
      borderRadius: 5,
      maxWidth: 169,
      width: "100%",
      height: "100%",
      padding: 12,
      display: "flex",
      alignItems: "center",
      transition: "all 0.3s ease-in-out",
      [theme.breakpoints.down("sm")]: {
        width: "48%",
      },
      "& .content": {
        fontSize: 16,
        fontWeight: 500,
      },
      "&:hover": {
        background: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.basic}`,
        color: theme.palette.primary.basic,
      },
    },
  },
}));

const QuestionSet = () => {
  const {
    nextQuestion,
    currentQuestionNum,
    progressBar,
    getTransformValue,
    giftBox,
    questions,
    loadingState,
    submitAnswerLoader,
    apiTime,
    tie,
    disableQuestions,
    isComplete,
    logOut,
    isRotated,
    getProgress,
    currentAssessmentType,
    questionsList,
  } = questionSetContainer();
  const classes = useStyles();
  const currentQuestion = {
    ...questions?.[
      currentQuestionNum > length(questions) - 1
        ? length(questions) - 1
        : currentQuestionNum
    ],

    magnitude: dayjs(),
  };
  if (!questions || !length(questions)) {
    return <VSNLoader variant="fullPage" />;
  }
  if (apiTime?.time || apiTime?.result === "fetching") {
    return <AssessmentCompletion />;
  }
  return (
    <Box width="100%" pt={{ md: 14, xs: 12 }} pb={5}>
      <SEO contentKey="assessment" />

      <VSNPrompt
        when={!isComplete && !logOut}
        ConfirmationBox={LeaveAssessment}
      />
      <Container>
        <div className={classes.procccessbar}>
          <div
            style={{
              marginLeft: "0px",
              transition: "transform .4s linear",
              transform: getTransformValue(currentQuestionNum),
            }}
            className={classes.gift}
            ref={giftBox}
          >
            <VSNImage src={gift} alt="gift" />
          </div>
          <VSNImage
            src={small1}
            alt="small1"
            className={`${classes.small1} ${classes.small}`}
          />
          <VSNImage
            src={small3}
            alt="small3"
            className={`${classes.small3} ${classes.small}`}
          />
          <VSNImage
            src={small4}
            alt="small4"
            className={`${classes.small4} ${classes.small}`}
          />
          <VSNImage
            src={small5}
            alt="small5"
            className={`${classes.small5} ${classes.small}`}
          />
          <Box className={classes.linear}>
            <LinearProgress
              variant="determinate"
              value={findPer(
                getProgress(currentQuestionNum),
                questionsList?.length + 1
              )}
              ref={progressBar}
            />
          </Box>
        </div>

        <Box
          mb={5}
          className={`${classes.information} ${
            disableQuestions ? classes.disabled : classes.enabled
          }`}
        >
          <VSNImage
            src={small2}
            alt="small2"
            className={`${classes.small2} ${classes.small}`}
          />
          <VSNImage
            src={small6}
            alt="small6"
            className={`${classes.small6} ${classes.small}`}
          />
          <VSNTypography variant="h4" component="h4">
            {currentQuestion?.phrase}
          </VSNTypography>
          {currentAssessmentType === "Options" ? (
            <div className={classes.twoOptions}>
              {currentQuestion?.choices?.map((el, i) => (
                <Fragment key={i}>
                  <VSNButton
                    handleClick={() => nextQuestion(el, currentQuestion)}
                    className={classes.optionInfo}
                  >
                    {el?.phrase}
                  </VSNButton>
                  {i < currentQuestion?.choices?.length - 1 ? (
                    <span className={classes.orOption}>OR</span>
                  ) : null}
                </Fragment>
              ))}
            </div>
          ) : (
            <Stack
              direction="row"
              justifyContent="center"
              flexWrap="wrap"
              gap={{ sm: 0.69, xs: 1 }}
            >
              {currentQuestion?.choices?.map((el, i) => (
                <VSNButton
                  handleClick={() => nextQuestion(el, currentQuestion)}
                  className={classes.progressBtn}
                  key={i}
                >
                  {el?.phrase}
                </VSNButton>
              ))}
            </Stack>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default QuestionSet;
