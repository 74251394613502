// import { ReactComponent as Training } from "../assets/header/svg/training.svg"
// import { ReactComponent as TrainingActive } from "../assets/header/svg/training-active.svg"
import { ReactComponent as HomeActive } from "../assets/header/svg/home-active.svg";
import { ReactComponent as TeamActive } from "../assets/header/svg/team-active.svg";
import { ReactComponent as VasanaActive } from "../assets/header/svg/vasana-active.svg";
// import { ReactComponent as AdminActive } from "../assets/header/svg/admin-active.svg"
// import { ReactComponent as Admin } from "../assets/header/svg/admin.svg"
import { ReactComponent as Home } from "../assets/header/svg/home.svg";
import { ReactComponent as Vasana } from "../assets/header/svg/vasana-leaf.svg";
import { ReactComponent as Teams } from "../assets/header/svg/teams.svg";
import { AssessmentActiveIcon, AssessmentIcon } from "../shared/icon";

export const headerLinks = ({ userRole, userRoleForOrg, isUser }) => {
  let links = [];

  const shouldDisplayAdminTab =
    userRoleForOrg !== "platform_admin" && userRoleForOrg !== "";
  if (isUser) {
    links = [
      {
        img: <Home />,
        activeImg: <HomeActive />,
        label: "HOME",
        path: "/",
      },

      // {
      //   img: <Training />,
      //   activeImg: <TrainingActive />,
      //   label: "training",
      //   path: "/training",
      // },
    ];

    if (userRoleForOrg !== "platform_admin") {
      links.push({
        img: <Teams />,
        activeImg: <TeamActive />,
        label: "TEAMS",
        path: "/teams",
      });
    }

    if (shouldDisplayAdminTab && (userRoleForOrg || userRole)) {
      links.push({
        img: <Vasana />,
        activeImg: <VasanaActive />,
        label: "ADMIN",
        path: "/org-admin",
      });
    }

    links.push({
      img: <AssessmentIcon />,
      activeImg: <AssessmentActiveIcon />,
      label: "ASSESSMENTS",
      path: "/assessments",
    });
  } else {
    links = links.concat([
      {
        label: "Home",
        path: "/",
        className: "landing-menu",
      },
      {
        label: "Public Safety",
        path: "/public-safety",
        className: "landing-menu",
      },
      {
        label: "Teams",
        path: "/for-teams",
        className: "landing-menu",
      },
      {
        label: "Who We Are",
        path: "/who-we-are",
        className: "landing-menu",
      },
      {
        label: "Contact Us",
        path: "/contact-us",
        className: "landing-menu",
      },
    ]);
  }

  return links;
};

export const routesToShowLinks = ["/"];
