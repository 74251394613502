import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiContainer } from "../utils/api";
import { SET_API_DATA } from "../redux/constants";
import { toggleModelShow } from "../shared/CustomFunc";

const AssessmentModelContainer = ({
  //   getMemberAssessmentDetail,
  userDetail,
}) => {
  const [filter, setFilter] = useState({ page: 1, keyWord: "", type: "" });
  const { api } = ApiContainer();
  const dispatch = useDispatch();
  const assessMentList = useSelector((state) => state?.api?.assessMentList);
  const getAssessmentLoader = useSelector(
    (state) => state?.api?.loader?.getAssessment
  );
  const getMemberAssessmentDetail = async (filter) => {
    const data = await api({
      method: "get",
      endPoint: `members/${userDetail?.user_id}/assessments?limit=5&page=${
        filter?.page ?? 1
      }&keyword=${filter?.keyWord ?? ""}&assessment_type=${filter?.type ?? ""}`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      needFullResponse: true,
      parent: "getAssessment",
    });
    if (data?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          assessMentList: {
            userDetail,
            assessMent: data?.data?.data,
            page: data?.data?.page,
          },
        },
      });
    } else {
      dispatch({
        type: SET_API_DATA,
        payload: {
          assessMentList: { userDetail, assessMent: [] },
        },
      });
    }
  };
  useEffect(() => {
    const timeId = setTimeout(() => {
      getMemberAssessmentDetail(filter);
    }, 500);
    return () => {
      clearTimeout(timeId);
    };
  }, [filter?.keyWord]);

  return {
    setFilter,
    getMemberAssessmentDetail,
    assessMentList,
    filter,
    getAssessmentLoader,
  };
};

export default AssessmentModelContainer;
