import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { SwiperSlide } from "swiper/react";
import ScrollAnimation from "react-animate-on-scroll";
import bannerShape1 from "../../assets/landing/png/bannerShape1.png";
import bannerShape2 from "../../assets/landing/png/bannerShape2.png";
import bannerShape3 from "../../assets/landing/png/bannerShape3.png";
import heroWhoWeAreImg1 from "../../assets/landing/png/heroWhoWeAreImg1.webp";
import heroWhoWeAreImg2 from "../../assets/landing/png/heroWhoWeAreImg2.webp";
import heroWhoWeAreImg3 from "../../assets/landing/png/heroWhoWeAreImg3.webp";
import whoWeAreAboutImg1 from "../../assets/WhoWeAre/png/whoWeAreAboutImg1.webp";
import whoWeAreAboutImg2 from "../../assets/WhoWeAre/png/whoWeAreAboutImg2.webp";
import peopleVideo from "../../assets/WhoWeAre/png/people-video.webp";
import folksImg1 from "../../assets/WhoWeAre/png/folks-img1.webp";
import folksImg2 from "../../assets/WhoWeAre/png/folks-img2.webp";
import folksImg3 from "../../assets/WhoWeAre/png/folks-img3.webp";
import folksImg4 from "../../assets/WhoWeAre/png/veresh.webp";
import folksImg5 from "../../assets/WhoWeAre/png/folks-img5.webp";
import folksImg6 from "../../assets/WhoWeAre/png/folks-img6.webp";
import folksImg7 from "../../assets/WhoWeAre/png/folks-img7.webp";
import halfOrange from "../../assets/landing/png/halfOrange.png";
import section1Bg1 from "../../assets/landing/png/section-1-bg-1.png";
import section1Bg2 from "../../assets/landing/png/section-1-bg-2.png";
import section1Bg3 from "../../assets/landing/png/section-1-bg-3.png";
import section1Bg4 from "../../assets/landing/png/section-1-bg-4.png";
import section1Bg5 from "../../assets/landing/png/section-1-bg-5.png";
import sa from "../../assets/landing/svg/sa.svg";
import ga from "../../assets/quickView/svg/ga.svg";
import re from "../../assets/quickView/svg/re.svg";
import ma from "../../assets/quickView/svg/ma.svg";
import shape6 from "../../assets/landing/png/shape6.png";
import shape7 from "../../assets/landing/png/shape7.png";
import SEO from "../../shared/SEO";
import VSNImage from "../../shared/VSNImage";
import VSNTypography from "../../shared/VSNTypography";
import FooterSlider from "./FooterSlider";
import { WhoWeAreStyle } from "./WhoWeAreStyle";
import { LandingDashboardStyles } from "./LandingContainerStyle";
import {
  GenomeShape4,
  GenomeShape5,
  Instagram,
  WhoWeAreShape1,
} from "../../shared/icon";
import { VSNSwiper } from "../../shared/VSNSwiper";

const folks = [
  {
    image: folksImg1,
    title: "Patricia Diaz-Kismarton",
    type: ga,
    desc: "My results-oriented (Vasana Type Ga) mindset and sense of purpose drives my leadership of Vasana for Frontlines. My coaching background helps ensure excellence in our therapeutic offerings, unlocking maximum human creative potential.",
    link: "https://www.linkedin.com/in/patricia-diaz-kismarton-7a61a27/",
  },
  {
    image: folksImg2,
    title: "Pritesh Suvagiya",
    type: ga,
    desc: "I'm dedicated to fostering collaboration and driving innovative solutions. Drawing from my Vasana-type Ga traits of reliability and inclusivity, I unite teams to achieve excellence in engineering, ensuring our solutions push boundaries and make a meaningful impact.",
    link: "https://www.linkedin.com/in/pritesh-s-662405149",
  },
  {
    image: folksImg3,
    title: "Rishi Agarwal",
    type: ga,
    desc: "As a Vasana Type Ga, I get stuff done! My intuitive understanding of group dynamics and empathy foster creative, collaborative environments. I guide teams toward excellence, ensuring everyone feels valued and empowered. With emotional intelligence and compassion, I lead to see others succeed.",
    link: "https://www.linkedin.com/in/tulikanair/",
  },
  {
    image: folksImg4,
    title: "Veresh Sita",
    type: sa,
    desc: "My mission is to make the world a better place for everyone. As a Sa type, I inspire people to deliver inspiring results by providing visionary leadership and driving growth. Creating a tone of enthusiasm and positive energy, I foster an atmosphere where everyone belongs and feels safe.",
    link: "https://www.linkedin.com/in/vereshsita/",
  },
  {
    image: folksImg5,
    title: "Tulika Nair",
    type: ma,
    desc: "Content design and strategy have always been my focus, and my Vasana type, Ma, has definitely influenced my rational, orderly, and high-quality approach to content. My attention to detail and precision ensure our communications are always spot-on.",
    link: "https://www.linkedin.com/in/tulikanair/",
  },
  // {
  //   image: folksImg6,
  //   title: "Travis Carson",
  //   type: ma,
  //   desc: "Understanding my Vasana type (Ma) equips me to help others excel under pressure. While situational flexibility is the ultimate goal with Vasana, the journey starts with self-awareness. I create content for our partners that's easy to learn and share, aiming to make the world better for everyone.",
  //   link: "https://www.linkedin.com/in/traviscarson/",
  // },
  {
    image: folksImg1,
    title: "Patricia Diaz-Kismarton",
    type: ga,
    desc: "My results-oriented (Vasana Type Ga) mindset and sense of purpose drives my leadership of Vasana for Frontlines. My coaching background helps ensure excellence in our therapeutic offerings, unlocking maximum human creative potential.",
    link: "https://www.linkedin.com/in/patricia-diaz-kismarton-7a61a27/",
  },
  {
    image: folksImg2,
    title: "Pritesh Suvagiya",
    type: ga,
    desc: "I'm dedicated to fostering collaboration and driving innovative solutions. Drawing from my Vasana-type Ga traits of reliability and inclusivity, I unite teams to achieve excellence in engineering, ensuring our solutions push boundaries and make a meaningful impact.",
    link: "https://www.linkedin.com/in/pritesh-s-662405149",
  },
  {
    image: folksImg3,
    title: "Rishi Agarwal",
    type: ga,
    desc: "As a Vasana Type Ga, I get stuff done! My intuitive understanding of group dynamics and empathy foster creative, collaborative environments. I guide teams toward excellence, ensuring everyone feels valued and empowered. With emotional intelligence and compassion, I lead to see others succeed.",
    link: "https://www.linkedin.com/in/tulikanair/",
  },
  {
    image: folksImg4,
    title: "Veresh Sita",
    type: sa,
    desc: "My mission is to make the world a better place for everyone. As a Sa type, I inspire people to deliver inspiring results by providing visionary leadership and driving growth. Creating a tone of enthusiasm and positive energy, I foster an atmosphere where everyone belongs and feels safe.",
    link: "https://www.linkedin.com/in/vereshsita/",
  },
  {
    image: folksImg5,
    title: "Tulika Nair",
    type: ma,
    desc: "Content design and strategy have always been my focus, and my Vasana type, Ma, has definitely influenced my rational, orderly, and high-quality approach to content. My attention to detail and precision ensure our communications are always spot-on.",
    link: "https://www.linkedin.com/in/tulikanair/",
  },
  {
    image: folksImg1,
    title: "Patricia Diaz-Kismarton",
    type: ga,
    desc: "My results-oriented (Vasana Type Ga) mindset and sense of purpose drives my leadership of Vasana for Frontlines. My coaching background helps ensure excellence in our therapeutic offerings, unlocking maximum human creative potential.",
    link: "https://www.linkedin.com/in/patricia-diaz-kismarton-7a61a27/",
  },
  {
    image: folksImg2,
    title: "Pritesh Suvagiya",
    type: ga,
    desc: "I'm dedicated to fostering collaboration and driving innovative solutions. Drawing from my Vasana-type Ga traits of reliability and inclusivity, I unite teams to achieve excellence in engineering, ensuring our solutions push boundaries and make a meaningful impact.",
    link: "https://www.linkedin.com/in/pritesh-s-662405149",
  },
  {
    image: folksImg3,
    title: "Rishi Agarwal",
    type: ga,
    desc: "As a Vasana Type Ga, I get stuff done! My intuitive understanding of group dynamics and empathy foster creative, collaborative environments. I guide teams toward excellence, ensuring everyone feels valued and empowered. With emotional intelligence and compassion, I lead to see others succeed.",
    link: "https://www.linkedin.com/in/tulikanair/",
  },
  {
    image: folksImg4,
    title: "Veresh Sita",
    type: sa,
    desc: "My mission is to make the world a better place for everyone. As a Sa type, I inspire people to deliver inspiring results by providing visionary leadership and driving growth. Creating a tone of enthusiasm and positive energy, I foster an atmosphere where everyone belongs and feels safe.",
    link: "https://www.linkedin.com/in/vereshsita/",
  },
  {
    image: folksImg5,
    title: "Tulika Nair",
    type: ma,
    desc: "Content design and strategy have always been my focus, and my Vasana type, Ma, has definitely influenced my rational, orderly, and high-quality approach to content. My attention to detail and precision ensure our communications are always spot-on.",
    link: "https://www.linkedin.com/in/tulikanair/",
  },
  // {
  //   image: folksImg6,
  //   title: "Travis Carson",
  //   type: ma,
  //   desc: "Understanding my Vasana type (Ma) equips me to help others excel under pressure. While situational flexibility is the ultimate goal with Vasana, the journey starts with self-awareness. I create content for our partners that's easy to learn and share, aiming to make the world better for everyone.",
  //   link: "https://www.linkedin.com/in/traviscarson/",
  // },
];

const advisors = [
  {
    image: folksImg6,
    name: "Travis Carson",
    designation: "Advisor",
    type: ma,
    link: "https://www.linkedin.com/in/traviscarson/",
  },
  {
    image: folksImg7,
    name: "Russ Burnham",
    designation: "Advisor",
    type: re,
    link: "https://www.linkedin.com/in/russ-burnham-160a952b3/",
  },
];

export default function WhoWeAre() {
  const classes = WhoWeAreStyle();
  const navigate = useNavigate();
  const landingClasses = LandingDashboardStyles();
  const isLargeScreen = useMediaQuery("(min-width:901px)");
  return (
    <>
      <SEO contentKey="whoWeAre" />
      <Box>
        <Box className={classes.bannerBg}>
          <Box className={classes.heroSection}>
            <VSNImage
              src={halfOrange}
              alt="cloud-purple"
              className={classes.halfOrange}
            />
            <Grid
              container
              spacing={4}
              alignItems="center"
              flexDirection="row-reverse"
            >
              <Grid item xs={12} md={6} lg={5}>
                <Box className={classes.heroCapsule}>
                  <VSNImage
                    src={bannerShape1}
                    alt="bannerShape1"
                    className={classes.bannerShape1}
                  />
                  <VSNImage
                    src={bannerShape2}
                    alt="bannerShape2"
                    className={classes.bannerShape2}
                  />
                  <VSNImage
                    src={bannerShape3}
                    alt="bannerShape3"
                    className={classes.bannerShape3}
                  />
                  <Box className={classes.heroCapsuleImage}>
                    <ScrollAnimation
                      className="capsule-img"
                      animateIn="fadeInUp"
                      duration="0.2"
                      offset={-100}
                    >
                      <VSNImage
                        src={heroWhoWeAreImg1}
                        alt="enterprise-hero-img"
                      />
                    </ScrollAnimation>
                    <ScrollAnimation
                      className="capsule-img"
                      animateIn="fadeInUp"
                      duration="0.3"
                      offset={-100}
                    >
                      <VSNImage
                        src={heroWhoWeAreImg2}
                        alt="enterprise-hero-img"
                      />
                    </ScrollAnimation>
                    <ScrollAnimation
                      className="capsule-img"
                      animateIn="fadeInUp"
                      duration="0.4"
                      offset={-100}
                    >
                      <VSNImage
                        src={heroWhoWeAreImg3}
                        alt="enterprise-hero-img"
                      />
                    </ScrollAnimation>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={5.5} sx={{ mx: "auto" }}>
                <Stack alignItems={{ lg: "start", xs: "center" }}>
                  <VSNTypography variant="h1" className={classes.lgHeading}>
                    We’re building a{" "}
                    <span className="orangeText">
                      compassionate world where every human thrives.
                    </span>
                  </VSNTypography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          className={classNames(
            classes.teamAboutSection,
            landingClasses.spacing
          )}
          position="relative"
        >
          <Container maxWidth="xl">
            <Grid
              container
              spacing={{ md: 5, xs: 3 }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={4}>
                <VSNImage
                  className={classes.teamAboutImage}
                  src={whoWeAreAboutImg1}
                  alt="whoWeAreAboutImg"
                />
              </Grid>
              <Grid item md={6}>
                <VSNTypography
                  variant="h6"
                  color="secondary.grey"
                  mb={{ slg: 4 }}
                  fontSize={{ lg: 24, md: 20, xs: 16 }}
                >
                  We&apos;re living through times fraught with stress. Consider
                  the rise in mental health challenges and the pervasive sense
                  of loneliness. Add to this limited resources, the high cost of
                  support, fractured social connections, and deteriorating
                  relationship quality. How can we reverse this? How do we offer
                  someone the support they need, the way they need it and when
                  they need it most?
                </VSNTypography>
              </Grid>
            </Grid>
            <Box className={classes.missionQuote}>
              {isLargeScreen ? (
                <WhoWeAreShape1 className={classes.topShape} />
              ) : (
                <GenomeShape5 className={classes.topShape} />
              )}

              <VSNTypography variant="h5">
                Vasana is on a mission to answer these questions and build
                effective solutions. We aim to change the paradigm and transform
                how we perceive mental and behavioral health.
              </VSNTypography>
              {isLargeScreen ? (
                <WhoWeAreShape1 className={classes.bottomShape} />
              ) : (
                <GenomeShape5 className={classes.bottomShape} />
              )}
            </Box>
            <Grid
              container
              spacing={{ md: 5, xs: 3 }}
              justifyContent="center"
              flexDirection="row-reverse"
            >
              <Grid item md={4}>
                <VSNImage
                  className={classes.teamAboutImage}
                  src={whoWeAreAboutImg2}
                  alt="whoWeAreAboutImg"
                />
              </Grid>
              <Grid item md={8}>
                <VSNTypography
                  variant="h6"
                  color="secondary.grey"
                  mb={{ sm: 4, xs: 2 }}
                  fontSize={{ lg: 24, md: 20, xs: 16 }}
                >
                  Traditional behavioral diagnostics have given us labels that
                  help us understand and empathize with each other a bit more.
                  But these labels can also be restrictive, boxing people in. We
                  want to change the relationship people have with these labels
                  and break down stigmas. Our aim is to create a more inclusive
                  way of being.
                </VSNTypography>
                <VSNTypography
                  variant="h6"
                  color="purple.main"
                  mb={{ sm: 4, xs: 2 }}
                  fontWeight="bold"
                  fontSize={{ lg: 24, md: 20, xs: 16 }}
                >
                  Imagine a platform that leverages the power of AI, Deep
                  Learning, Game Theory, and Natural Language Processing to
                  decode a person’s unique behavioral genome.
                </VSNTypography>
                <VSNTypography
                  variant="h6"
                  color="secondary.grey"
                  fontSize={{ lg: 24, md: 20, xs: 16 }}
                >
                  Enter Vasana, your co-pilot to happiness, thriving
                  relationships, and enhanced performance. Our innovative
                  behavioral diagnostics enables adaptive applications and
                  therapeutics designed to help individuals flourish through
                  improved mental and behavioral health.
                </VSNTypography>
              </Grid>
            </Grid>
          </Container>
          <VSNImage
            src={section1Bg1}
            alt="shape"
            className={`${classes.section1Bg1} ${classes.sectionShape}`}
          />
          <VSNImage
            src={section1Bg3}
            alt="shape"
            className={`${classes.section1Bg2} ${classes.sectionShape}`}
          />
          <VSNImage
            src={section1Bg4}
            alt="shape"
            className={`${classes.section1Bg3} ${classes.sectionShape}`}
          />
          <VSNImage
            src={section1Bg2}
            alt="shape"
            className={`${classes.section1Bg4} ${classes.sectionShape}`}
          />
          <VSNImage
            src={section1Bg5}
            alt="shape"
            className={`${classes.section1Bg5} ${classes.sectionShape}`}
          />
          <VSNImage
            src={shape7}
            alt="shape"
            className={`${classes.section1Bg6} ${classes.sectionShape}`}
          />
        </Box>
        <Box className={classes.spacing} position="relative">
          <Container maxWidth="xl">
            <Box className={classes.whoWeAreCTA}>
              <VSNTypography
                variant="h3"
                color="white.main"
                mb={2}
                fontWeight="bold"
                fontSize={{ md: 72, sm: 62, xsm: 50, xs: 40 }}
              >
                Vāsanā
              </VSNTypography>
              <VSNTypography
                variant="h6"
                color="white.light"
                mb={2}
                fontWeight={400}
                fontSize={{ md: 32, sm: 24, xsm: 20, xs: 18 }}
              >
                /[ vaa-suh-naa ] / noun, Sanskrit; Devanagari
              </VSNTypography>
              <VSNTypography
                variant="h6"
                color="white.light"
                fontWeight={400}
                mb={1}
                fontSize={{ md: 32, sm: 24, xsm: 20, xs: 18 }}
              >
                1. The deep-seated behavioral tendencies or karmic imprints that
                shape a person&apos;s current actions.
              </VSNTypography>
              <VSNTypography
                variant="h6"
                color="white.light"
                fontWeight={400}
                mb={1}
                fontSize={{ md: 32, sm: 24, xsm: 20, xs: 18 }}
              >
                2. Helping people thrive
              </VSNTypography>
            </Box>
            <VSNTypography
              variant="h6"
              color="secondary.grey"
              fontWeight={400}
              sx={{ fontStyle: "italic", pt: { md: 3, xs: 2 } }}
              fontSize={{ xl: 32, lg: 26, md: 20, xs: 16 }}
            >
              Understanding and influencing deep-rooted behavioral patterns can
              help significantly improve mental and behavioral health, boosting
              overall well-being and happiness.
            </VSNTypography>
            <VSNTypography
              variant="h6"
              color="secondary.grey"
              fontWeight={400}
              sx={{ fontStyle: "italic", pt: { md: 3, xs: 2 } }}
              fontSize={{ xl: 32, lg: 26, md: 20, xs: 16 }}
            >
              At Vasana, we embrace this profound insight into human behavior.
              Our name reflects our mission to develop advanced behavioral
              diagnostics that enable adaptive applications and therapeutics.
              Our aim is to enrich lives by providing tools that improve
              holistic health, strengthen relationships, and foster happiness.
            </VSNTypography>
          </Container>
          <VSNImage
            src={section1Bg1}
            alt="shape"
            className={`${classes.section2Bg1} ${classes.sectionShape}`}
          />
        </Box>
        <Box className={classes.spacing} position="relative">
          <Container maxWidth="xl">
            <Box className={classes.weAreList}>
              <VSNTypography
                variant="h4"
                fontSize={{ lg: 58, md: 48, sm: 38, xs: 24 }}
              >
                We are
              </VSNTypography>
              <List disablePadding>
                <ListItem disablePadding>
                  <VSNTypography>
                    <VSNTypography component="span">
                      Humble and Authentic:
                    </VSNTypography>{" "}
                    We are transparent, clear, and direct. But we are never
                    bossy or cocky.
                  </VSNTypography>
                </ListItem>
                <ListItem disablePadding>
                  <VSNTypography>
                    <VSNTypography component="span">
                      Understanding and Relatable:
                    </VSNTypography>{" "}
                    We recognize the humanity in us and others. We encourage
                    open, honest, two-way conversations. We value empathy and
                    respect. We are thoughtful, respectful, purposeful, and
                    collaborative.
                  </VSNTypography>
                </ListItem>
                <ListItem disablePadding>
                  <VSNTypography>
                    <VSNTypography component="span">
                      Maverick Pioneers:
                    </VSNTypography>{" "}
                    We think outside the proverbial box. We know that we must
                    disrupt the status quo to change the world. We are unafraid
                    to take a stand. We have opinions that are driven by strong
                    convictions and grounded in facts.
                  </VSNTypography>
                </ListItem>
                <ListItem disablePadding>
                  <VSNTypography>
                    <VSNTypography component="span">
                      Approachable and Friendly:
                    </VSNTypography>{" "}
                    We are warm and personable. We want to make people feel
                    welcome. We are upbeat and friendly. We make people more
                    productive, happy, and energized. We take what we do
                    seriously but do not take ourselves seriously.
                  </VSNTypography>
                </ListItem>
                <ListItem disablePadding>
                  <VSNTypography>
                    <VSNTypography component="span">
                      Nurturing and Inclusive:
                    </VSNTypography>{" "}
                    We work around the needs of people. We inhabit an
                    environment dedicated to seeing everyone belong and thrive.
                    We are people- and individual-first and champion
                    inclusivity.
                  </VSNTypography>
                </ListItem>
                <ListItem disablePadding>
                  <VSNTypography
                    variant="h4"
                    fontWeight="700"
                    color="primary.basic"
                    fontSize={{ lg: 58, md: 48, sm: 38, xs: 24 }}
                  >
                    HUMAN.
                  </VSNTypography>
                </ListItem>
              </List>
            </Box>
          </Container>
          <VSNImage
            src={shape6}
            alt="shape"
            className={`${classes.section3Bg1} ${classes.sectionShape}`}
          />
          <VSNImage
            src={section1Bg5}
            alt="shape"
            className={`${classes.section3Bg2} ${classes.sectionShape}`}
          />
          <VSNImage
            src={shape7}
            alt="shape"
            className={`${classes.section3Bg3} ${classes.sectionShape}`}
          />
          <VSNImage
            src={section1Bg1}
            alt="shape"
            className={`${classes.section3Bg4} ${classes.sectionShape}`}
          />
        </Box>
        {/* <Box className={classes.spacing} position="relative">
          <Container maxWidth="xl">
            <Box mx="auto" maxWidth={430} textAlign="center">
              <VSNTypography
                variant="h5"
                fontWeight="700"
                className={classes.mdHeading}
              >
                Hear from our People
              </VSNTypography>
              <VSNTypography
                color="secondary.grey"
                fontSize={{ lg: 24, md: 20, xs: 16 }}
              >
                Because a video is worth a million words.
              </VSNTypography>
            </Box>
            <Box className={classes.videoWrapper}>
              <VSNImage src={peopleVideo} />
              <PlayArrowIcon />
            </Box>
          </Container>
          <VSNImage
            src={section1Bg4}
            alt="shape"
            className={`${classes.section4Bg1} ${classes.sectionShape}`}
          />
          <VSNImage
            src={section1Bg2}
            alt="shape"
            className={`${classes.section4Bg2} ${classes.sectionShape}`}
          />
          <VSNImage
            src={section1Bg5}
            alt="shape"
            className={`${classes.section4Bg3} ${classes.sectionShape}`}
          />
          <VSNImage
            src={shape7}
            alt="shape"
            className={`${classes.section4Bg4} ${classes.sectionShape}`}
          />
        </Box> */}
        <Box className={classes.spacing} position="relative">
          <Container maxWidth="xl">
            <VSNTypography
              color="secondary.darkGrey"
              variant="h5"
              fontWeight={700}
              textAlign="center"
              mb={4}
              className={classes.mdHeading}
            >
              The Folks Building Vasana
            </VSNTypography>
          </Container>
          <Box width="100%">
            <VSNSwiper
              autoplay={{
                delay: 2500,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
              centeredSlides
              loop
              breakpoints={{
                0: {
                  slidesPerView: 1.5,
                  spaceBetween: 10,
                  centeredSlides: true,
                },
                600: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2.75,
                  spaceBetween: 40,
                },
                992: {
                  slidesPerView: 3.5,
                  spaceBetween: 50,
                },
                1280: {
                  slidesPerView: 4.5,
                  spaceBetween: 40,
                },
                1440: {
                  slidesPerView: 5.25,
                  spaceBetween: 40,
                },
                1600: {
                  slidesPerView: 5.5,
                  spaceBetween: 50,
                },
              }}
            >
              {folks.map((data, ind) => {
                return (
                  <SwiperSlide key={ind}>
                    <Box className={classes.folkBox}>
                      <Box className={classes.flipBox}>
                        <Box className="front">
                          <VSNImage src={data.image} alt={data.title} />
                        </Box>
                        <Box className="back">
                          <Box>
                            <VSNTypography className={classes.description}>
                              {data.desc}
                            </VSNTypography>
                          </Box>
                        </Box>
                      </Box>
                      <VSNTypography
                        variant="h6"
                        sx={{
                          verticalAlign: "middle",
                          "& svg": {
                            verticalAlign: "-1px",
                            height: 16,
                            width: 16,
                          },
                        }}
                      >
                        {data.title}{" "}
                        <a href={data?.link} target="_blank" rel="noreferrer">
                          <Instagram />
                        </a>
                      </VSNTypography>
                      <VSNTypography color="secondary.grey">
                        Vasana type <VSNImage src={data.type} at="type" />
                      </VSNTypography>
                    </Box>
                  </SwiperSlide>
                );
              })}
            </VSNSwiper>
            {/* <VSNSwiper
              className={classes.bottomFolks}
              autoplay={{
                delay: 2500,
              }}
              loop
              breakpoints={{
                0: {
                  slidesPerView: 1.5,
                  spaceBetween: 10,
                },
                600: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3.5,
                  spaceBetween: 40,
                  centeredSlides: true,
                },
                992: {
                  slidesPerView: 4,
                  spaceBetween: 60,
                },
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 80,
                },
                1440: {
                  slidesPerView: 6,
                  spaceBetween: 100,
                },
              }}
            >
              {folks2.map((data, ind) => {
                return (
                  <SwiperSlide key={ind}>
                    <Box className={classes.folkBox}>
                      {data.desc ? (
                        <VSNTypography className={classes.description}>
                          {data.desc}
                        </VSNTypography>
                      ) : (
                        <VSNImage src={data.image} alt={data.title} />
                      )}
                      <VSNTypography
                        variant="h6"
                        sx={{
                          verticalAlign: "middle",
                          "& svg": {
                            verticalAlign: "-1px",
                            height: 16,
                            width: 16,
                          },
                        }}
                      >
                        {data.title} <Instagram />
                      </VSNTypography>
                      <VSNTypography color="secondary.grey">
                        Vasana type <VSNImage src={data.type} alt="type" />
                      </VSNTypography>
                    </Box>
                  </SwiperSlide>
                );
              })}
            </VSNSwiper> */}
          </Box>
        </Box>
        <Box className={classes.spacing} position="relative">
          <Container maxWidth="xl">
            <VSNTypography
              color="secondary.darkGrey"
              variant="h5"
              fontWeight={700}
              textAlign="center"
              mb={4}
              className={classes.mdHeading}
            >
              The Advisors Guiding Us
            </VSNTypography>
            {/* <VSNSwiper
              className={classes.bottomFolks}
              autoplay={{
                delay: 2500,
              }}
              centeredSlides
              loop
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
            > */}
            <Grid container spacing={3} justifyContent="center">
              {advisors.map((data, i) => {
                return (
                  <Grid item lg={3} md={4} sm={5} xts={6} xs={12} key={i}>
                    <Box className={classes.advisors}>
                      <VSNImage src={data.image} alt="advisors" />
                      <VSNTypography
                        variant="h6"
                        sx={{
                          verticalAlign: "middle",
                          "& svg": {
                            verticalAlign: "-1px",
                            height: 16,
                            width: 16,
                          },
                        }}
                      >
                        {data.name}{" "}
                        <a href={data?.link} target="_blank" rel="noreferrer">
                          <Instagram />
                        </a>
                      </VSNTypography>
                      <VSNTypography color="secondary.grey">
                        Vasana type <VSNImage src={data.type} at="type" />
                      </VSNTypography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            {/* </VSNSwiper> */}
          </Container>
        </Box>
        <FooterSlider mt={{ sm: 10, xs: 5 }} />
      </Box>
    </>
  );
}
