import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  ListItem,
  Stack,
} from "@mui/material";
import Masonry from "react-masonry-css";
import VSNTypography from "../../shared/VSNTypography";
import {
  FlashIcon,
  MenuIcon,
  OptionalIcon,
  RightRoundIcon,
  SadIcon,
} from "../../shared/icon";
import { assessmentsViewContainer } from "../../container/assessmentsView.container";
import AssessmentCard from "./assessmentCard";
import VSNSelect from "../../shared/VSNSelect";
import { ReactComponent as PreviewLayerIcon } from "../../assets/landing/svg/previewLayer.svg";

const useStyles = makeStyles((theme) => ({
  assessmentContainer: {
    "&.MuiContainer-root": {
      maxWidth: 1536,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 30,
      lineHeight: "40px",
      fontWeight: 700,
      color: "#2D2F39",
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
        lineHeight: "30px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 22,
        lineHeight: "28px",
      },
    },
  },
  mdText: {
    "&.MuiTypography-root": {
      fontSize: 18,
      lineHeight: "23px",
      color: "#747787",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        lineHeight: "18px",
        fontWeight: 400,
      },
      "& span": {
        fontWeight: 700,
        color: "#8B3F9E",
      },
    },
  },
  headingWrapper: {
    maxWidth: 608,
    margin: "0 auto",
    textAlign: "center",
    padding: "56px 0 0 0",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 0 0 0",
    },
  },
  innerTabsWrapper: {
    maxWidth: 1002,
    width: "100%",
    margin: "65px auto 30px",
    background: "#fff",
    borderRadius: 8,
    position: "relative",
    zIndex: 2,
    overflowX: "auto",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "30px -16px 10px -16px",
      paddingBottom: 10,
      width: "unset",
      borderRadius: 0,
    },
  },
  listitemTab: {
    "&.MuiListItem-root": {
      color: "#747787",
      opacity: 1,
      minWidth: 0,
      display: "flex",
      alignItems: "center",
      width: "fit-content",
      padding: [[21, 12]],
      margin: [[0, 24]],
      position: "relative",
      cursor: "pointer",
      flexDirection: "row",
      flex: "0 0 auto",
      [theme.breakpoints.down("md")]: {
        margin: "0 12px",
      },
      [theme.breakpoints.down("767")]: {
        margin: 0,
        padding: 16,
      },
      [theme.breakpoints.down("sm")]: {
        background: "#fff",
      },
      "& span": {
        display: "inline-block",
        marginLeft: 8,
      },
      "&::after": {
        position: "absolute",
        content: '""',
        height: 4,
        width: "100%",
        background: theme.palette.primary.main,
        borderRadius: "4px 4px 0px 0px",
        left: 0,
        opacity: 0,
        bottom: 0,
        transition: "all 0.3s ease-in-out",
        zIndex: 1,
        [theme.breakpoints.down("sm")]: {
          zIndex: 1,
        },
      },
      "&.active, &:hover": {
        color: theme.palette.primary.main,
        "&::after": {
          opacity: 1,
        },
      },
      "& svg": {
        flex: "0 0 auto",
      },
    },
  },
  masonryGrid: {
    display: "flex",
    gap: 25,
    width: "calc(100% - 50px)",
    [theme.breakpoints.down("1024")]: {
      width: "calc(100% - 25px)",
    },
    [theme.breakpoints.down("md")]: {
      gap: 16,
      width: "calc(100% - 16px)",
    },
    [theme.breakpoints.down("650")]: {
      width: "100%",
    },
  },
  sortFilter: {
    margin: [[0, 24]],
    padding: [[21, 12]],
    "& .MuiFormControl-root": {
      "& .MuiSvgIcon-root": {
        display: "none",
      },
      "& span, .MuiSelect-select": {
        verticalAlign: "middle",
        color: `${theme.palette.secondary.grey} !important`,
        fontSize: 16,
        fontFamily: "DM Sans",
        fontWeight: 400,
      },
      "& .MuiOutlinedInput-input": {
        padding: "0 !important",
      },
      "& fieldset": {
        display: "none",
      },
    },
  },
  filterDropDown: {
    "& .MuiPaper-root": {
      maxHeight: 250,
      padding: [[0, 5]],
      "& ul": {
        "& li": {
          display: "flex",
          justifyContent: "start",
          padding: "6px 16px",
          "&.MuiButtonBase-root": {
            borderRadius: 5,
            "&.Mui-selected": {
              color: theme.palette.primary.main,
            },
            "&.Mui-focusVisible": {
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            },
          },
          "& img": {
            height: 28,
            width: "auto",
          },
        },
      },
    },
  },
}));

const innertab = [
  {
    id: 1,
    icon: <MenuIcon />,
    name: "All",
    value: "all",
  },
  {
    id: 2,
    icon: <FlashIcon />,
    name: "Required",
    value: "required",
  },
  {
    id: 3,
    icon: <OptionalIcon />,
    name: "Optional",
    value: "optional",
  },
  {
    id: 4,
    icon: <RightRoundIcon />,
    name: "Completed",
    value: "completed",
  },
  // {
  //   id: 5,
  //   icon: <SadIcon />,
  //   name: "Ignored",
  //   value: "ignored",
  // },
];

const sortBy = {
  name: "anotherFilter",
  placeHolder: "Assessment Filter",
  defaultValue: 0,
  options: [
    {
      key: "All",
      value: "all",
    },
    {
      key: "Wellness Assessments",
      value: "wellness",
    },
    {
      key: "Behavioral Assessments",
      value: "behavioral",
    },
  ],
};

export default function AssessmentsView() {
  const {
    assessmentTab,
    assessmentFilterTab,
    assessmentList,
    assessmentListLoader,
    handleTabChange,
    resultInfo,
    handleButtonClick,
    retakeAssessment,
    userRoleData,
    onFilterChange,
  } = assessmentsViewContainer();

  const classes = useStyles();
  const breakpointCols = {
    default: 3,
    1200: 3,
    1024: 2,
    650: 1,
  };

  return (
    <Container maxWidth="xl" className={classes.assessmentContainer}>
      <Box className={classes.headingWrapper}>
        <VSNTypography variant="h2" className={classes.heading}>
          Assessments
        </VSNTypography>
        <VSNTypography className={classes.mdText}>
          Discover your unique personality traits through our assessments to
          enhance teamwork and boost efficiency.
        </VSNTypography>
      </Box>
      <Stack
        direction="row"
        justifyContent={{ xsm: "center", xs: "start" }}
        className={classes.innerTabsWrapper}
      >
        {innertab.map((data) => {
          return (
            <ListItem
              key={data?.id}
              className={`${assessmentTab === data?.value ? "active" : ""} ${
                classes.listitemTab
              }`}
              onClick={() =>
                !assessmentListLoader ? handleTabChange(data?.value) : null
              }
              disabled={assessmentListLoader}
            >
              {data?.icon}
              <span>{data?.name}</span>
            </ListItem>
          );
        })}
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          className={classes.sortFilter}
        >
          <PreviewLayerIcon />
          <VSNSelect
            {...{
              ...sortBy,
              onChange: onFilterChange,
              value: sortBy?.options
                ?.map((el) => el?.value)
                ?.includes(assessmentFilterTab)
                ? assessmentFilterTab
                : 0,
              classes: { popover: classes.filterDropDown },
              disabled: assessmentListLoader,
            }}
          />
        </Stack>
      </Stack>
      <>
        {assessmentListLoader ? (
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={24} color="inherit" />
          </Grid>
        ) : (
          <Masonry
            breakpointCols={breakpointCols}
            className={classes.masonryGrid}
            columnClassName="my-masonry-grid_column"
          >
            {assessmentList?.length ? (
              assessmentList?.map((data, i) => {
                return (
                  <AssessmentCard
                    {...{
                      ...data,
                      resultInfo,
                      handleButtonClick,
                      retakeAssessment,
                      userRoleData,
                      index: i,
                    }}
                    key={i}
                  />
                );
              })
            ) : (
              <Stack>No assessments found</Stack>
            )}
          </Masonry>
        )}
      </>
    </Container>
  );
}
