import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_API_DATA } from "../redux/constants";
import { ApiContainer } from "../utils/api";
import { equal, keys } from "../utils/javascript";
import { assessmentIds, endPoints } from "../utils/constant";

export const assessmentDataContainer = ({ currentAssessment, location }) => {
  const { api } = ApiContainer();
  const dispatch = useDispatch();

  const assessmentStoredData = useSelector(
    (state) => state.api?.assessmentData
  );
  const [assessmentData, setAssessData] = useState({
    questions: [],
    subId: "",
    markerInfo: {},
    tieBreaker: [],
    ...assessmentStoredData,
  });
  const [loading, setLoading] = useState(true);

  const getQuestionSetData = async () => {
    setLoading(true);
    const getData = async (endPoint, key) => {
      const response = await api({
        method: "GET",
        endPoint,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: key,
      });
      if (response?.status) {
        setAssessData((prevData) => {
          const returnData = {
            ...prevData,
            [key]:
              equal(key, "questions") || equal(key, "tieBreaker")
                ? response?.data
                : response?.data,
          };
          dispatch({
            type: SET_API_DATA,
            payload: {
              assessmentData: returnData,
            },
          });
          return returnData;
        });
      }
    };

    const apiCalls = [
      getData(
        `assessment/${currentAssessment?.assessment_id}/get-question`,
        "questions"
      ),
    ];
    if (
      ["/assessment", "/quick-note"]?.some((path) =>
        location?.pathname?.includes(path)
      )
    ) {
      apiCalls?.push([getData(endPoints?.generateSubmissionId, "subId")]);
    }
    if (currentAssessment?.has_tie_breaker_questions) {
      apiCalls?.push([
        getData(
          `assessment/${currentAssessment?.assessment_id}/tie-breaker`,
          "tieBreaker"
        ),
      ]);
    }
    if (
      currentAssessment?.assessment_id?.toString() === assessmentIds?.vasanaType
    ) {
      apiCalls?.push([getData(endPoints?.markerDetails, "markerInfo")]);
    }
    await Promise.all(apiCalls);
    setLoading(false);
  };
  useEffect(() => {
    if (currentAssessment?.assessment_id) getQuestionSetData();
  }, [currentAssessment?.assessment_id]);
  return { assessmentData, loading };
};
