import { shuffle } from "lodash";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  ON_FORM_CHANGE,
  SET_API_DATA,
  SET_USER_DATA,
} from "../redux/constants";
import { ApiContainer } from "../utils/api";
import { entries, lowerCase, values, length } from "../utils/javascript";
import {
  clearFormData,
  getAssessmentState,
  getSlug,
} from "../shared/CustomFunc";
import { showToast } from "../utils/toastService";
import ApiCalls from "../utils/apiCall";
import { assessmentIds } from "../utils/constant";

export const questionSetContainer = () => {
  const assessmentData = useSelector((state) => state?.api?.assessmentData);
  const userData = useSelector((state) => state?.app?.userData);
  const allAssessmentResults = useSelector(
    (state) => state?.api?.assessmentResult
  );
  const { api } = ApiContainer();
  const { getRoleInfo } = ApiCalls();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const progressBar = useRef(null);
  const giftBox = useRef(null);
  const currentAssessmentId = localStorage.getItem("currentAssessmentId");
  const questionsList = useSelector(
    (state) => state.api?.assessmentData?.questions
  );
  const [questions, setQuestions] = useState([]);
  const tieQuestions = useSelector(
    (state) => state.api?.assessmentData?.tieBreaker
  );
  const loadingState = useSelector((state) => ({
    questions: state.api.loader?.questions,
    subId: state.api.loader?.subId,
    markerInfo: state.api.loader?.markerInfo,
  }));

  const { form } = useSelector((state) => state);
  const currentAssessment = getAssessmentState({
    assessments: userData?.assessments,
    id: currentAssessmentId,
  });
  const assessmentResult = useSelector(
    (state) => state.form.formData?.assessmentResult?.[currentAssessmentId]
  );
  const { assessmentList } = useSelector((state) => state.api);
  const currentAssessmentType = currentAssessment?.answer_type;

  const formPath = {
    resultPath: "assessmentResult",
  };

  const [disableQuestions, setDisableQuestions] = useState(false);

  const [time, setTime] = useState({ time_start: "" });
  const [apiTime, setApiTime] = useState({
    time: "",
    result: "",
  });
  const [polling, setPolling] = useState({
    isPolling: false,
    seconds: 0,
  });
  const logOut = useSelector((state) => state?.app?.flags?.logOut);

  const setDefault = () => {
    setTime({ ...time, time_start: dayjs().toISOString() });
    setDisableQuestions(false);
    setApiTime({
      time: "",
      result: "",
    });
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.resultPath]: {
            ...form.formData?.[formPath?.resultPath],
            [currentAssessment?.assessment_id]: {
              ...assessmentResult,
              tie_markers: [],
              need_tie_breaker: false,
              completed: false,
              currentQuestionNum: 0,
              types: {
                sa: 0,
                re: 0,
                ga: 0,
                ma: 0,
              },
            },
          },
        },
      },
    });
  };
  useEffect(() => {
    if (currentAssessment?.assessment_id) {
      setDefault();
    }
  }, [currentAssessment?.assessment_id]);
  const [isRotated, setIsRotated] = useState(false);

  const handleResize = () => {
    setIsRotated(window.innerWidth < window.innerHeight);
  };

  useEffect(() => {
    // Initial check on component mount
    setIsRotated(window.innerWidth < window.innerHeight);

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const shuffleTheQus = async () => {
    const shuffled = await shuffle([...questionsList]);
    setQuestions(shuffled);
  };

  useEffect(() => {
    if (currentAssessment?.shuffle_questions && length(questionsList)) {
      shuffleTheQus();
    }
  }, [questionsList]);

  let tempTieMarkers = assessmentResult?.tie_markers;
  const setTieQuestion = (tieMarkers) => {
    const pairs = [];
    for (let i = 0; i < length(tieMarkers); i += 2) {
      if (i + 1 < length(tieMarkers)) {
        const pair = [tieMarkers[i], tieMarkers[i + 1]];
        pairs.push(pair);
      }
    }
    const newQuestion = pairs.map((pair) => {
      tempTieMarkers = [
        ...tempTieMarkers.filter((marker) => !pair?.includes(marker)),
      ];

      return tieQuestions?.filter((obj) =>
        lowerCase(obj?.markers)
          .split(",")
          .every((item) => pair?.includes(item))
      )[0];
    });
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.resultPath]: {
            ...form.formData?.[formPath?.resultPath],
            [currentAssessment?.assessment_id]: {
              ...assessmentResult,
              tie_markers: [...tempTieMarkers],
            },
          },
        },
      },
    });
    setQuestions((prev) => [...prev, ...newQuestion]);
    setDisableQuestions(false);
  };

  const checkTie = async () => {
    if (currentAssessment?.has_tie_breaker_questions) {
      if (!assessmentResult.need_tie_breaker) {
        const max = Math.max(...values(assessmentResult?.types));

        const tieMarkers = [];
        entries(assessmentResult?.types).forEach(([key, val]) => {
          if (val === max) {
            tieMarkers.push(key);
          }
        });
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              [formPath.resultPath]: {
                ...form.formData?.[formPath?.resultPath],
                [currentAssessment?.assessment_id]: {
                  ...assessmentResult,
                  tie_markers: tieMarkers,
                  totalTieQue: length(tieMarkers) - 1,
                  ...(length(tieMarkers) === 1
                    ? { need_tie_breaker: false, completed: true }
                    : {
                        need_tie_breaker: true,
                        completed: false,
                        curTieQue: 1,
                      }),
                },
              },
            },
          },
        });
      } else {
        dispatch({
          type: ON_FORM_CHANGE,
          payload: {
            formData: {
              ...form.formData,
              [formPath.resultPath]: {
                ...form.formData?.[formPath?.resultPath],
                [currentAssessment?.assessment_id]: {
                  ...assessmentResult,
                  need_tie_breaker: false,
                  completed: true,
                },
              },
            },
          },
        });
      }
    } else {
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            [formPath.resultPath]: {
              ...form.formData?.[formPath?.resultPath],
              [currentAssessment?.assessment_id]: {
                ...assessmentResult,
                need_tie_breaker: false,
                completed: true,
              },
            },
          },
        },
      });
    }
  };

  const nextQuestion = async (selected, curQuestion) => {
    const answer = {
      choice_id: selected.id,
      question_id: selected.question_id,
      magnitude: dayjs().diff(dayjs(curQuestion?.magnitude), "milliseconds"),
      submission_id: assessmentData?.subId,
      result: selected.result,
      markers: curQuestion?.markers,
      tie_breaker: assessmentResult?.need_tie_breaker,
    };
    if (!Object.keys(curQuestion)?.includes("tie_breaker")) {
      delete answer?.markers;
    }
    if (!Object.keys(curQuestion)?.includes("markers")) {
      delete answer?.tie_breaker;
    }
    const last = assessmentResult?.currentQuestionNum === length(questions) - 1;
    if (last) {
      setDisableQuestions(true);
    }
    const data = api({
      method: "POST",
      endPoint: `assessment/${currentAssessmentId}/answer`,
      data: {
        ...answer,
      },
      showToastMessage: false,
      urlencoded: false,
    });
    const selectedMarker = lowerCase(selected.result);

    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [formPath.resultPath]: {
            ...form.formData?.[formPath?.resultPath],
            [currentAssessment?.assessment_id]: {
              ...assessmentResult,
              currentQuestionNum: assessmentResult?.currentQuestionNum + 1,
              types: {
                ...assessmentResult?.types,
                [selectedMarker]: assessmentResult?.types[selectedMarker]
                  ? assessmentResult?.types[selectedMarker] + 1
                  : 1,
              },
              ...(assessmentResult?.need_tie_breaker
                ? {
                    tie_markers: [
                      selectedMarker,
                      ...assessmentResult?.tie_markers,
                    ],
                    curTieQue: assessmentResult?.curTieQue
                      ? assessmentResult?.curTieQue + 1
                      : 1,
                  }
                : []),
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    if (
      currentAssessment?.has_tie_breaker_questions &&
      assessmentResult?.need_tie_breaker &&
      length(assessmentResult?.tie_markers) >= 2
    ) {
      setTieQuestion(assessmentResult?.tie_markers);
    }
  }, [assessmentResult?.tie_markers]);

  useEffect(() => {
    if (
      assessmentResult?.currentQuestionNum &&
      assessmentResult?.currentQuestionNum === length(questions) &&
      (!assessmentResult?.need_tie_breaker ||
        length(assessmentResult?.tie_markers) === 1)
    ) {
      checkTie();
    }
  }, [assessmentResult?.currentQuestionNum]);

  const getResult = async () => {
    setApiTime((prev) => ({ ...prev, time: 3, result: "fetching" }));
    const result = await api({
      method: "GET",
      endPoint: `assessment/${currentAssessmentId}/result?submission_id=${assessmentData?.subId}`,
      showToastMessage: false,
      needLoader: true,
      parent: "assessmentResult",
      urlencoded: false,
    });
    if (result?.status) {
      if (result?.data?.status === "IN_PROGRESS") {
        if (!polling?.isPolling) {
          setPolling({ ...polling, seconds: 15, isPolling: true });
        }
        return;
      }
      const clonedAssessments = [...userData?.assessments];
      const index = clonedAssessments?.findIndex(
        (el) =>
          el?.assessment_id?.toString() === currentAssessmentId?.toString()
      );
      clonedAssessments[index] = {
        ...currentAssessment,
        result: result?.data?.result || currentAssessment?.result,
        submission_id: assessmentData?.subId,
      };
      dispatch({
        type: SET_USER_DATA,
        payload: {
          assessments: clonedAssessments,
        },
      });

      const clonedAssessmentList = [...assessmentList];
      const currIndex = clonedAssessmentList?.findIndex(
        (el) =>
          el?.assessment_id?.toString() === currentAssessmentId?.toString()
      );
      clonedAssessmentList[currIndex] = {
        ...currentAssessment,
        assessment_taken: true,
      };
      dispatch({
        type: SET_API_DATA,
        payload: {
          assessmentList: clonedAssessmentList,
        },
      });
      if (currentAssessmentId?.toString() === assessmentIds?.vasanaType) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            assessmentResult: {
              ...allAssessmentResults,
              [currentAssessment?.assessment_id]: {
                ...result?.data,
                tie_markers: result?.data?.tie_markers?.split(","),
              },
            },
          },
        });
      }
      setQuestions([]);
      setApiTime((prev) => ({ ...prev, result: "fetched" }));
    } else {
      setDefault();
      showToast(
        "Sorry for inconvenience, looks like there was network issues, Please retake the assessment",
        "info"
      );
    }
  };

  useEffect(() => {
    if (!assessmentResult?.need_tie_breaker && assessmentResult?.completed) {
      getResult();
    }
  }, [assessmentResult?.need_tie_breaker, assessmentResult?.completed]);

  useEffect(() => {
    if (length(questionsList) > 0) {
      setQuestions(questionsList);
    }
  }, [questionsList]);

  useEffect(() => {
    if (apiTime?.time === 0 && apiTime?.result === "fetched") {
      dispatch(clearFormData(formPath?.resultPath));
      const remainingAssessment = assessmentList?.length
        ? assessmentList.find(
            (assessment) =>
              assessment.is_required_for_signup && !assessment.assessment_taken
          )
        : null;
      if (remainingAssessment) {
        localStorage.setItem(
          "currentAssessmentId",
          remainingAssessment?.assessment_id
        );
        const slug = getSlug(
          assessmentList,
          remainingAssessment?.assessment_id
        );
        navigate(`/get-started/${slug}`);
      }
      if (
        currentAssessment?.assessment_id?.toString() ===
        assessmentIds?.vasanaType?.toString()
      ) {
        const remainingAssessment = assessmentList?.length
          ? assessmentList.find(
              (assessment) =>
                assessment.is_required_for_signup &&
                !assessment.assessment_taken
            )
          : null;
        if (remainingAssessment) {
          const slug = getSlug(
            assessmentList,
            remainingAssessment?.assessment_id
          );
          navigate(`/get-started/${slug}`);
        } else {
          navigate("/");
        }
      } else {
        const remainingAssessment = assessmentList?.length
          ? assessmentList.find(
              (assessment) =>
                assessment.is_required_for_signup &&
                !assessment.assessment_taken
            )
          : null;
        if (remainingAssessment) {
          const slug = getSlug(
            assessmentList,
            remainingAssessment?.assessment_id
          );
          navigate(`/get-started/${slug}`);
        } else {
          navigate(`/assessments`);
        }
      }
    }
  }, [apiTime]);

  useEffect(() => {
    let interval;
    const sec = apiTime?.time;
    if (sec !== undefined) {
      if (sec !== 0) {
        interval = setInterval(() => {
          if (sec > 0) {
            setApiTime((prev) => ({ ...prev, time: sec - 1 }));
          }
        }, 1000);
      }
    }
    return () => {
      clearInterval(interval);
    };
  }, [apiTime?.time]);

  useEffect(() => {
    let interval;
    const sec = polling?.seconds;
    if (sec > 0) {
      interval = setInterval(() => {
        getResult();
        setPolling((prev) => ({ ...prev, seconds: prev?.seconds - 3 }));
      }, 3000);
    } else if (polling?.isPolling) {
      setDefault();
      showToast("Please complete assessment first", "error");
      setPolling({ isPolling: false, seconds: 0 });
    }
    return () => {
      clearInterval(interval);
    };
  }, [polling]);

  const getTransformValue = (curQuestionNum) => {
    const pxToTranslate =
      progressBar?.current?.offsetWidth - giftBox?.current?.offsetWidth;
    let progress = curQuestionNum / (questionsList?.length + 1);
    if (assessmentResult?.completed) {
      progress = 1;
    } else if (assessmentResult?.need_tie_breaker) {
      progress =
        (assessmentResult?.curTieQue / (assessmentResult?.totalTieQue + 1) +
          questionsList?.length) /
        (questionsList?.length + 1);
    }
    const translate = progress * pxToTranslate;

    return `translateX(${translate}px)`;
  };

  const getProgress = (curQuestionNum) => {
    let progress = curQuestionNum;
    if (assessmentResult?.completed) {
      progress = questionsList?.length + 1;
    } else if (assessmentResult?.need_tie_breaker) {
      progress =
        assessmentResult?.curTieQue / (assessmentResult?.totalTieQue + 1) +
        questionsList?.length;
    }
    return progress;
  };
  return {
    currentQuestionNum: assessmentResult?.currentQuestionNum,
    nextQuestion,
    progressBar,
    getTransformValue,
    giftBox,
    questions,
    loadingState,
    apiTime,
    tie: assessmentResult?.need_tie_breaker,
    disableQuestions,
    isComplete: assessmentResult?.completed,
    logOut,
    getProgress,
    isRotated,
    currentAssessmentType,
    questionsList,
  };
};
