import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { SwiperSlide } from "swiper/react";
import VSNTypography from "../../shared/VSNTypography";
import VSNImage from "../../shared/VSNImage";
import { VSNSwiper } from "../../shared/VSNSwiper";
import VSNPaper from "../../shared/VSNPaper";
import Bgshape1 from "../../assets/assesments/png/Bg-shape-1.png";
import Bgshape2 from "../../assets/assesments/png/Bg-shape-2.png";
import Bgshape3 from "../../assets/assesments/png/Bg-shape-3.png";
import Bgshape4 from "../../assets/assesments/png/Bg-shape-4.png";
import cardImage from "../../assets/assesments/png/card-anxiety.png";
import arrow from "../../assets/quickView/svg/swiper-arrow.svg";

const cardDesign = [
  {
    color: `linear-gradient(177.63deg, #FCF0FF 17.11%, rgba(232, 244, 252, 0) 83.99%)`,
    image: Bgshape1,
  },
  {
    color: `linear-gradient(180deg, #F0F9F2 0%, rgba(242, 231, 245, 0) 100%)`,
    image: Bgshape2,
  },
  {
    color: `linear-gradient(180.31deg, #FFF2EB 3.46%, rgba(251, 238, 255, 0) 102.41%)`,
    image: Bgshape3,
  },
  {
    color: `linear-gradient(180deg, #E6F1FA 8.02%, rgba(255, 247, 244, 0) 94.74%)`,
    image: Bgshape4,
  },
];

const SwiperCardStyle = makeStyles((theme) => ({
  assessmentReportCard: {
    padding: "20px 25px",
    borderRadius: 10,
    background: "#FCFCFD",
    border: `2px solid ${theme.palette.primary.light} `,
    boxShadow: "0px 7.7982px 17.32934px 0px rgba(81, 125, 132, 0.07)",
    position: "relative",
    zIndex: 3,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontSize: 20,
      lineHeight: "26px",
      fontWeight: 700,
      color: theme.palette.secondary.main,
      position: "relative",
      "&:before": {
        position: "absolute",
        content: "''",
        top: 2,
        height: 22,
        left: -25,
        width: 4,
        borderRadius: "0 10px 10px 0",
        background: theme.palette.primary.basic,
        [theme.breakpoints.down("sm")]: {
          left: -15,
        },
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: 20,
        lineHeight: "24px",
      },
    },
  },
  mdHeadingMain: {
    "&.MuiTypography-root": {
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: 700,
      color: "#86650A",
      position: "relative",
      "& span": {
        color: "#555FDD",
        fontSize: 22,
      },
      "&:before": {
        position: "absolute",
        content: "''",
        top: 2,
        height: 22,
        left: -25,
        width: 4,
        borderRadius: "0 10px 10px 0",
        background: theme.palette.primary.basic,
        [theme.breakpoints.down("sm")]: {
          left: -15,
        },
      },
      [theme.breakpoints.down("1200")]: {
        fontSize: 18,
        lineHeight: "22px",
      },
    },
  },
  desc: {
    fontSize: 16,
    lineHeight: "23px",
    color: theme.palette.secondary.grey,
    fontWeight: 400,
    "& span": {
      color: "#86650A",
    },
  },
  containerCard: {
    width: "100%",
    "& .divider": {
      position: "relative",
      maxWidth: "100%",
      "&:after": {
        position: "absolute",
        content: "''",
        top: 0,
        left: "calc(100% + 12px)",
        background: "linear-gradient(#555FDD00 ,#555edd26,#555FDD00)",
        height: "100%",
        width: 2,
      },
    },
    "& .swiper-pagination": {
      bottom: 0,
      "& .swiper-pagination-bullet": {
        width: 24,
        height: 4,
        background: "#dfe1f899",
        borderRadius: 12,
        transition: "all 0.3s ease-in-out",
        "&.swiper-pagination-bullet-active": {
          background: "#E87637",
          width: 11,
        },
      },
    },
    "& .swiper-button-prev, .swiper-button-next": {
      top: "40%",
      "&:after": {
        background: `url(${arrow})`,
        fontSize: 0,
        height: 27,
        width: 27,
        backgroundSize: "contain",
      },
    },
    "& .swiper-button-next": {
      transform: "rotate(180deg)",
    },
  },
  containerCardDeatils: {
    minHeight: "260px",
    background: `linear-gradient(177.63deg, #FCF0FF 17.11%, rgba(232, 244, 252, 0) 83.99%)`,
    border: "none",
    padding: "14px  20px 20px 22px",
    overflow: "hidden",
    position: "relative",
    boxShadow: "none",
    textAlign: "center",
    borderRadius: "16px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  bgShape: {
    position: "absolute",
    top: 0,
    right: 0,
    opacity: 0.5,
  },
}));

const SwiperCardComponent = (props) => {
  const { title, description, data } = props;
  const classes = SwiperCardStyle();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item md={12} xs={12}>
      <Stack className={classes.assessmentReportCard}>
        <Box sx={{ padding: isSmallScreen ? "15px" : "" }}>
          <VSNTypography
            variant="h3"
            component="h3"
            className={classes.mdHeading}
            mb={1.5}
          >
            {title}
          </VSNTypography>
          <VSNTypography component="p" className={classes.desc} mb={1.5}>
            {description}
          </VSNTypography>
        </Box>
        <Box sx={{ padding: isSmallScreen ? "0px" : "initial" }}>
          <VSNSwiper
            className={classes.containerCard}
            pagination
            navigation
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              600: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: data?.length <= 3 ? 3 : 4,
                spaceBetween: 25,
              },
              1600: {
                slidesPerView: data?.length <= 3 ? 3 : 4,
                spaceBetween: 25,
              },
            }}
          >
            {data?.map((item, ind) => {
              const { color, image } = cardDesign[ind % cardDesign.length];
              return (
                <SwiperSlide key={ind}>
                  <VSNPaper
                    className={classes.containerCardDeatils}
                    style={{ background: color }}
                  >
                    <Box width="fit-content" mx="auto">
                      <VSNImage
                        src={item?.image ?? cardImage}
                        alt="cardImage"
                        height="110px"
                        width="auto"
                      />
                    </Box>
                    <VSNTypography
                      variant="h4"
                      component="h4"
                      fontSize={{ xts: 18, xs: 17 }}
                      fontWeight={700}
                      lineHeight="23px"
                      color="#86650A"
                      mb={{ xts: 1.3, xs: 1.15 }}
                      textAlign="center"
                    >
                      {item?.title}
                    </VSNTypography>
                    <VSNTypography
                      variant="p"
                      component="p"
                      fontSize={{ sm: 16, xts: 14 }}
                      fontWeight={500}
                      lineHeight="20px"
                      color="#747787"
                      textAlign="left"
                    >
                      {item?.description}
                    </VSNTypography>

                    <VSNImage
                      src={image}
                      alt="shape"
                      className={classes.bgShape}
                    />
                  </VSNPaper>
                </SwiperSlide>
              );
            })}
          </VSNSwiper>
        </Box>
      </Stack>
    </Grid>
  );
};

export default SwiperCardComponent;
