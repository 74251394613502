import { makeStyles } from "@mui/styles";
import { Box, Stack } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import VSNSearchBox from "../../shared/VSNSearchBox";
import VSNSelect from "../../shared/VSNSelect";
import VSNTypography from "../../shared/VSNTypography";
import VSNButton from "../../shared/VSNButton";
import { ReactComponent as Download } from "../../assets/admin/svg/download-icon.svg";
import { ReactComponent as Reset } from "../../assets/admin/svg/reset-icon.svg";
import {
  dateRange,
  dateRangeSignin,
} from "../../description/filters.description";
import VSNDatePicker from "../../shared/VSNDatePicker";
import { teamsButtonContainer } from "../../description/orgDashboard.description";
import {
  DeleteIcon,
  InviteIcon,
  InviteMemberIcon,
  PlusIcon,
} from "../../shared/icon";
import { toggleModelShow } from "../../shared/CustomFunc";
import { department_admin, organization_admin } from "../../utils/constant";

const useStyles = makeStyles((theme) => ({
  search: {
    maxWidth: 405,
    width: "100%",
    flex: "0 0 40%",
    [theme.breakpoints.down("1440")]: {
      flex: "0 0 30%",
    },
    [theme.breakpoints.down("1230")]: {
      flex: "0 0 24%",
    },
    "& .MuiFormControl-root": {
      background: "#F9F9F9",
      width: "100%",
      borderRadius: 5,

      "& .MuiInputBase-root": {
        height: 40,
        paddingLeft: 5,
        [theme.breakpoints.down("md")]: {
          height: 40,
        },
        "& input": {
          padding: 8,
          paddingLeft: 15,
          fontSize: 16,
          "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus":
            {
              WebkitBoxShadow: `0 0 0px 40rem #f9f9f9 inset`,
              borderRadius: 6,
            },
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
  createButton: {
    "&.MuiButton-root": {
      padding: "11px 25px",
      "& svg": {
        height: 20,
      },
      "& .content": {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "18px",
        display: "flex",
        alignItems: "center",
        gap: 8,
        [theme.breakpoints.down("md")]: {
          fontSize: 14,
        },
        "& .MuiStack-root": {
          gap: 10,
        },
      },
      [theme.breakpoints.down("md")]: {
        padding: "8px 12px",
      },
    },
  },
  fieldWrapper: {
    flex: "1",
    justifyContent: "end",
    [theme.breakpoints.down("1300")]: {
      flex: "1",
      justifyContent: "end",
    },
  },
  filterMain: {
    marginTop: 0,
    padding: "0 15px 15px",
  },
  sortFilter: {
    [theme.breakpoints.down("xsm")]: {
      width: "100%",
    },
    "& p": {
      fontSize: 12,
      marginBottom: 8,
      fontWeight: 500,
      lineHeight: "15px",
    },
    "& .MuiStack-root": {
      overflow: "unset",
      paddingTop: 0,
    },

    "& .MuiFormControl-root": {
      minWidth: 181,
      [theme.breakpoints.down("400")]: {
        minWidth: 170,
      },

      "& .MuiInputBase-root": {
        borderRadius: 8,
        height: 40,
        "& svg": {
          pointerEvents: "none",
          cursor: "pointer",
          position: "absolute",
          right: "7px",
          color: "#747787",
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #000",
          },
          "& svg": {
            transform: "rotate(180deg)",
          },
        },
        "& .MuiSelect-select": {
          minWidth: 112,

          padding: [[8, 32, 8, 12]],
          fontWeight: 500,
          fontSize: 14,
          color: theme.palette.secondary.main,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #EAEDF0",
        },
      },
    },
  },
  filter: {
    [theme.breakpoints.down("xsm")]: {
      width: "100%",
    },
    "& p": {
      fontSize: 12,
      marginBottom: 8,
      fontWeight: 500,
      lineHeight: "15px",
    },
    "& .MuiInputBase-root": {
      borderRadius: 8,
      height: 40,
      "& svg": {
        pointerEvents: "none",
        cursor: "pointer",
        position: "absolute",
        right: "7px",
        color: "#747787",
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #000",
        },
        "& svg": {
          transform: "rotate(180deg)",
        },
      },
      "& .MuiSelect-select": {
        minWidth: 112,
        padding: [[8, 32, 8, 12]],
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.secondary.main,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #EAEDF0",
      },
    },
    // "& .MuiAutocomplete-root": {
    //   minWidth: 155,
    //   maxWidth: 300,
    //   border: "1px solid #EAEDF0",
    //   borderRadius: 8,
    //   overflow: "hidden",
    //   "&:hover": {
    //     borderColor: "#000",
    //   },
    //   "&.Mui-focused": {
    //     "& .MuiOutlinedInput-notchedOutline": {
    //       borderColor: "#000",
    //     },
    //   },
    //   "& .MuiInputBase-root": {
    //     borderRadius: 8,
    //     padding: [[5, 32, 5, 12]],
    //     height: 48,
    //     overflow: "auto",

    //     "& input": {
    //       padding: 0,
    //       fontWeight: 500,
    //       color: theme.palette.secondary.main,
    //     },
    //     "& .MuiOutlinedInput-notchedOutline": {
    //       border: "none",
    //     },
    //   },
    // },
  },
  resetButton: {
    background: "#555FDD0D",
    borderRadius: 5,
    padding: "11px 11.5px",
    display: "flex",
    "& p": {
      fontSize: 14,
      color: theme.palette.primary.main,
      cursor: "pointer",
      fontWeight: 500,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      lineHeight: "18px",
      gap: 10,
      "& svg": {
        transform: "rotate(300deg)",
      },
    },
  },
  filterAutocomplete: {
    "& .MuiPaper-root": {
      "& ul": {
        "& li": {
          "& .MuiButtonBase-root.MuiCheckbox-root": {
            paddingLeft: 0,
          },
        },
      },
    },
  },
  downloadReportButton: {
    "& .MuiButtonBase-root": {
      marginBottom: 0,
      padding: "8px 18px",
      lineHeight: "21px",
      fontSize: 16,
      borderRadius: 5,
    },
  },

  filterDropDown: {
    "& .MuiPaper-root": {
      maxHeight: 250,
      "& ul": {
        "& li": {
          display: "flex",
          justifyContent: "start",
          padding: "6px 16px",
          "& img": {
            height: 28,
            width: "auto",
          },
        },
      },
    },
  },
  btnDelete: {
    padding: "8px",
    height: 40,
    borderRadius: 5,
    background: "#F543460D",
    color: "#F54346",
    width: "fit-content",
    alignItems: "center",
    "&.disabled": {
      cursor: "not-allowed",
    },
  },
  sortFilterMain: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 16,
    [theme.breakpoints.down("1600")]: {
      "& .MuiFormControl-root": {
        minWidth: "0",
        "& .MuiInputBase-root": {
          "& .MuiSelect-select": {
            minWidth: 100,
          },
        },
      },
    },
    [theme.breakpoints.down("xsm")]: {
      "& > div": {
        width: "100%",
      },
    },
  },
  btnOrangeOutlined: {
    "&.MuiButton-root": {
      "&.MuiButtonBase-root": {
        padding: "8px 18px",
        "& .content": {
          display: "flex",
          alignItems: "center",
          fontSize: 16,
          fontWeight: 700,
        },
      },
    },
  },
  disableDownloadReportButton: {
    cursor: "not-allowed",
  },
  button: {
    "&.MuiButtonBase-root": {
      color: theme.palette.primary.light,
      padding: "11px 11.5px",
      borderRadius: 5,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& svg": {
        height: 18,
        width: 18,
      },
      "& span.icon": {
        marginLeft: 8,
        display: "inline-block",
      },
      "& .content": {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
      },
      "& .loader": {
        "& .MuiCircularProgress-root": {
          height: "16px !important",
          width: "16px !important",
        },
      },
      "&.link": {
        background: "white",
        border: "1px solid #555FDD",
        color: "#555FDD",
        "& .linkIcon": {
          height: "16px",
          width: "16px",
          display: "inline-block",
        },
      },
    },
  },
  inviteButton: {
    "&.MuiButtonBase-root": {
      color: theme.palette.primary.light,
      padding: "11px 11.5px",
      borderRadius: 5,
      border: 0,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& svg": {
        height: 18,
        width: 18,
      },
      "& span.icon": {
        marginLeft: 8,
        display: "inline-block",
      },
      "& .content": {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
      },
      "& .loader": {
        "& .MuiCircularProgress-root": {
          height: "16px !important",
          width: "16px !important",
        },
      },
      "&.link": {
        background: "#555FDD0D",
        color: "#555FDD",
        "& .linkIcon": {
          height: "20px",
          width: "20px",
          display: "inline-block",
        },
      },
    },
  },
}));

const FiltersContainer = ({
  inviteAdminName,
  sortBy,
  organizationsFiler,
  locationFiler,
  organizationsTypeFiler,
  onFilterChange,
  resetFilters,
  filters,
  onSearchChange,
  downloadData,
  orgFilters,
  locationFilters,
  orgType,
  keyword,
  csvLoader,
  searchRef,
  orgDefaultOptions,
  isSortFilter,
  isDownload,
  assessmentStatusFilter,
  activeTab,
  showOrgTypeFilter,
  showOrgFilter,
  showLastVisitedFilter,
  showResetFilter,
  showDateFilter,
  showDownload,
  className,
  onDeleteClick,
  selectedRows,
  showDeleteButton,
  showLocationFilter,
  showAssessmentSort,
  handleButtonClick,
  disableDownload,
  showSearchInput,
  hidePagination,
  userData,
  pathname,
  showCreateButton,
  createLocation,
  createDepartment,
  showOrgLocationFilter,
  showDepartmentTypeFilter,
  handleInviteAdmin,
  makeAdmin,
  inviteMembersDepartment,
  locationFilter,
  locationFiltersValue,
  departmentFilter,
  departmentFiltersValue,
  ...rest
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const buttons = teamsButtonContainer();
  const permission = useSelector(
    (state) => state?.app?.userData?.user_profile?.permissions
  );
  const role = useSelector((state) => state?.app?.userData?.user_profile?.role);
  const { locationFilerLoader = locationFilters } = useSelector(
    (state) => state?.api?.loader
  );

  return (
    <Box
      className={`${classes.filterMain}`}
      {...rest}
      p={activeTab === "assessments" ? 0 : null}
    >
      <Stack
        direction={{ lg: "row", xs: "column" }}
        justifyContent="space-between"
        alignItems={{ lg: "end", xs: "center" }}
        gap={2}
      >
        {showSearchInput && (
          <div className={classes.search}>
            <VSNSearchBox
              onChange={onSearchChange}
              name="keyword"
              inputRef={searchRef}
            />
          </div>
        )}
        <Stack
          direction="row"
          alignItems="end"
          gap={{ xs: 2 }}
          flexWrap="wrap"
          className={classes.fieldWrapper}
        >
          {showOrgTypeFilter && (
            <div className={classes.filter}>
              <VSNTypography variant="body1" component="p">
                {organizationsTypeFiler.label}
              </VSNTypography>

              <VSNSelect
                fullWidth
                {...{
                  ...organizationsTypeFiler,
                  options: [
                    ...orgDefaultOptions,
                    ...(orgType !== undefined ? orgType : []),
                  ],
                  onChange: onFilterChange,
                  classes: { popover: classes.filterDropDown },
                  ...{
                    value: filters?.organizationType
                      ? filters?.organizationType
                      : 0,
                  },
                }}
              />
            </div>
          )}

          {locationFilter && role === organization_admin && (
            <div className={classes.filter}>
              <VSNSelect
                name="location"
                fullWidth
                value={filters?.location || 0}
                placeHolder="Location"
                options={
                  [{ key: "All", value: "" }, ...locationFiltersValue] ?? []
                }
                onChange={onFilterChange}
              />
            </div>
          )}
          {departmentFilter && role !== department_admin && (
            <div className={classes.filter}>
              <VSNSelect
                name="department"
                fullWidth
                value={filters?.department || 0}
                placeHolder="Department"
                options={[
                  { key: "All", value: "" },
                  ...(departmentFiltersValue ?? []),
                ]}
                onChange={onFilterChange}
              />
            </div>
          )}
          {!isSortFilter && (
            <Stack
              direction="row"
              className={`${classes.sortFilter} ${classes.sortFilterMain}`}
              justifyContent="center"
            >
              {sortBy &&
                Object.keys(sortBy).map((sortOptionKey, i) => {
                  return (
                    <div key={i}>
                      <VSNTypography variant="body1" component="p">
                        {sortBy[sortOptionKey]?.label}
                      </VSNTypography>
                      <VSNSelect
                        key={sortOptionKey}
                        fullWidth
                        {...{
                          ...sortBy[sortOptionKey],
                          onChange: onFilterChange,
                          value: filters?.[sortOptionKey] || 0,
                          classes: { popover: classes.filterDropDown },
                          placeHolder: sortBy[sortOptionKey]?.label,
                        }}
                      />
                    </div>
                  );
                })}
            </Stack>
          )}
          {isSortFilter && (
            <div className={classes.sortFilter}>
              <VSNTypography variant="body1" component="p">
                {assessmentStatusFilter?.label}
              </VSNTypography>
              <div className={classes.sortFilter}>
                <VSNSelect
                  {...{
                    ...assessmentStatusFilter,
                    onChange: onFilterChange,
                    classes: { popover: classes.filterDropDown },
                    value: filters?.assessment_given || 0,
                  }}
                />
              </div>
            </div>
          )}
          {showAssessmentSort && (
            <div className={classes.sortFilter}>
              <VSNTypography variant="body1" component="p">
                {activeTab === "organizations"
                  ? "Organizations Created"
                  : activeTab === "locations"
                  ? "Locations Created"
                  : activeTab === "departments"
                  ? "Departments Created"
                  : "Assessment Completed"}
              </VSNTypography>
              <VSNDatePicker
                {...{
                  ...dateRange,
                  onFilterChange,
                  value: filters?.date_filter,
                }}
              />
            </div>
          )}
          {showLastVisitedFilter && (
            <div className={classes.sortFilter}>
              <VSNTypography variant="body1" component="p">
                Last Visited
              </VSNTypography>
              <VSNDatePicker
                {...{
                  ...dateRangeSignin,
                  onFilterChange,
                  value: filters?.last_signin_at,
                }}
              />
            </div>
          )}
          {/* {showOrgFilter && (
            <div className={classes.filter}>
              <VSNTypography variant="body1" component="p">
                {organizationsFiler?.label}
              </VSNTypography>

              <VSNSelect
                fullWidth
                {...{
                  ...organizationsFiler,
                  options: [
                    ...orgDefaultOptions,

                    ...(orgFilters !== undefined ? orgFilters : []),
                  ],
                  onChange: onFilterChange,
                  classes: { popover: classes.filterDropDown },
                  ...{
                    value: filters?.organization ? filters?.organization : 0,
                  },
                }}
              />
            </div>
          )} */}
          {showDepartmentTypeFilter && (
            // hear add department type api
            <div className={classes.filter}>
              <VSNTypography variant="body1" component="p">
                {organizationsFiler?.label}
              </VSNTypography>

              {/* <VSNSelect
                fullWidth
                {...{
                  ...organizationsFiler,
                  options: [
                    ...orgDefaultOptions,

                    ...(orgFilters !== undefined ? orgFilters : []),
                  ],
                  onChange: onFilterChange,
                  classes: { popover: classes.filterDropDown },
                  ...{
                    value: filters?.organization ? filters?.organization : 0,
                  },
                }}
              /> */}
            </div>
          )}
          {showOrgLocationFilter && (
            // hear add location api
            <div className={classes.filter}>
              <VSNTypography variant="body1" component="p">
                {organizationsFiler?.label}
              </VSNTypography>

              <VSNSelect
                fullWidth
                {...{
                  ...organizationsFiler,
                  options: [
                    ...orgDefaultOptions,

                    ...(orgFilters !== undefined ? orgFilters : []),
                  ],
                  onChange: onFilterChange,
                  classes: { popover: classes.filterDropDown },
                  ...{
                    value: filters?.organization ? filters?.organization : 0,
                  },
                }}
              />
            </div>
          )}
          {showLocationFilter && (
            <div className={classes.filter}>
              <VSNTypography variant="body1" component="p">
                {locationFiler?.label}
              </VSNTypography>

              <VSNSelect
                fullWidth
                {...{
                  ...locationFiler,
                  options: [
                    ...orgDefaultOptions,

                    ...(locationFilters !== undefined ? locationFilters : []),
                  ],
                  onChange: onFilterChange,
                  classes: { popover: classes.filterDropDown },
                  ...{
                    value: filters?.location ? filters?.location : 0,
                  },
                }}
              />
            </div>
          )}
          {activeTab === "teams" && (
            <>
              {buttons.map(({ value, icon, key }, index) => (
                <VSNButton
                  key={index}
                  variant="outlined"
                  className={classes.btnOrangeOutlined}
                  handleClick={(e) => handleButtonClick(e, key)}
                >
                  {icon} &nbsp;{value}
                </VSNButton>
              ))}
            </>
          )}
          {role === department_admin && inviteMembersDepartment && (
            <VSNButton
              handleClick={() => dispatch(toggleModelShow(`inviteMembers`))}
              className={classes.createButton}
            >
              <Stack direction="row" alignItems="center">
                <InviteMemberIcon />
              </Stack>
              Invite Members
            </VSNButton>
          )}
          {showResetFilter && (
            <VSNButton
              className={`${classes.inviteButton} link`}
              handleClick={resetFilters}
            >
              <span className="linkIcon">
                <Reset />
              </span>
              Reset
            </VSNButton>
          )}
          {showCreateButton && (
            <VSNButton variant="outlined" endIcon={<Download />}>
              Download
            </VSNButton>
          )}
          {makeAdmin &&
            inviteAdminName &&
            permission?.[activeTab]?.includes("invite") && (
              <VSNButton
                className={`${classes.inviteButton} link`}
                handleClick={() => handleInviteAdmin()}
                disableFocusRipple
              >
                <span className="linkIcon">
                  <InviteIcon />
                </span>
                {inviteAdminName}
              </VSNButton>
            )}
          {createDepartment &&
            permission?.department &&
            permission?.department?.includes("create") && (
              <VSNButton
                className={classes.createButton}
                handleClick={() =>
                  dispatch(toggleModelShow("createDepartment"))
                }
              >
                <Stack direction="row" alignItems="center">
                  <PlusIcon />
                  Create Department
                </Stack>
              </VSNButton>
            )}
          {createLocation &&
            permission?.location &&
            permission?.location?.includes("create") && (
              <VSNButton
                className={classes.createButton}
                handleClick={() => dispatch(toggleModelShow("createLocation"))}
              >
                <Stack direction="row" alignItems="center">
                  <PlusIcon />
                  Create Location
                </Stack>
              </VSNButton>
            )}
          {showDownload && !isDownload && (
            <div
              className={`${classes.downloadReportButton} ${
                disableDownload ? classes.disableDownloadReportButton : ""
              }`}
            >
              <VSNButton
                variant="outlined"
                endIcon={<Download />}
                handleClick={downloadData}
                isLoading={csvLoader}
                disabled={disableDownload}
              >
                Download
              </VSNButton>
            </div>
          )}
          {showDeleteButton &&
            (activeTab === "members" && pathname === "/org-admin"
              ? userData?.user_profile?.organization_name !== "Guest"
              : true) && (
              <Stack
                direction="row"
                className={`${classes.btnDelete} ${
                  selectedRows?.length <= 0 ? "disabled" : ""
                }`}
                onClick={() => selectedRows?.length > 0 && onDeleteClick(null)}
              >
                <DeleteIcon />
                <VSNTypography
                  fontSize={13}
                  lineHeight="17px"
                  fontWeight="500"
                  color="#F54346"
                  ml={1}
                  style={{
                    cursor:
                      selectedRows?.length <= 0 ? "not-allowed" : "pointer",
                  }}
                >
                  Delete
                </VSNTypography>
              </Stack>
            )}
          {/* )} */}
        </Stack>
      </Stack>
    </Box>
  );
};

export default FiltersContainer;
