export const successStatus = [200, 201, 204];
export const isAuthenticated = "amplify-signin-with-hostedUI";
export const unauthStatus = [401];
export const signinByPassword = "Temppass@123";
export const adminOTPPath = "/admin/otp";
export const userOTPPath = "/otp";
export const assessmentAssessableRoles = ["admin", "user"];

export const endPoints = {
  assessmentReports: "assessments/report",
  organizations: "organization",
  users: "members",
  locations: "locations",
  departments: "departments",
  deleteusers: "members",
  assessmentReportsDownload: "download-reports",
  organizationsDownload: "download/organizations",
  memberLogin: "auth/signin",
  memberSignUp: "auth/signup",
  demoUserLogin: "/auth/signin-by-password",
  generateSubmissionId: "assessment/generate-submission-id",
  markerDetails: "assessment/markers-details",
  session: "auth/session",
  signup: "/auth/signup",
  assessmentResult: "assessment/result",
  confirmOTP: "/auth/confirm-otp",
  downloadReport: "assessment/download-report",
  editProfile: "user/profile",
  createTeam: "teams/create-team",
  myTeams: "teams/my-teams",
  notMemberTeams: "teams/member/not-in",
  exitTeam: "teams/exit-team",
  joinTeam: "teams/join",
  joinOrganization: "organization/join",
  defaultProfilePictures: "teams/default-profile-pictures",
  contactUs: "contact-us/",
};

export const adminPoints = {
  adminLogin: "platform-admin/signin",
  organizationDetail: "platform-admin/organizations",
  createOrg: "platform-admin/organization",
  sendMail: "admin/send-email",
  getSummary: "platform-admin/summary",
  resendEmail: "platform-admin/resend-email",
  organizationType: "platform-admin/organization-types",
  createLocation: "platform-admin/locations",
  createDepartments: "platform-admin/departments",
};

export const onePagerPoints = {
  isi: `assessment/isi/result`,
  anxiety: `assessment/gad7/result`,
};

export const assessmentIds = {
  vasanaType: "1",
  introExtro: "2",
  ptsd: "3", // stress
  isi: "4", // sleep
  phq: "5", // happiness
  proQOL: "6", // quality
  audit: "7", // alcohol
  mspss: "8", // social
  anxiety: "9",
};

export const assessmentNames = {
  1: "vasana-assessment",
};

export const assessmentTypes = {
  vasanaType: "Options",
  introExtro: "Slider",
  isi: "Slider",
};
export const organization_admin = "organization_admin";
export const department_admin = "department_admin";
export const location_admin = "location_admin";
export const head_clinician = "head_clinician";
export const clinician = "clinician";
export const location_admin_name = [location_admin, head_clinician, clinician];
export const organization_admin_name = [organization_admin];
export const department_admin_name = [department_admin];
export const organization_location_admin = [
  ...location_admin_name,
  ...organization_admin_name,
];
export const all_admin = [
  ...location_admin_name,
  ...organization_admin_name,
  ...department_admin_name,
];

export const nameOfRole = {
  organization_admin: "Organization Admin",
  location_admin: "Location Admin",
  department_admin: "Department Admin",
  head_clinician: "Head Clinician",
  clinician: "Clinician",
};

export const RoleToId = {
  location_admin: 2,
  head_clinician: 3,
  clinician: 4,
};
export const IdToRole = {
  2: location_admin,
  3: head_clinician,
  4: clinician,
};
