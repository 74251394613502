import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ON_FLAG_CHANGE,
  ON_FORM_CHANGE,
  ON_MODEL_STATE_CHANGE,
  SET_API_DATA,
  TAB_CHANGE,
} from "../redux/constants";
import { ApiContainer } from "../utils/api";
import { getEndpoint, toggleModelShow } from "../shared/CustomFunc";
import { FilterTab } from "../description/orgDashboard.description";
import { copyToClipboard, equal, keys } from "../utils/javascript";
import { LinkIcon, RightArrow } from "../shared/icon";
import { department_admin, location_admin_name } from "../utils/constant";
import { showToast } from "../utils/toastService";

export const orgDashboardContainer = (props) => {
  const { api } = ApiContainer();
  const dispatch = useDispatch();

  const organization_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_id
  );
  const reFetchSummary = useSelector(
    (state) => state?.app?.flags?.reFetchSummary
  );
  const organization_original_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_original_id
  );
  const location_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.location_id
  );
  const department_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.department_id
  );

  const isOrgAdmin = useSelector(
    (state) => state?.app?.userData?.user_profile?.role
  );
  const organization_type_id = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_type_id
  );

  const { permissions, role } =
    useSelector((state) => state?.app?.userData?.user_profile) ?? {};
  const tabs = [
    ...(FilterTab(role, organization_type_id, permissions).filter(
      (tab) => permissions && permissions[tab?.name]
    ) ?? {}),
  ];
  const payloadForEndPoint = {
    organization_original_id,
    location_id,
    department_id,
    role,
  };
  const modelState = useSelector((state) => state.modelStates);
  const form = useSelector((state) => state?.form);
  const { formData, formErrors } = form;
  const formPath = { parent: "inviteMembersForm" };
  const inviteMembersData = formData?.[formPath.parent];
  const inviteMembersError = formErrors?.[formPath.parent];
  const tabsPath = "org-summary";
  const activeTab = useSelector((state) => state?.api?.tabs?.[tabsPath]);
  const handleTabChange = (newVal) => {
    dispatch({ type: TAB_CHANGE, payload: { [tabsPath]: newVal } });
  };
  const organization_summary = useSelector(
    (state) => state?.api?.organization_summary
  );
  const organizationSummary = useSelector(
    (state) => state?.api?.organizationSummary
  );
  const filters = useSelector((state) => state.form.formData?.[activeTab]);
  const loaders = useSelector((state) => ({
    orgSummaryLoader: state.api?.loader?.organization_summary,
    inviteMembers: state.api?.loader?.inviteMembers,
    organizationSummary: state.api?.loader?.organizationSummary,
    organizationInfo: state.api?.loader?.organizationInfo,
  }));

  const organizationName = useSelector(
    (state) => state?.app?.userData?.user_profile?.organization_name
  );
  const invitationLink = useSelector(
    (state) => state?.api?.organizationSummary?.invitation_link
  );

  const handleShareLink = (link) => {
    copyToClipboard(link);
  };

  const onFilters = ({ tab = "members", value, member_status }) => {
    // if (
    //   (member_status && member_status === filters?.member_status) ||
    //   (!member_status &&
    //     value &&
    //     filters?.sortByVasanaType?.toLowerCase() === value?.toLowerCase()) ||
    //   (!member_status &&
    //     !value &&
    //     tab === activeTab &&
    //     !filters?.sortByVasanaType &&
    //     !filters?.member_status)
    // )
    //   return;
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          [tab || "assessmentReports"]: {
            ...form.formData?.[tab || "assessmentReports"],
            page: 1,
            sortByVasanaType:
              value?.charAt(0).toUpperCase() + value?.slice(1) || null,
            rowsPerPage: 10,
            member_status,
            ...(tab !== activeTab ? { keyword: "" } : {}),
          },
        },
      },
    });
    dispatch({
      type: TAB_CHANGE,
      payload: { [tabsPath]: tab || "assessmentReports" },
    });
  };

  // const tabsEndpoint = {
  //   location_admin: `organization/${organization_original_id}/locations/${location_id}/admins`,
  //   organization_admin: `organization/${organization_original_id}/admins`,
  //   department_admin: `organization/${organization_original_id}/locations/${location_id}/departments/${department_id}/admins`,
  //   locations: `organization/${organization_original_id}/locations`,
  //   departments: `organization/${organization_original_id}/departments`,
  //   members: `organization/${organization_original_id}/members`,
  //   teams: `organization/${organization_original_id}/locations/${location_id}/departments/${department_id}/teams`,
  // };

  // const getSummary = async () => {
  //   if (activeTab === "assessments") return;
  //   if (!filters) return;
  //   let endPoint = tabsEndpoint[activeTab];
  //   if (activeTab === "members") {
  //     if (location_admin_name?.includes(role)) {
  //       endPoint = `organization/${organization_original_id}/locations/${location_id}/members`;
  //     } else if (role === department_admin) {
  //       endPoint = `organization/${organization_original_id}/locations/${location_id}/departments/${department_id}/members`;
  //     }
  //   }
  //   const {
  //     sortyByStatus,
  //     sortByRole,
  //     sortByVasanaType,
  //     page,
  //     rowsPerPage,
  //     keyword,
  //     date_filter,
  //   } = filters;
  //   const reportsData = await api({
  //     method: "GET",
  //     endPoint: `${endPoint}?page=${page}&limit=${rowsPerPage}${
  //       date_filter?.startDate ? `&start_date=${date_filter?.startDate}` : ``
  //     }${date_filter?.endDate ? `&end_date=${date_filter?.endDate}` : ``}${
  //       sortyByStatus ? `&member_status=${sortyByStatus}` : ``
  //     }${sortByVasanaType ? `&vasana_type=${sortByVasanaType}` : ``}${
  //       sortByRole ? `&member_role=${sortByRole}` : ``
  //     }${keyword ? `&keyword=${keyword}` : ``}`,
  //     showToastMessage: false,
  //     urlencoded: false,
  //     needLoader: true,
  //     parent: activeTab,
  //     needFullResponse: true,
  //   });

  //   if (reportsData?.status) {
  //     dispatch({
  //       type: SET_API_DATA,
  //       payload: {
  //         [activeTab]: reportsData.data,
  //       },
  //     });
  //   } else {
  //     dispatch({
  //       type: SET_API_DATA,
  //       payload: {
  //         [activeTab]: [],
  //       },
  //     });
  //   }
  // };

  const getOrganizationSummary = async () => {
    const summary = async () => {
      const summaryData = await api({
        method: "GET",
        endPoint: getEndpoint({
          ...payloadForEndPoint,
          endPoint: "summary",
        }),
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "organizationSummary",
      });
      if (summaryData?.status) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            organizationSummary: summaryData.data,
          },
        });
      }
    };
    const memberSummary = async () => {
      const summaryMemberData = await api({
        method: "GET",
        endPoint: getEndpoint({
          ...payloadForEndPoint,
          endPoint: "members/summary",
        }),
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: "organizationSummary",
      });
      if (summaryMemberData?.status) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            summaryMemberData: summaryMemberData.data,
          },
        });
      }
    };
    memberSummary();
    summary();
  };

  useEffect(() => {
    dispatch({ type: TAB_CHANGE, payload: { [tabsPath]: tabs[0]?.value } });
  }, [permissions]);

  useEffect(() => {
    if (organization_id) {
      getOrganizationSummary();
    }
  }, [organization_id]);
  useEffect(() => {
    if (reFetchSummary) {
      getOrganizationSummary();
      dispatch({ type: ON_FLAG_CHANGE, payload: { reFetchSummary: false } });
    }
  }, [reFetchSummary]);

  const handleClick = async (button) => {
    if (button === "Invite") {
      const formData = new FormData();
      if (inviteMembersData?.csv_file) {
        formData.append("members_csv", inviteMembersData?.csv_file_data);
      }
      if (inviteMembersData?.emails?.length) {
        formData.append(
          "members_to_invite",
          inviteMembersData?.emails?.toString()
        );
      }
      const data = await api({
        method: "POST",
        endPoint: `organization/${organization_id}/invite-members`,
        data: formData,
        showToastMessage: true,
        urlencoded: false,
        header: { "Content-Type": "multipart/form-data" },
        needLoader: true,
        parent: "inviteMembers",
      });
      if (data.status) {
        toggleModelShow("inviteMembers");
        props?.getReports({ formPath: { parent: "members" } });
      }
      dispatch({ type: ON_FLAG_CHANGE, payload: { reFetchSummary: true } });
    } else if (button === "Copy Invitation link") {
      copyToClipboard(invitationLink);
    }
  };

  const buttonsContainer = [
    {
      label: "Invite",
      icon: <RightArrow />,
      handleClick,
      disabled: !(
        (inviteMembersData?.emails?.length ||
          inviteMembersData?.csv_file_data) &&
        !inviteMembersError?.csv_file
      ),
      hasLoader: true,
    },
    {
      label: "Copy Invitation link",
      className: "link",
      link: <LinkIcon />,
      handleClick,
    },
  ];

  const onEditOrganization = async () => {
    const organizationData = await api({
      method: "GET",
      endPoint: `organization/${organization_original_id}`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "organizationInfo",
    });
    if (organizationData?.status) {
      dispatch(toggleModelShow("editOrg"));
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...formData,
            organizationInfo: organizationData?.data,
          },
        },
      });
      dispatch({
        type: SET_API_DATA,
        payload: {
          orgToEdit: organizationData?.data,
        },
      });
    }
  };

  const onEditLocation = async (
    location_id,
    organization_id = organization_original_id
  ) => {
    const locationData = await api({
      method: "GET",
      endPoint: `organization/${organization_id}/locations/${location_id}`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "locationInfo",
    });
    if (locationData?.status) {
      dispatch(toggleModelShow("editLocation"));
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            locationInfo: locationData?.data,
          },
        },
      });
    }
    dispatch({
      type: SET_API_DATA,
      payload: {
        locationToEdit: locationData?.data,
      },
    });
  };
  const onDepartmentEdit = async (
    department_id,
    organization_id = organization_original_id
  ) => {
    const departmentData = await api({
      method: "GET",
      endPoint: `organization/${organization_id}/locations/${location_id}/departments/${department_id}`,
      showToastMessage: false,
      urlencoded: false,
      needLoader: true,
      parent: "departmentInfo",
    });
    if (departmentData?.status) {
      dispatch(toggleModelShow("editDepartment"));
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            departmentInfo: departmentData?.data,
          },
        },
      });
    }
    dispatch({
      type: SET_API_DATA,
      payload: {
        departmentToEdit: departmentData?.data,
      },
    });
  };

  const findTab = tabs.filter(({ value }) => value === activeTab)[0];

  const openEventScheduleModel = async () => {
    const data = await api({
      method: "GET",
      endPoint: `organization/${organization_original_id}/locations/${location_id}/departments/${department_id}/event-schedule`,
      urlencoded: false,
      needLoader: true,
      parent: "changingRole",
      needFullResponse: false,
    });
    if (data?.status) {
      dispatch(toggleModelShow("createEventSchedule"));
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ...form.formData,
            // [formPath.parent]: { ...formData, [name]: value },
            eventScheduleInfo: data?.data,
          },
        },
      });
    } else {
      showToast(data?.error);
    }
  };

  return {
    isOrgAdmin,
    modelState,
    onEditLocation,
    toggleModelShow,
    organization_summary,
    loaders,
    tabs,
    handleTabChange,
    activeTab,
    onFilters,
    organizationSummary,
    organizationName,
    buttonsContainer,
    filters,
    organization_id,
    onEditOrganization,
    handleShareLink,
    onDepartmentEdit,
    permissions,
    findTab,
    openEventScheduleModel,
  };
};
