import { Box, Stack, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ReactComponent as VasanaLogo } from "../assets/Teams/svg/white-vasana-logo.svg";
import bgHeader from "../assets/common/png/bgChat-img.png";
import humaChatBotContainer from "../container/humaChatBot.container";
import { CloseIcon } from "../shared/icon";
import ChatBot from "./teams/team-view/ChatBot";

const useStyles = makeStyles((theme) => ({
  chatboxTitle: {
    "&.MuiStack-root": {
      cursor: "pointer",
      transition: "all 0.25s ease-in-out",
      zIndex: 99999,
      position: "fixed",
      right: 0,
      color: "#fff",
      "& .close": {
        height: 30,
        width: 30,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
          height: 20,
          width: 20,
          verticalAlign: "middle",
        },
      },
      "& svg": {
        height: 30,
        width: 30,
      },
    },
  },
  fixChatBox: {
    width: 391,
    zIndex: 99,
    boxShadow: "-3px -3px 34px 0px rgb(97 71 57 / 31%)",
    transition: "all 0.3s ease-in-out",
    right: 16,
    maxHeight: "calc(100vh - 200px)",
    minHeight: 350,
    border: "none",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      left: 25,
      right: "25px !important",
      top: "150px !important",
      width: "calc(100% - 50px)",
      boxShadow: "-3px -3px 34px 0px rgb(105 105 105 / 35%)",
      padding: 0,
      minHeight: 350,
      maxHeight: "unset",
      height: "calc(100% - 180px)",
    },
  },
  chatBoxWrapper: {
    width: "100%",
    maxWidth: 391,
    transition: "all 0.3s ease-in-out",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 285,
    },
    // "&.default": {
    //   transform: "none !important",
    // },
  },
  closeIconWrapper: {
    transition: "all 0.3s ease-in-out",
    height: 46,
    width: 46,
    marginLeft: "auto",
    borderRadius: 100,
    backgroundColor: theme.palette.primary.basic,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.light,
    position: "fixed",
    bottom: 25,
    right: 25,
    zIndex: 10,
    cursor: "pointer",
  },
  closeWrapper: {
    position: "fixed",
    top: 85,
    right: 25,
    height: 45,
    width: 45,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "100%",
    color: "#fff",
    zIndex: 9999,
    cursor: "pointer",
    "& svg": {
      height: "16px !important",
      width: "16px !important",
    },
  },
}));

export default function HumaChatBot() {
  const classes = useStyles();
  const {
    message,
    messages,
    typing,
    onMessageChange,
    onSendMessage,
    inputRef,
    // open,
    // toggleChatBox,
    anchorEl: open,
    position,
    ref,
    handleDragStart,
    handleClick,
  } = humaChatBotContainer();
  const matches = useMediaQuery("(max-width:599px)");

  return (
    <Stack
      ref={ref}
      direction="row-reverse"
      className={classes.chatboxTitle}
      alignItems="center"
      justifyContent="center"
      variant="contained"
      style={{
        top: position.y
          ? `calc(100vh - ${window.innerHeight - position.y}px)`
          : `calc(100vh - ${position.y + 50 + 51}px)`,
        bottom: position.y
          ? `calc(100vh - ${position.y + 51}px)`
          : `calc(100vh - ${window.innerHeight - 50}px)`,
      }}
    >
      {matches && open ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          className={classes.closeWrapper}
          onClick={handleClick}
        >
          <CloseIcon />
        </Stack>
      ) : null}
      {!(matches && open) ? (
        <Stack
          direction="row"
          alignItems="center"
          position="relative"
          sx={{
            userSelect: "none",
            padding: "6px 6px 6px 18px",
            borderRadius: "38px 0 0 38px",
            background: "#555FDD",
            height: 51,
            zIndex: 9999,
          }}
          id="chatBot"
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
          onClick={handleClick}
        >
          <Stack>
            {open ? (
              <Box className="close">
                <CloseIcon />
              </Box>
            ) : (
              <VasanaLogo />
            )}
          </Stack>
        </Stack>
      ) : null}
      {open && (
        <ChatBot
          height={
            position.y !== 0 && window.innerHeight / 2 > position.y
              ? window.innerHeight - 18 - 51 - position.y - 82 - 51 >= 326
                ? 326
                : 236
              : 326
          }
          className={`${classes.fixChatBox} ${open ? "open" : ""}`}
          sx={{
            top:
              position.y !== 0 && window.innerHeight / 2 > position.y
                ? window.innerHeight - 18 - 51 - position.y - 82 - 51 >= 326
                  ? 280
                  : 230
                : "unset",
            bottom:
              position.y !== 0 && window.innerHeight / 2 > position.y
                ? "unset"
                : 280,
            right: -40,
          }}
          headerStyle={{
            background: `url(${bgHeader}),#E87637`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            "& .MuiTypography-root": {
              color: "rgba(255, 255, 255, 0.70)",
              "&.MuiTypography-h3": {
                color: "#fff",
                "&:before": {
                  background: "white",
                },
              },
            },
          }}
          chatBoxFooter={0}
          message={message}
          messages={messages}
          typing={typing}
          onMessageChange={onMessageChange}
          onSendMessage={onSendMessage}
          inputRef={inputRef}
          bodyStyle={{ padding: "0 5px" }}
        />
      )}
    </Stack>
  );
}
