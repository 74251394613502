import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Stack,
  Toolbar,
  IconButton,
  Menu,
  Drawer,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Union from "../../assets/common/svg/union.svg";
import VSNImage from "../../shared/VSNImage";
import VSNButton from "../../shared/VSNButton";
import VSNTypography from "../../shared/VSNTypography";
import headerContainer from "../../container/header.container";
import { ReactComponent as ExitIcon } from "../../assets/header/svg/log-out.svg";
import VSNConfirmBox from "../../shared/VSNConfirmBox";
import { signOutContainer } from "../../container/signOut.container";
import { ReactComponent as VasanaLogo } from "../../assets/header/svg/vasana_logo.svg";
import { upperCase } from "../../utils/javascript";
import { headerLinks } from "../../description/header.description";
import VSNMembersAvatar from "../../shared/VSNMembersAvatar";
import { HamburgarIcon } from "../../shared/icon";

const useStyles = makeStyles((theme) => ({
  logoutIcon: {
    cursor: "pointer",
    width: 24,
    height: 24,
    position: "relative",
    top: "-2px",
  },
  headerLine: {
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.secondary.grey,
      marginRight: 14,
      [theme.breakpoints.down("lmd")]: {
        display: "none",
      },
    },
  },
  registerDetail: {
    "& p": {
      color: theme.palette.secondary.grey,
      fontWeight: 700,
      lineHeight: "23px",
    },
    "& a": {
      background: "linear-gradient(132.61deg, #e87637 32.09%, #ffbe78 163.78%)",
      padding: [[8, 20]],
      color: theme.palette.primary.light,
      borderRadius: 8,
      marginLeft: 15,
    },
  },
  header: {
    "&.MuiPaper-root": {
      "&.MuiAppBar-root": {
        zIndex: "1201 !important",
        backgroundColor: ({ isLandingPage, scrolled, openDrawer }) =>
          !openDrawer && isLandingPage && !scrolled ? "transparent" : "#fff",
      },
      "& .MuiToolbar-root": {
        [theme.breakpoints.down("sm")]: {
          minHeight: 64,
        },
      },
    },
  },
  profile: {
    lineHeight: "13px",
    marginRight: 10,
    width: "max-content",
    height: 32,
    minWidth: 32,
    backgroundColor: "#1F396D",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    padding: 6,
    flex: "0 0 32px",
    "& .MuiTypography-root": {
      fontSize: 12,
      lineHeight: 1.5,
    },
  },
  navItemsMain: {
    "&.MuiStack-root": {
      [theme.breakpoints.down("lmd")]: {
        display: "none",
      },
    },
    "& span": {
      lineHeight: "0",
    },
  },
  navItem: {
    "& a": {
      display: "flex",
      alignItems: "center",
      padding: [[21, 10]],
      gap: 6,
      minHeight: 24,
      position: "relative",
      transition: "all 0.3s ease-in-out",
      height: "100%",
      [theme.breakpoints.down("lmd")]: {
        padding: 10,
      },
      "&::after": {
        position: "absolute",
        content: '""',
        height: 4,
        width: "100%",
        background: theme.palette.primary.main,
        borderRadius: "4px 4px 0px 0px",
        left: 0,
        opacity: 0,
        bottom: 0,
        transition: "all 0.3s ease-in-out",
        zIndex: -1,
        [theme.breakpoints.down("lmd")]: {
          zIndex: 1,
        },
      },
      "& .MuiBox-root": {
        "&.active": {
          lineHeight: 0,
          display: "none",
        },
      },
      "&.active, &:hover": {
        "&::after": {
          opacity: ({ isLandingPage }) => (isLandingPage ? 0 : 1),
        },
        "& .MuiBox-root": {
          "&.active": {
            lineHeight: 0,
            display: "block",
          },
          display: "none",
          lineHeight: 0,
        },
        "& p": {
          color: ({ isLandingPage }) =>
            isLandingPage
              ? theme.palette.primary.basic
              : theme.palette.primary.main,
        },
        "& span": {
          display: "none",
        },
      },
    },
    "& p": {
      color: "#606370",
      lineHeight: "18px",
      fontSize: 14,
      fontWeight: 500,
      "&.landing-menu": {
        fontSize: 16,
        [theme.breakpoints.down(1199)]: {
          fontSize: 14,
        },
      },
    },
  },
  loginButtons: {
    "& .MuiButtonBase-root": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& .content": {
        fontSize: 14,
      },
      margin: 0,
      padding: [[7, 40]],
      fontSize: 14,
      height: 39,
      [theme.breakpoints.down("1199")]: {
        marginLeft: 8,
      },
      [theme.breakpoints.down("slg")]: {
        padding: [[4, 20]],
        height: 31,
        marginLeft: 0,
      },
      "& + .MuiButtonBase-root": {
        marginLeft: 16,
      },
    },
  },
  iconWrapper: {
    lineHeight: 0,
    "& svg": {
      height: 21,
      width: 21,
    },
  },

  search: {
    cursor: "pointer",
    marginRight: 12,
  },
  conformModel: {
    "& .MuiButtonBase-root": {
      margin: 0,
    },
  },
  profileText: {
    height: "32px !important",
    width: "32px !important",
    fontSize: "16px !important",
    border: "2px solid #fff !important",
  },

  drawerMain: {
    width: "100%",
    "& .MuiPaper-root": {
      top: 64,
      width: 300,
      padding: [[30, 15]],
      justifyContent: "space-between",
      height: "calc(100svh - 64px)",
      [theme.breakpoints.down("sm")]: {
        width: 200,
      },
      "& .MuiStack-root": {
        margin: "0",
        padding: "0",
      },
    },
  },
}));

const HeaderTabs = ({
  userRoleData,
  isUser,
  classes,
  direction,
  vasanaTypeAssessment,
  previousPath,
  pathname,
}) => {
  return (
    <Stack
      direction={direction}
      sx={{
        maxWidth: "max-content",
        margin: "0 auto",
        width: "100%",
      }}
      gap={1.25}
      pl={{ sm: 3 }}
      justifyContent="space-between"
    >
      {headerLinks({
        organizationName: userRoleData?.user_profile?.organization_name,
        userRole: userRoleData?.is_demo_user,
        userRoleForOrg: userRoleData?.user_profile?.role,
        isUser,
      })?.map(({ path, activeImg, label, img, disable, className }) => {
        return (
          <Stack
            direction="row"
            key={path}
            alignItems="center"
            className={classes.navItem}
            style={disable ? { cursor: "not-allowed" } : {}}
          >
            <NavLink
              to={
                path === "/teams" && !pathname?.includes("teams")
                  ? previousPath?.teamsTab
                  : path === "/assessments" &&
                    !pathname?.includes("assessments")
                  ? previousPath?.assessmentsTab
                  : path
              }
              style={disable ? { pointerEvents: "none" } : {}}
            >
              {/* <VSNTypography variant="body" sx={{ lineHeight: 0 }}>
              {" "}
              {img}
            </VSNTypography> */}
              <Box className={`${classes.iconWrapper}`}>{img}</Box>
              <Box className={`${classes.iconWrapper} active`}>
                {activeImg}{" "}
              </Box>
              <VSNTypography
                component="p"
                variant="body1"
                className={className}
              >
                {label}
              </VSNTypography>
            </NavLink>
          </Stack>
        );
      })}
    </Stack>
  );
};

const Header = () => {
  const { isLoggingOut, toggleSignOut, handleLogOut } = signOutContainer();
  const {
    handleLoginClick,
    handleRegisterClick,
    noLoginLink,
    userRoleData,
    isUser,
    isAdmin,
    pathname,
    state,
    navigateUser,
    vasanaTypeAssessment,
    previousPath,
    openDrawer,
    setOpenDrawer,
    toggleDrawer,
  } = headerContainer();
  const isLandingPage =
    [
      "/",
      "/for-teams",
      "/public-safety",
      "/who-we-are",
      "/contact-us",
    ].includes(pathname) && !isUser;
  const [scrolled, setScrolled] = useState(false);
  const classes = useStyles({ isLandingPage, scrolled, openDrawer });
  // const showText = ["/get-started", "/quick-note"]?.some((path) =>
  //   pathname?.includes(path)
  // );
  const isSmallScreen = useMediaQuery("(max-width:599px)");
  const isMdScreen = useMediaQuery("(max-width:992px)");

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > (isMdScreen ? 20 : 10);
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
      window.addEventListener("scroll", handleScroll);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  return (
    <header>
      <AppBar
        component="nav"
        className={classes.header}
        color="inherit"
        sx={{
          boxShadow:
            !openDrawer && isLandingPage && !scrolled
              ? "none"
              : "6px 7px 20px rgba(0, 0, 0, 0.08)",
        }}
        scrolled={String(scrolled)}
      >
        <Toolbar
          sx={{
            minHeight: 64,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            style={{ background: "none", padding: 0 }}
            disableRipple
            onClick={() => navigateUser()}
          >
            <VasanaLogo />
          </IconButton>

          {isUser &&
          location?.pathname !== "/admin" &&
          !["get-started", "quick-note"]?.some((path) =>
            pathname?.includes(path)
          ) &&
          !(
            pathname?.includes("assessment") &&
            !pathname?.includes("/assessments")
          ) &&
          vasanaTypeAssessment?.result ? (
            <>
              <Drawer
                open={openDrawer}
                onClose={toggleDrawer(false)}
                className={classes.drawerMain}
              >
                <Box
                  sx={{ width: "100%", maxWidth: 250 }}
                  role="presentation"
                  onClick={toggleDrawer(false)}
                >
                  <HeaderTabs
                    {...{
                      userRoleData,
                      isUser,
                      classes,
                      direction: "column",
                      vasanaTypeAssessment,
                      previousPath,
                      pathname,
                    }}
                  />
                </Box>
              </Drawer>
              <Stack direction="row" display={{ lmd: "flex", xs: "none" }}>
                <HeaderTabs
                  {...{
                    userRoleData,
                    isUser,
                    classes,
                    direction: "row",
                    vasanaTypeAssessment,
                    previousPath,
                    pathname,
                  }}
                />
              </Stack>
            </>
          ) : !isUser ? (
            <>
              <Drawer
                open={openDrawer}
                onClose={toggleDrawer(false)}
                className={classes.drawerMain}
              >
                <Box
                  sx={{ width: "100%", maxWidth: 250 }}
                  role="presentation"
                  onClick={toggleDrawer(false)}
                >
                  <HeaderTabs
                    {...{
                      userRoleData,
                      isUser,
                      classes,
                      direction: "column",
                      vasanaTypeAssessment,
                      previousPath,
                      pathname,
                    }}
                  />
                </Box>
                <Box role="presentation" onClick={toggleDrawer(false)}>
                  {isSmallScreen && (
                    <Stack
                      alignItems="center"
                      className={classes.loginButtons}
                      gap={1.5}
                    >
                      <VSNButton
                        variant="outlined"
                        handleClick={handleLoginClick}
                      >
                        Login
                      </VSNButton>
                    </Stack>
                  )}
                </Box>
              </Drawer>
              <Stack
                direction="row"
                display={{ lmd: "flex", xs: "none" }}
                sx={{
                  position: { xlg: "absolute" },
                  left: "50%",
                  transform: { xlg: "translateX(-50%)" },
                }}
              >
                <HeaderTabs
                  {...{
                    userRoleData,
                    isUser,
                    classes,
                    direction: "row",
                    vasanaTypeAssessment,
                    previousPath,
                    pathname,
                  }}
                />
              </Stack>
            </>
          ) : null}
          <Stack
            direction="row"
            alignItems="center"
            ml={{ lmd: 0, xs: "auto" }}
          >
            {/* {showText && (
            <VSNTypography className={classes.headerLine}>
              😊 Don’t stress out. There are no right or wrong answers.
            </VSNTypography>
          )} */}

            {isUser ? (
              <>
                {!pathname?.includes("/admin") && (
                  <>
                    {/* <VSNImage src={search} className={classes.search} /> */}

                    {userRoleData?.user_profile?.given_name &&
                      !state?.onBoarding && (
                        <Link to="/edit-profile" className={classes.profile}>
                          <VSNMembersAvatar
                            className={classes.profileText}
                            alt={userRoleData?.user_profile?.given_name}
                            src={userRoleData?.user_profile?.profile_picture}
                            vasanaType={vasanaTypeAssessment?.result}
                            title={
                              upperCase(
                                userRoleData?.user_profile?.given_name?.[0]
                              ) +
                              upperCase(
                                userRoleData?.user_profile?.family_name?.[0]
                              )
                            }
                            defaultColor="#ffffff"
                            defaultPrimaryBackgroundColor="#1F396D"
                            defaultSecondaryBackgroundColor="#1F396D"
                          />
                        </Link>
                      )}
                  </>
                )}

                <Box className={classes.logoutIcon}>
                  {/* <VSNToolTip title="Log out"> */}
                  <ExitIcon onClick={() => toggleSignOut()} />
                  {/* </VSNToolTip> */}
                </Box>
              </>
            ) : (
              !noLoginLink?.includes(pathname) && (
                <Stack
                  direction="row"
                  alignItems="center"
                  className={classes.loginButtons}
                >
                  {!isSmallScreen && (
                    <VSNButton
                      variant="outlined"
                      handleClick={handleLoginClick}
                    >
                      Login
                    </VSNButton>
                  )}
                  <VSNButton handleClick={handleRegisterClick}>
                    Get Started
                  </VSNButton>
                </Stack>
              )
            )}
          </Stack>
          {location?.pathname !== "/admin" &&
          !["get-started", "quick-note"]?.some((path) =>
            pathname?.includes(path)
          ) &&
          !(
            pathname?.includes("assessment") &&
            !pathname?.includes("/assessments")
          ) &&
          vasanaTypeAssessment?.result ? (
            <Box
              aria-hidden
              sx={{
                display: { lmd: "none", xs: "inline-block" },
                ml: 1,
              }}
            >
              <Button
                onClick={toggleDrawer(!openDrawer)}
                sx={{
                  minWidth: "unset",
                  color: (theme) => theme.palette.secondary.grey,
                  backgroundColor: "#EDEEF1",
                  "&:hover": {
                    backgroundColor: "#EDEEF1",
                  },
                }}
              >
                <HamburgarIcon />
              </Button>
            </Box>
          ) : !isUser ? (
            <Box
              aria-hidden
              sx={{
                display: { lmd: "none", xs: "inline-block" },
                ml: 1,
              }}
            >
              <Button
                onClick={toggleDrawer(!openDrawer)}
                sx={{
                  minWidth: "unset",
                  color: (theme) => theme.palette.secondary.grey,
                  backgroundColor: "#EDEEF1",
                  "&:hover": {
                    backgroundColor: "#EDEEF1",
                  },
                }}
              >
                <HamburgarIcon />
              </Button>
            </Box>
          ) : null}
        </Toolbar>
        <VSNConfirmBox
          isOpen={isLoggingOut}
          title="Are You Sure?"
          msg="Are you sure you want to log out?"
          handleOk={handleLogOut}
          handleCancel={toggleSignOut}
          okBtnName="Log out"
          cancelBtnName="Cancel"
          className={classes.conformModel}
        />
      </AppBar>
    </header>
  );
};

export default Header;
