import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ON_FORM_CHANGE, SET_USER_DATA } from "../redux/constants";
import { handleNavigation, navigateAfterLogin } from "../shared/CustomFunc";
import { ApiContainer } from "../utils/api";
import ApiCalls from "../utils/apiCall";
import {
  adminOTPPath,
  userOTPPath,
  assessmentAssessableRoles,
  endPoints,
  assessmentIds,
  assessmentTypes,
  assessmentNames,
} from "../utils/constant";

export const otpContainer = () => {
  const { signInCall } = ApiCalls();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const endPoint = pathname === adminOTPPath ? "admin/signin" : "auth/signin";
  const { api } = ApiContainer();
  const navigate = useNavigate();
  const localSecond = localStorage.getItem("otp-second");
  const [seconds, setSeconds] = useState(localSecond || 60);
  const [code, setCode] = useState("");
  const handleChange = (code) => setCode(code);
  const user = localStorage.getItem("user-name");
  const [userSession, setUserSession] = useState(
    JSON.parse(localStorage.getItem("user-session"))
  );
  const userRoleData = useSelector((state) => state.app.userData);
  const { form } = useSelector((state) => state);
  const loaders = useSelector((state) => {
    return {
      OTPLoader: state.api?.loader?.getRoleInfo,
      reportLoader: state.api?.loader?.reportGenerating,
      resendOTPLoader: state.api?.loader?.resendOTP,
    };
  });
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    localStorage.setItem("user-session", JSON.stringify(userSession));
  }, [userSession]);

  const loginUser = async () => {
    const data = await api({
      method: "POST",
      endPoint: endPoints?.confirmOTP,
      data: {
        user_name: user,
        session: userSession,
        code,
      },
      showToastMessage: true,
      needLoader: true,
      parent: "getRoleInfo",
      urlencoded: false,
    });
    if (data?.status) {
      const token = data?.data?.access_token;
      localStorage?.setItem("token", token);
      setIsDisable(true);
      dispatch({ type: SET_USER_DATA, payload: data?.data });
      dispatch({
        type: ON_FORM_CHANGE,
        payload: {
          formData: {
            ["userInfo"]: data?.data?.user_profile,
          },
        },
      });
      localStorage.setItem(
        "isAdmin",
        data?.data?.user_profile?.role === "platform_admin"
      );
    } else if (data.statusCode === 409) {
      setUserSession(data?.data?.session);
    } else if (data.statusCode === 400) {
      setSeconds(0);
    }
  };

  useEffect(() => {
    dispatch({
      type: ON_FORM_CHANGE,
      payload: {
        formData: {
          ...form.formData,
          userInfo: userRoleData?.user_profile,
        },
      },
    });
  }, [userRoleData]);

  useEffect(() => {
    if (code?.length === 4) {
      loginUser();
    }
  }, [code]);

  useEffect(() => {
    if (userRoleData && Object.keys(userRoleData).length) {
      localStorage?.setItem("isAuth", true);
      if (
        pathname === userOTPPath &&
        assessmentAssessableRoles?.includes(userRoleData?.role)
      ) {
        handleNavigation(navigate, userRoleData);
      } else if (pathname === adminOTPPath) {
        navigate("/admin");
      }
    }
  }, [userRoleData]);

  useEffect(() => {
    let interval;
    if (user) {
      interval = setInterval(() => {
        if (seconds > 0) {
          localStorage.setItem("otp-second", seconds);
          setSeconds(seconds - 1);
        }
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [JSON.stringify(user), seconds]);

  const handleResend = async () => {
    const data = await signInCall({
      userData: {
        user_name: user,
        ...(pathname !== adminOTPPath && { is_signup: false }),
      },
      showToastMessage: true,
      setUserSession,
      endPoint,
      needLoader: true,
      parent: "resendOTP",
    });
    setCode("");
    setSeconds(60);
    localStorage.setItem("otp-second", 60);
  };
  useEffect(() => {
    const token = localStorage?.getItem("token");
    if (token && userRoleData?.profile_updated) {
      localStorage.setItem("currentAssessmentId", assessmentIds?.vasanaType);
      navigate(`/get-started/${assessmentNames[assessmentIds?.vasanaType]}`);
    }
    const second = localSecond;
    if (second === undefined) {
      localStorage.setItem("otp-second", seconds);
    }
    // if (!user) {
    //   if (token) {
    //     localStorage.setItem("currentAssessmentId", assessmentIds?.vasanaType);
    //     localStorage.setItem(
    //       "currentAssessmentType",
    //       assessmentTypes?.vasanaType
    //     );
    //     navigate("/get-started");
    //   } else {
    //     navigate("/");
    //   }
    // }
    setSeconds(seconds === 1 ? 0 : seconds);
  }, []);

  const handleNext = (e) => {
    e.preventDefault();
    loginUser({});
  };
  return {
    handleNext,
    seconds: seconds?.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    }),
    handleResend,
    code,
    handleChange,
    loaders,
    isDisable,
  };
};
