import { useMediaQuery } from "@mui/material";
import AssessmentResult from "../presentation/adminDashboard/AssessmentResult";
import VSNImage from "../shared/VSNImage";
import { orgLinkSortBy, sortBy } from "./filters.description";

export const assessmentHeaders = [
  { label: "Member Name", name: "member_name" },
  { label: "Organization", name: "organization_name" },
  { label: "Registered Date", name: "registered_date", type: "date" },
  { label: "Assessment Completed", name: "completed_date", type: "date" },
  { label: "Vasana Type", name: "result", type: "VasanaTypeIcon" },
  {
    label: (
      <VSNImage
        src={`${process.env.REACT_APP_MEDIA_URL}sa/black-logo.svg`}
        alt="Sa"
      />
    ),
    name: "sa_percentage",
    type: "percentage",
  },
  {
    label: (
      <VSNImage
        src={`${process.env.REACT_APP_MEDIA_URL}re/black-logo.svg`}
        alt="Re"
      />
    ),
    name: "re_percentage",
    type: "percentage",
  },
  {
    label: (
      <VSNImage
        src={`${process.env.REACT_APP_MEDIA_URL}ga/black-logo.svg`}
        alt="Ga"
      />
    ),
    name: "ga_percentage",
    type: "percentage",
  },
  {
    label: (
      <VSNImage
        src={`${process.env.REACT_APP_MEDIA_URL}ma/black-logo.svg`}
        alt="Ma"
      />
    ),
    name: "ma_percentage",
    type: "percentage",
  },
];
export const organizationsHeaders = [
  { type: "collapsible" },
  { label: "Organization", name: "original_id", type: "organization" },
  {
    label: "Organization Type",
    name: "type",
    type: "type",
  },
  { label: "Created Date", name: "created_date", type: "date" },
  { label: "Members", name: "active_users" },
  { label: "Edit", type: "actions", action: ["edit"] },
  { label: "Actions", type: "actions", action: ["resendEmail"] },
];

export const usersHeaders = [
  { label: "Member Name", name: "first_name", type: "member_name" },
  { label: "Organization", name: "organization_name" },
  { label: "Email", name: "email" },
  { label: "Mobile Number", name: "phone_number" },
  { label: "Vasana Type", name: "primary_marker", type: "VasanaTypeIcon" },
  {
    label: "Assessment Completed",
    name: "assessment_completed_at",
    type: "date",
  },
  {
    label: "Last Visited",
    name: "last_signin_at",
    type: "time",
  },
  { label: "Actions", type: "actions", action: ["download", "delete"] },
];

export const locationHeaders = [
  { label: "Location", name: "location_name", type: "location_name" },
  { label: "Organization", name: "organization_name" },
  { label: "Created Date", name: "created_date", type: "date" },
  { label: "Members", name: "active_users" },
  { label: "Edit", type: "actions", action: ["edit"] },
  // { label: "Actions", type: "actions", action: ["resendEmail"] },
];

export const departmentHeaders = [
  { label: "Department", name: "department_name", type: "department_name" },
  { label: "Location", name: "location_name", type: "location_name" },
  { label: "Organization", name: "organization_name" },
  { label: "Created Date", name: "created_date", type: "date" },
  { label: "Members", name: "active_users" },
  { label: "Edit", type: "actions", action: ["edit"] },
  // { label: "Actions", type: "actions", action: ["resendEmail"] },
];

export const rawsPerPageOption = [
  5,
  10,
  25,
  75,
  100,
  { label: "All", value: -1 },
];
export const tabs = [
  {
    tabLabel: "Organizations",
    value: "organizations",
    children: (
      <AssessmentResult
        columns={[...organizationsHeaders]}
        sortBy={{ orgLinkSortBy }}
        getFrom="organizations"
        showAssessmentSort
        showOrgTypeFilter
        showOrgFilter
        showResetFilter
        showDownload
        showSearchInput
        hasExpandableRow
        expandedColumns={[
          { label: "Name of Assessments", name: "name" },
          { label: "Action", type: "actions", action: ["enabled"] },
        ]}
        className="adminOrganizations"
      />
    ),
  },
  {
    tabLabel: "Location",
    value: "locations",
    children: (
      <AssessmentResult
        columns={[...locationHeaders]}
        sortBy={{ orgLinkSortBy }}
        getFrom="locations"
        showAssessmentSort
        // showDownload
        showResetFilter
        showOrgFilter
        showSearchInput
      />
    ),
  },
  {
    tabLabel: "Department",
    value: "departments",
    children: (
      <AssessmentResult
        columns={[...departmentHeaders]}
        sortBy={{ orgLinkSortBy }}
        getFrom="departments"
        showAssessmentSort
        // showDownload
        showLocationFilter
        showResetFilter
        showOrgFilter
        showSearchInput
      />
    ),
  },
  {
    tabLabel: "Members",
    value: "users",
    children: (
      <AssessmentResult
        columns={[...usersHeaders]}
        getFrom="users"
        showAssessmentSort
        showResetFilter
        showOrgFilter
        showLastVisitedFilter
        showSearchInput
      />
    ),
  },
  {
    tabLabel: "Assessments",
    value: "assessmentReports",
    children: (
      <AssessmentResult
        columns={[...assessmentHeaders]}
        sortBy={{ sortBy }}
        getFrom="reports"
        showAssessmentSort
        showDownload
        showResetFilter
        showOrgFilter
        showSearchInput
      />
    ),
  },
];

export const summaryCardHeaders = ({ assessmentSummary, classes }) => {
  const isLargeScreen = useMediaQuery("(min-width:475px)");

  return {
    organization: {
      title: <>Organizations {isLargeScreen && <br />} Onboarded</>,
      tab: "organizations",
      value: `${assessmentSummary?.organizations_onboarded}`,
      className: `${classes.assesmentCard}`,
    },
    location: {
      title: <>Location</>,
      tab: "locations",
      value: `${assessmentSummary?.locations_onboarded}`,
      className: `${classes.assesmentCard}`,
    },
    department: {
      title: <>Department</>,
      tab: "departments",
      value: `${assessmentSummary?.departments_onboarded}`,
      className: `${classes.assesmentCard}`,
    },
    users: {
      title: "Members",
      tab: "users",
      value: `${assessmentSummary?.no_of_active_user}`,
      className: `${classes.assesmentCard}`,
    },
    assessment_completed: {
      title: <>Assessments {isLargeScreen && <br />} Completed</>,
      tab: "assessmentReports",
      value: `${assessmentSummary?.assessment_completed}`,
      className: `${classes.assesmentCard}`,
    },
  };
};
