import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { SET_API_DATA } from "../redux/constants";
import { nameValidation } from "../shared/validation";

let socket;
const humaChatBotContainer = () => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const messages = useSelector((state) => state?.api?.allMessages);
  const open = useSelector((state) => state?.api?.humaChatBox);
  const [allMessages, setAllMessages] = useState(
    messages || {
      data: [],
      page: { current_page: 1, limit: 10 },
    }
  );
  const [message, setMessage] = useState("");
  const [typing, setTyping] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [anchorEl, setAnchorEl] = useState(false);
  const ref = useRef();

  const accessToken = localStorage.getItem("token");
  const onConnect = () => {
    console.log("Connected ;)");
  };

  const onDisconnect = () => {
    console.log("Disconnected :(");
  };

  const onAnswer = (message) => {
    setAllMessages((prevAllMessages) => {
      const temp = prevAllMessages?.data?.find(
        (msg) => msg?.ai?.greetingMessage
      );
      if (message?.greeting_message && temp) {
        return { ...prevAllMessages };
      }
      const clonedAllMessages = [...prevAllMessages?.data];
      clonedAllMessages?.unshift({
        ai: {
          phrase: message?.answer || "",
          time: Math.floor(Date.now() / 1000),
          greetingMessage: message?.greeting_message,
        },
      });
      dispatch({
        type: SET_API_DATA,
        payload: {
          allMessages: { ...prevAllMessages, data: clonedAllMessages },
        },
      });
      return { ...prevAllMessages, data: clonedAllMessages };
    });
    setTyping(false);
  };

  const handleResize = (e) => {
    setPosition({ x: 0, y: !position.y ? 0 : ref?.current?.offsetTop });
  };

  useEffect(() => {
    if (!typing) {
      inputRef?.current?.focus();
    }
  }, [typing]);

  useEffect(() => {
    console.log("Connecting...");
    socket = io.connect(process.env.REACT_APP_SOCKET_URL, {
      reconnection: false,
      extraHeaders: {
        Authorization: accessToken,
      },
    });
    socket.on("connect", onConnect);
    socket.on("answer", onAnswer);
    socket.on("disconnect", onDisconnect);
    // getMessages(allMessages?.page);
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onMessageChange = (e) => {
    const { value } = e?.target;
    if (value === "" || nameValidation(value)) {
      setMessage(value);
    }
  };

  const onSendMessage = (e) => {
    e.preventDefault();
    if (message?.trim()) {
      socket?.emit("ask_question", {
        question: message,
      });
      const clonedAllMessages = [...allMessages?.data];
      clonedAllMessages?.unshift({
        human: {
          phrase: message || "",
          time: Math.floor(Date.now() / 1000),
        },
      });
      setAllMessages({ ...allMessages, data: clonedAllMessages });
      dispatch({
        type: SET_API_DATA,
        payload: {
          allMessages: { ...allMessages, data: clonedAllMessages },
        },
      });
      setMessage("");
      setTyping(true);
    }
  };

  const toggleChatBox = (flag) => {
    dispatch({
      type: SET_API_DATA,
      payload: {
        humaChatBox: flag,
      },
    });
  };

  const handleDragStart = (e) => {
    // Check if the initial event originated from the desired target element
    const isTargetElement = e?.currentTarget?.id === "chatBot"; // replace with your target element's class

    if (!isTargetElement) {
      return; // Do not initiate dragging if not the target element
    }

    const handleDragMove = (e) => {
      e.preventDefault();
      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;
      if (window.innerHeight - 108 >= clientY && clientY > 64) {
        setPosition({ x: clientX, y: clientY });
      }
    };

    const handleDragEnd = () => {
      document.removeEventListener("mousemove", handleDragMove);
      document.removeEventListener("touchmove", handleDragMove, {
        passive: false,
      });

      document.removeEventListener("mouseup", handleDragEnd);
      document.removeEventListener("touchend", handleDragEnd);

      // Re-add event listeners for starting a new drag
      document.addEventListener("mousedown", handleDragStart);
      document.addEventListener("touchstart", handleDragStart, {
        passive: false,
      });
    };

    // Add event listeners for dragging
    document.addEventListener("mousemove", handleDragMove);
    document.addEventListener("touchmove", handleDragMove, { passive: false });

    document.addEventListener("mouseup", handleDragEnd);
    document.addEventListener("touchend", handleDragEnd);

    // Remove event listeners for starting a new drag
    document.removeEventListener("mousedown", handleDragStart);
    document.removeEventListener("touchstart", handleDragStart, {
      passive: false,
    });
  };

  document.addEventListener("mousedown", handleDragStart);
  document.addEventListener("touchstart", handleDragStart, { passive: false });

  const handleClick = () => {
    setAnchorEl(!anchorEl);
  };

  return {
    message,
    messages,
    typing,
    onMessageChange,
    onSendMessage,
    inputRef,
    open,
    toggleChatBox,
    anchorEl,
    position,
    ref,
    handleDragStart,
    handleClick,
  };
};
export default humaChatBotContainer;
