import { useMediaQuery } from "@mui/material";
import Groups2Icon from "@mui/icons-material/Groups2";
import AssessmentResult from "../presentation/orgAdminDashboard/OrgAssessmentReport";
import orangeFlower from "../assets/Teams/svg/orange-flower.svg";
import greenRainbow from "../assets/Teams/png/greenRainbow.png";
import {
  location_admin_name,
  organization_admin as organization_admin_name,
  department_admin as department_admin_name,
} from "../utils/constant";
import { ERROR_MESSAGE } from "./errorMessage.description";

export const organization_admin = [
  {
    label: "First Name",
    name: "first_name",
    type: "first_name",
  },
  {
    label: "Last Name",
    name: "last_name",
    type: "last_name",
  },
  {
    label: "Email",
    name: "email",
    type: "email",
  },

  // {
  //   label: "Phone Number",
  //   name: "phone_number",
  //   type: "phone_number",
  // },

  { label: "Status", type: "changeStatus", name: "status" },

  // {
  //   label: "Action",
  //   name: "actions",
  //   type: "actions",
  //   permission: organization_admin,
  //   action: ["changeRole"],
  // },
];

export const location_admin = [
  {
    label: "First Name",
    name: "first_name",
    type: "first_name",
  },
  {
    label: "Last Name",
    name: "last_name",
    type: "last_name",
  },
  {
    label: "Email",
    name: "email",
    type: "email",
  },
  // {
  //   label: "Phone Number",
  //   name: "phone_number",
  //   type: "phone_number",
  // },
  { label: "Status", type: "changeStatus", name: "status" },
  { label: "Role", name: "role", type: "locationRole" },

  // {
  //   label: "Action",
  //   name: "actions",
  //   type: "actions",
  //   permission: "location_admin",
  //   action: ["changeRole"],
  // },
];

export const department_admin = [
  {
    label: "First Name",
    name: "first_name",
    type: "first_name",
  },
  {
    label: "Last Name",
    name: "last_name",
    type: "last_name",
  },
  {
    label: "Email",
    name: "email",
    type: "email",
  },

  // {
  //   label: "Phone Number",
  //   name: "phone_number",
  //   type: "phone_number",
  // },
  { label: "Status", type: "changeStatus", name: "status" },
  // {
  //   label: "Action",
  //   name: "actions",
  //   type: "actions",
  //   permission: "department_admin",
  //   action: ["changeRole"],
  // },
];

export const organization_members = [
  {
    label: "Name",
    name: "first_name",
    type: "member_name_icon",
  },
  {
    label: "Email",
    name: "email",
    type: "email",
  },
  {
    label: "Location",
    name: "location_name",
  },
  // {
  //   label: "Department",
  //   name: "department_name",
  // },
  {
    label: "Department",
    name: "Department Type",
    type: "changeDepartment",
    showRole: [...location_admin_name, organization_admin_name],
  },
  {
    label: "Vasana Type",
    name: "primary_marker",
    type: "VasanaTypePercentage",
  },
  {
    label: "Reports",
    name: "user_assessments",
    type: "viewAssessment",
    showRole: [
      ...location_admin_name,
      organization_admin_name,
      department_admin_name,
    ],
    permissionName: "user_assessments",
    permissionToFind: "view",
  },

  // { label: "Registered Date", name: "created_at", type: "date" },
  // { label: "Members Status", name: "member_status", type: "status" },
  // { label: "Teams", type: "team_name", name: "teams_name" },
  // // { label: "Role", name: "role", type: "role", isMember: true },

  // {
  //   label: "Assessment Completed",
  //   name: "assessment_completed_at",
  //   type: "date",
  // },
  {
    label: "Action",
    name: "actions",
    type: "actions",
    action: ["showList", "deleteUser"],
  },
];

export const organization_location = [
  {
    label: "Name",
    name: "location_name",
    type: "location_name",
  },
  {
    label: "Address",
    name: "address",
    type: "address",
  },
  {
    label: "#Departments",
    name: "departments_count",
  },

  {
    label: "#Members",
    name: "active_users",
    type: "members",
  },

  { label: "Location Admin", type: "showAdmin", name: "showAdmin" },
  // { label: "Head Clinician", type: "head_clinician", name: "head_clinician" },
  // { label: "Clinician", type: "clinician", name: "clinician" },

  {
    label: "Action",
    name: "actions",
    type: "actions",
    permission: "location",
    action: ["edit", "delete"],
  },
];
export const organization_department = [
  {
    label: "Name",
    name: "department_name",
    type: "location_name",
  },
  {
    label: "Address",
    name: "address",
    type: "address",
  },
  {
    label: "Location",
    name: "location_name",
  },

  {
    label: "#Members",
    name: "active_users",
  },

  {
    label: "Department Admin",
    type: "showAdmin",
    name: "showAdmin",
  },

  // { label: "Department Admin", type: "showAdmin", name: "showAdmin" },
  // { label: "Department Type", type: "showAdmin", name: "department_type" },

  { label: "Last OTB Date", name: "last_date" },
  { label: "Event Schedule", type: "createEventSchedule", name: "" },
  { label: "OTB-Report", type: "viewReport", name: "report" },

  {
    label: "Action",
    name: "actions",
    type: "actions",
    permission: "location",
    action: ["copyLink", "edit", "delete"],
  },
];

export const organization_teams = [
  {
    label: "Team Name",
    name: "team_name",
    type: "teams_team_name",
    width: "65%",
  },
  {
    label: "Team Members",
    name: "participants",
    type: "participants",
    width: "20%",
  },
  {
    label: "Action",
    type: "actions",
    action: ["edit", "manageMembers", "delete"],
    width: "15%",
  },
];

export const organization_assessments = [
  {
    label: "Name of Assessments",
    name: "name",
    type: "text",
  },
  {
    label: "Required",
    type: "actions",
    action: ["enabled"],
    width: "15%",
  },
];

export const rawsPerPageOption = [
  5,
  10,
  25,
  75,
  100,
  { label: "All", value: -1 },
];

const allTabs = [
  {
    tabLabel: "Organization",
    value: organization_admin_name,
    name: organization_admin_name,
    editButtonName: "Edit Organization",
    editPermission: "organization",
    tableViewPermission: organization_admin_name,
    showDetail: "organization",
    tableName: "ORGANIZATION ADMINS",
    showCard: true,
    children: (
      <AssessmentResult
        columns={[...organization_admin]}
        getFrom="admins"
        showSearchInput
        inviteAdminName="Invite Organization Admin"
        expandedColumns={[
          { label: "Name of Assessments", name: "name" },
          { label: "Action", type: "actions", action: ["enabled"] },
        ]}
      />
    ),
  },
  {
    tabLabel: "Location",
    value: "locations",
    name: "location",
    tableViewPermission: "location",
    tableName: "LOCATIONS",
    children: (
      <AssessmentResult
        columns={[...organization_location]}
        getFrom="locations"
        showSearchInput
        createLocation
        expandedColumns={[
          { label: "Name of Assessments", name: "name" },
          { label: "Action", type: "actions", action: ["enabled"] },
        ]}
      />
    ),
  },
  {
    tabLabel: "Location",
    value: "location_admin",
    name: "location_admin",
    editButtonName: "Edit Location",
    editPermission: "location",
    tableViewPermission: "location_admin",
    tableName: "LOCATION ADMINS",
    showCard: true,
    showDetail: "location",
    children: (
      <AssessmentResult
        columns={[...location_admin]}
        getFrom="locations"
        showSearchInput
        inviteAdminName="Invite Location Admin"
        expandedColumns={[
          { label: "Name of Assessments", name: "name" },
          { label: "Action", type: "actions", action: ["enabled"] },
        ]}
      />
    ),
  },
  {
    tabLabel: "Department",
    value: "departments",
    name: "department",
    tableViewPermission: "department",
    tableName: "DEPARTMENTS",
    children: (
      <AssessmentResult
        columns={[...organization_department]}
        // sortBy={{ sortyByStatus, sortByRole, sortByVasanaType }}
        getFrom="members"
        // showResetFilter
        // showDeleteButton
        // showAssessmentSort
        showSearchInput
        locationFilter
        createDepartment
        showDepartmentTypeFilter
        expandedColumns={[
          { label: "Name of Assessments", name: "name" },
          { label: "Action", type: "actions", action: ["enabled"] },
        ]}
      />
    ),
  },
  {
    tabLabel: "Department",
    value: department_admin_name,
    name: department_admin_name,
    editButtonName: "Edit Department",
    editPermission: "department",
    tableViewPermission: department_admin_name,
    tableName: "DEPARTMENT ADMINS",
    showDetail: "department",
    showCard: true,
    children: (
      <AssessmentResult
        columns={[...department_admin]}
        // sortBy={{ sortyByStatus, sortByRole, sortByVasanaType }}
        getFrom="members"
        // showResetFilter
        // showDeleteButton
        // showAssessmentSort
        showSearchInput
        inviteAdminName="Invite Department Admin"
        expandedColumns={[
          { label: "Name of Assessments", name: "name" },
          { label: "Action", type: "actions", action: ["enabled"] },
        ]}
      />
    ),
  },
  {
    tabLabel: "Members",
    value: "members",
    name: "member",
    tableViewPermission: "member",
    tableName: "MEMBERS",
    children: (
      <AssessmentResult
        columns={[...organization_members]}
        // sortBy={{ sortyByStatus, sortByRole, sortByVasanaType }}
        getFrom="members"
        showResetFilter
        locationFilter
        departmentFilter
        // showDeleteButton
        // showAssessmentSort
        showSearchInput
        inviteMembersDepartment
        // hasExpandableRow
        expandedColumns={[
          { label: "Name of Assessments", name: "name" },
          { label: "Action", type: "actions", action: ["enabled"] },
        ]}
      />
    ),
  },
  {
    tabLabel: "Teams",
    value: "teams",
    name: "team",
    tableViewPermission: "team",
    tableName: "TEAMS",
    children: (
      <AssessmentResult
        columns={[...organization_teams]}
        getFrom="teams"
        minWidth={900}
        // showDeleteButton
        showResetFilter
        showSearchInput
      />
    ),
  },
  {
    tabLabel: "Assessment Preferences",
    value: "assessments",
    name: "assessments",
    tableName: "ASSESSMENT PREFERENCES",
    tableViewPermission: "assessments",
    children: (
      <AssessmentResult
        columns={[...organization_assessments]}
        getFrom="assessments"
        minWidth={900}
        hidePagination
        tableWrapperClass="expandTableUser"
      />
    ),
  },
];

export const FilterTab = (
  role = organization_admin_name,
  organization_type_id = 1,
  permissions
) => {
  return allTabs.filter(({ name, tableViewPermission }) => {
    if (
      (role === "head_clinician" || role === "clinician") &&
      name === "location"
    ) {
      return false;
    }
    if (
      organization_type_id === 1 &&
      name === "team" &&
      role === organization_admin_name
    ) {
      return false;
    }
    if (name === role && permissions) {
      return true;
    }
    if (
      (role === "head_clinician" || role === "clinician") &&
      name === "location_admin"
    ) {
      return true;
    }
    if (role?.startsWith(name)) {
      return false;
    }
    if (
      name?.endsWith("_admin") &&
      permissions?.[tableViewPermission]?.includes(name)
    ) {
      return false;
    }
    if (
      !name?.endsWith("_admin") &&
      permissions?.[tableViewPermission]?.includes("view")
    ) {
      return true;
    }
    return false;
  });
};

export const summaryCardHeaders = ({ organizationSummary, classes }) => {
  const isLargeScreen = useMediaQuery("(min-width:475px)");

  return {
    location: {
      name: "location",
      title: "Location",
      className: `${classes.assesmentCard}`,
      value: organizationSummary?.locations_onboarded || 0,
      tab: "location",
      shape: orangeFlower,
    },
    department: {
      name: "department",
      title: "Department",
      className: `${classes.assesmentCard}`,
      value: organizationSummary?.departments_onboarded || 0,
      tab: "department",
      shape: orangeFlower,
    },
    member: {
      name: "member",
      title: "Members",
      className: `${classes.assesmentCard}`,
      value: organizationSummary?.total_members || 0,
      tab: "members",
      shape: orangeFlower,
    },
    users: {
      name: "team",
      title: "Teams",
      className: `${classes.assesmentCard}`,
      value: organizationSummary?.no_of_teams || 0,
      tab: "teams",
      shape: greenRainbow,
    },
    assessment_completed: {
      title: <>Assessments{isLargeScreen && <br />} Completed</>,
      className: `${classes.assesmentCard}`,
      value: organizationSummary?.assessment_completed || 0,
      member_status: true,
      shape: greenRainbow,
    },
  };
};

export const teamsButtonContainer = () => {
  return [
    {
      value: "Create a Team",
      icon: <Groups2Icon />,
      key: "createTeam",
    },
  ];
};

export const vasanaTypeColor = {
  Sa: {
    color: "#8304A3",
  },
  Re: {
    color: "#3498DB",
  },
  Ga: {
    color: "#FFB142",
  },
  Ma: {
    color: "#56A06F",
  },
};

export const vasanaTypeBackgroundColor = {
  Sa: {
    color: "#F2E7F5",
  },
  Re: {
    color: "#E3F1F9",
  },
  Ga: {
    color: "#FFF7EC",
  },
  Ma: {
    color: "#F0F9F2",
  },
};

export const EditOrgAttribute = [
  {
    label: "ORGANIZATION DETAILS",
    value: [
      {
        control: "input",
        label: "Organization Name",
        type: "text",
        name: "name",
        value: "",
        isRequired: true,
        validateAs: "required",
        placeHolder: "Enter organization name",
        errorMessage: ERROR_MESSAGE?.organization_name,
        fullWidth: true,
        md: 6,
      },
      {
        control: "input",
        label: "Organization ID",
        type: "text",
        name: "original_id",
        value: "",
        isRequired: true,
        validateAs: "name",
        placeHolder: "Enter Address Line 1",
        errorMessage: ERROR_MESSAGE?.address_line_1,
        fullWidth: true,
        md: 6,
        readOnly: true,
      },
      {
        control: "input",
        label: "Address Line 1",
        type: "text",
        name: "address_line_1",
        value: "",
        isRequired: true,
        validateAs: "name",
        placeHolder: "Enter Address Line 1",
        errorMessage: ERROR_MESSAGE?.address_line_1,
        fullWidth: true,
        md: 6,
      },
      {
        control: "input",
        label: "Address Line 2",
        type: "text",
        name: "address_line_2",
        value: "",
        placeHolder: "Enter Address Line 2",
        errorMessage: ERROR_MESSAGE?.address_line_2,
        fullWidth: true,
        md: 6,
      },
      {
        control: "input",
        label: "City",
        type: "text",
        name: "city",
        value: "",
        isRequired: true,
        validateAs: "name",
        placeHolder: "Enter city",
        errorMessage: ERROR_MESSAGE?.city,
        fullWidth: true,
        md: 6,
      },
      {
        control: "input",
        label: "Zip Code",
        type: "number",
        name: "zip_code",
        value: "",
        isRequired: true,
        validateAs: "zipCode",
        placeHolder: "Enter zip code",
        errorMessage: ERROR_MESSAGE?.zip_code,
        fullWidth: true,
        md: 6,
        sx: {
          '& input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button':
            {
              WebkitAppearance: "none",
            },
        },
      },
    ],
  },
];
