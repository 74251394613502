import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { successStatus, unauthStatus } from "./constant";
import { showToast } from "./toastService";
import { LOADING_CHANGE, LOGOUT } from "../redux/constants";

export const ApiContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = () => {
    localStorage?.clear();
    dispatch({ type: LOGOUT });
    navigate("/login");
  };
  const api = ({
    method,
    endPoint,
    data,
    showToastMessage = false,
    toastMessage = "",
    responseType = "",
    needLoader = false,
    parent = "",
    notShowMsgOnCode = [],
    needFullResponse = false,
    header,
  }) =>
    new Promise((resolve) => {
      const accessToken = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(accessToken && { Authorization: accessToken }),
        ...header,
      };
      if (needLoader) {
        dispatch({ type: LOADING_CHANGE, payload: { [parent]: true } });
      }
      axios({
        method,
        url: `${process.env.REACT_APP_API_URL}/${endPoint}`,
        data,
        headers,
        responseType,
      })
        .then((response) => {
          if (
            !response?.data?.error &&
            showToastMessage &&
            successStatus?.includes(response?.status) &&
            !notShowMsgOnCode?.includes(response?.status)
          ) {
            showToast(
              !toastMessage ? response.data.message : toastMessage,
              "success"
            );
          } else if (response?.data?.error) {
            showToast(
              !toastMessage ? response.data.message : toastMessage,
              "error"
            );
          }

          if (needLoader) {
            dispatch({ type: LOADING_CHANGE, payload: { [parent]: false } });
          }
          return resolve({
            data: needFullResponse
              ? response.data
              : response.data.data || response.data,
            status: successStatus?.includes(response?.status),
            headers: response.headers,
          });
        })
        .catch((error) => {
          const { response, request } = error;
          if (error?.code === "ERR_NETWORK") {
            if (needLoader)
              dispatch({ type: LOADING_CHANGE, payload: { [parent]: false } });
            navigate("/error");
          }
          if (unauthStatus?.includes(response?.status)) {
            logout();
          }

          if (request && request?.responseType === "arraybuffer") {
            const strData = new TextDecoder().decode(
              new Uint8Array(response?.data)
            );

            data = JSON.parse(JSON.stringify(strData));
          }

          if (
            showToastMessage &&
            typeof response?.data?.message === "string" &&
            !notShowMsgOnCode?.includes(response?.data?.status_code)
          ) {
            showToast(
              response?.data?.error_code === 500
                ? "Server not responding"
                : response?.data?.message,
              "error"
            );
          }
          if (needLoader) {
            dispatch({ type: LOADING_CHANGE, payload: { [parent]: false } });
          }
          return resolve({
            status: false,
            error: response?.data?.message,
            error_code: response?.data?.status_code,
            statusCode: response?.status,
            data: response?.data,
          });
        });
    });
  return {
    api,
  };
};
