import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { LOGOUT, ON_FLAG_CHANGE } from "../redux/constants";

export const signOutContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRoleData = useSelector((state) => state.app?.userData);
  const userInfo = userRoleData.user_profile;

  const flags = useSelector((state) => state?.app?.flags);

  const toggleSignOut = () => {
    dispatch({
      type: ON_FLAG_CHANGE,
      payload: { isLoggingOut: !flags?.isLoggingOut },
    });
  };

  useEffect(() => {
    if (flags?.logOut) {
      toggleSignOut();

      if (flags?.logOut) {
        // if (token) {
        //   Auth.signOut();
        // } else {
        localStorage?.clear();
        if (userInfo?.role === "platform_admin") {
          navigate("/admin/login");
        } else {
          navigate("/");
        }
        // }
      }
      dispatch({ type: LOGOUT });
    }
  }, [flags?.logOut]);

  const handleLogOut = () => {
    dispatch({
      type: ON_FLAG_CHANGE,
      payload: { logOut: true },
    });
  };
  return { isLoggingOut: flags?.isLoggingOut, toggleSignOut, handleLogOut };
};

export default signOutContainer;
