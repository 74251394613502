import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  headerLinks,
  routesToShowLinks,
} from "../description/header.description";
import { assessmentIds, assessmentNames } from "../utils/constant";
import { equal, keys, length } from "../utils/javascript";
import { getAssessmentState } from "../shared/CustomFunc";

const headerContainer = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const noLoginLink = ["/admin", "/signup", "/otp", "/login"];
  const navigator = useNavigate();
  const location = useLocation();
  const userRoleData = useSelector((state) => state.app?.userData);
  const userInfo = userRoleData.user_profile;
  const vasanaTypeAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: assessmentIds?.vasanaType,
  });

  const isUser = localStorage?.getItem("token");
  const isAdmin =
    localStorage?.getItem("token") && location?.pathname !== "/admin";
  const { pathname, state } = location;

  const [previousPath, setPreviousPath] = useState({
    teamsTab: "teams",
    assessmentsTab: "assessments",
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  useEffect(() => {
    if (routesToShowLinks?.includes(pathname)) setShowLinks(true);
    else setShowLinks(false);
    if (pathname?.includes("teams")) {
      setPreviousPath({ ...previousPath, teamsTab: pathname });
    } else if (pathname?.includes("assessments")) {
      setPreviousPath({ ...previousPath, assessmentsTab: pathname });
    }
  }, [pathname]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setUserLoggedIn(true);
    } else {
      setPreviousPath({
        teamsTab: "teams",
        assessmentsTab: "assessments",
      });
    }
  }, [localStorage.getItem("token")]);

  const handleRegisterClick = () => {
    navigator("/signup");
  };

  const handleLoginClick = () => {
    navigator("/login");
  };
  const navigateUser = () => {
    let nav = "/";
    if (length(keys(userRoleData))) {
      if (userRoleData?.is_demo_user) {
        nav = "/";
      } else if (
        equal(userInfo?.role, "user") ||
        equal(userInfo?.role, "platform_admin")
      ) {
        if (!vasanaTypeAssessment?.result) {
          nav = `/get-started/${assessmentNames[assessmentIds?.vasanaType]}`;
        }
      }
    }

    if (pathname !== nav) {
      navigator(nav);
    }
  };

  return {
    userLoggedIn,
    headerLinks,
    handleRegisterClick,
    handleLoginClick,
    showLinks,
    noLoginLink,
    userRoleData,
    isUser,
    isAdmin,
    pathname,
    state,
    navigateUser,
    vasanaTypeAssessment,
    previousPath,
    openDrawer,
    setOpenDrawer,
    toggleDrawer,
  };
};
export default headerContainer;
