import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SET_API_DATA } from "../redux/constants";
import { ApiContainer } from "../utils/api";
import { imagesToLoad } from "../description/assessment.description";
import { keys, length } from "../utils/javascript";
import { showToast } from "../utils/toastService";
import { assessmentIds } from "../utils/constant";
import { getAssessmentState, getSlug } from "../shared/CustomFunc";

export const vasanaTypeResultContainer = () => {
  const { api } = ApiContainer();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiData = useSelector((state) => state?.api);
  const markerInfo = useSelector((state) => state?.api?.markerInfo);
  const userRoleData = useSelector((state) => state.app.userData);
  const subId = useSelector((state) => state.api?.assessmentData?.subId);
  const downloadReportData = useSelector(
    (state) => state.api?.downloadVasanaTypeReport
  );
  const generatingReport = useSelector(
    (state) => state.api?.generatingReport
  ) || { isGenerating: false, reportSuccess: true, download: false };
  const [resultInfo, setResultInfo] = useState({});
  const [count, setCount] = useState(0);
  const interval = useRef(null);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
  // const [generatingReport, setGeneratingReport] = useState({
  // isGenerating: false,
  // reportSuccess: true,
  // download: false,
  // });
  const [readMore, setReadMore] = useState(false);
  const [activeCards, setActiveCards] = useState({ start: -1, end: 3 });
  const [reportLoaders, setReportLoaders] = useState({});
  localStorage.setItem("currentAssessmentId", assessmentIds?.vasanaType);
  const vasanaTypeAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: assessmentIds?.vasanaType,
  });
  const introExtroAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: assessmentIds?.introExtro,
  });
  const isiAssessment = getAssessmentState({
    assessments: userRoleData?.assessments,
    id: assessmentIds?.isi,
  });
  const result = useSelector(
    (state) => state?.api?.assessmentResult?.[assessmentIds?.vasanaType]
  );
  const allAssessmentResults = useSelector(
    (state) => state?.api?.assessmentResult
  );
  const assessmentsSection = useSelector(
    (state) => state?.api?.assessmentsSection
  );

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (result && markerInfo) {
      setResultInfo({ ...markerInfo?.[result?.result?.toLowerCase()] });
    }
  }, [result, markerInfo]);

  useEffect(() => {
    imagesToLoad.forEach((src) => {
      const imageToLoad = new Image();
      imageToLoad.src = `${process.env.REACT_APP_MEDIA_URL}${src}`;
    });
  }, []);

  const getAssessmentDashboardData = async () => {
    const response = await api({
      method: "GET",
      endPoint: "assessment/in-dashboard",
      showToastMessage: false,
      urlencoded: false,
    });
    if (response?.status) {
      dispatch({
        type: SET_API_DATA,
        payload: {
          assessmentsSection:
            response?.data?.message === "No Data Found"
              ? []
              : response?.data || [],
        },
      });
    }
  };

  useEffect(() => {
    getAssessmentDashboardData();
  }, []);

  const getData = async (endPoint, key) => {
    if (!apiData?.[key]?.[assessmentIds?.vasanaType]) {
      const response = await api({
        method: "GET",
        endPoint,
        showToastMessage: false,
        urlencoded: false,
        needLoader: true,
        parent: key,
      });

      if (response?.status) {
        dispatch({
          type: SET_API_DATA,
          payload: {
            [key]: {
              ...allAssessmentResults,
              [assessmentIds?.vasanaType]: response?.data,
            },
          },
        });
      }
    }
  };

  // const generateReport = async () => {
  //   setGeneratingReport((prev) => ({
  //     ...prev,
  //     isGenerating: true,
  //   }));
  //   if (localStorage?.getItem("token") && !downloadReportData) {
  //     const reportData = await api({
  //       method: "GET",
  //       endPoint: `assessment/${assessmentIds?.vasanaType}/download-report?submission_id=${vasanaTypeAssessment?.submission_id}`,
  //     });
  //     setGeneratingReport((prev) => ({
  //       ...prev,
  //       isGenerating: true,
  //       reportSuccess: reportData?.data?.status,
  //     }));
  //     setCount((prevcount) => prevcount + 1);
  //     if (reportData?.data?.status === "Generating Full Report") {
  //       setGeneratingReport((prev) => ({
  //         ...prev,
  //         isGenerating: true,
  //         reportSuccess: false,
  //         download: false,
  //       }));
  //     } else if (reportData?.data?.url) {
  //       const url = reportData?.data?.url;
  //       const filename = reportData?.data?.file_name;
  //       clearInterval(interval.current);
  //       setGeneratingReport((prev) => ({
  //         ...prev,
  //         isGenerating: false,
  //         reportSuccess: true,
  //       }));
  //       showToast("Your Vasana type report is ready.", "success");
  //       dispatch({
  //         type: SET_API_DATA,
  //         payload: {
  //           downloadReport: {
  //             url,
  //             filename,
  //           },
  //         },
  //       });
  //     } else {
  //       clearInterval(interval.current);
  //       setGeneratingReport((prev) => ({
  //         ...prev,
  //         reportSuccess: null,
  //       }));
  //     }
  //   }
  // };

  useEffect(() => {
    if (
      length(keys(vasanaTypeAssessment)) &&
      vasanaTypeAssessment?.submission_id
    ) {
      getData(
        `assessment/${assessmentIds?.vasanaType}/result?submission_id=${vasanaTypeAssessment?.submission_id}`,
        "assessmentResult"
      );
      // generateReport();
    }
  }, [vasanaTypeAssessment]);

  // useEffect(() => {
  //   const { isGenerating, reportSuccess } = generatingReport;
  //   if (isGenerating && reportSuccess === false) {
  //     // interval.current = setInterval(generateReport, 5000);
  //   }
  // }, [generatingReport.reportSuccess]);

  const downloadReport = () => {
    // setGeneratingReport((prev) => ({
    //   ...prev,
    //   isGenerating: false,
    //   download: true,
    // }));
    dispatch({
      type: SET_API_DATA,
      payload: {
        generatingReport: {
          ...generatingReport,
          isGenerating: false,
          download: true,
        },
      },
    });

    fetch(downloadReportData?.url)
      .then((response) => response.blob())
      .then((blob) => {
        const a = document.createElement("a");
        const filename =
          downloadReportData?.filename.replace(/"/g, "") ||
          userRoleData?.user_profile?.given_name
            ? `${userRoleData?.user_profile?.given_name}'s Vasana Type Report.pdf`
            : downloadReportData?.filename.replace(/"/g, "");
        a.download = filename;
        const pdfBlobUrl = URL.createObjectURL(blob);
        a.href = pdfBlobUrl;
        a.click();
        // setGeneratingReport((prev) => ({
        //   ...prev,
        //   download: false,
        // }));
        dispatch({
          type: SET_API_DATA,
          payload: {
            generatingReport: {
              ...generatingReport,
              download: false,
            },
          },
        });
      });
  };

  const changeText = async () => {
    if (!downloadReportData) {
      // setGeneratingReport((prev) => ({
      //   ...prev,
      //   reportSuccess: null,
      //   download: true,
      // }));
      dispatch({
        type: SET_API_DATA,
        payload: {
          generatingReport: {
            ...generatingReport,
            reportSuccess: null,
            download: true,
          },
        },
      });
    }
  };
  // useEffect(() => {
  //   if (vasanaTypeAssessment?.pdf_exists) {
  //     setGeneratingReport((prev) => ({
  //       ...prev,
  //       reportSuccess: true,
  //     }));
  //   }
  // }, [vasanaTypeAssessment]);

  // useEffect(() => {
  //   if (!generatingReport?.isGenerating && !vasanaTypeAssessment?.pdf_exists) {
  //     setGeneratingReport((prev) => ({
  //       ...prev,
  //       reportSuccess: true,
  //     }));
  //   }
  // }, [generatingReport?.isGenerating]);

  const downloadAllReports = (value) => {
    setReportLoaders((prev) => ({
      ...prev,
      [value?.assessment_id]: true,
    }));

    fetch(value?.pdf_link)
      .then((response) => response.blob())
      .then((blob) => {
        const a = document.createElement("a");
        const filename =
          value?.pdf_name.replace(/"/g, "") ||
          userRoleData?.user_profile?.given_name
            ? value?.pdf_name
            : value?.pdf_name.replace(/"/g, "");
        a.download = filename;
        const pdfBlobUrl = URL.createObjectURL(blob);
        a.href = pdfBlobUrl;
        a.click();
        setReportLoaders((prev) => ({
          ...prev,
          [value?.assessment_id]: false,
        }));
      });
  };

  useEffect(() => {
    const { reportSuccess, download } = generatingReport;
    if (reportSuccess && download) {
      downloadReport();
    }
  }, [generatingReport?.reportSuccess]);

  useEffect(() => {
    if (count >= 36 && !generatingReport?.reportSuccess) {
      showToast();
      clearInterval(interval.current);
      setCount(0);
    }
  }, [count]);

  useEffect(() => {
    return () => {
      clearTimeout(interval.current);
    };
  }, []);

  const handleVideoPlay = (index) => {
    if (currentPlayingIndex === index) {
      setCurrentPlayingIndex(null);
    } else {
      setCurrentPlayingIndex(index);
    }
  };

  const handleAssessmentClick = ({ assessmentId, type }) => {
    localStorage.setItem("currentAssessmentId", assessmentId);
    const slug = getSlug(apiData?.assessmentList, assessmentId);
    navigate(`/get-started/${slug}`);
  };

  const getCountOfSlides = (size) => {
    const currentSize = Number(size);
    if (currentSize >= 0 && currentSize < 500) return 1;
    if (currentSize >= 500 && currentSize < 1200) return 2;
    if (currentSize >= 1200 && currentSize < 1400) return 3;
    return 4;
  };

  const onActiveIndexChange = (e) => {
    const currentSlides = getCountOfSlides(e?.currentBreakpoint);
    setActiveCards({
      start: e?.activeIndex - 1,
      end: e?.activeIndex + currentSlides - 1,
    });
  };

  const toggleReport = ({ assessmentId }) => {
    navigate(`/assessments/${assessmentId}`);
  };

  return {
    result,
    markerInfo,
    downloadReport,
    readMore,
    toggleReadMore,
    resultInfo,
    userRoleData,
    generatingReport,
    changeText,
    downloadReportData,
    currentPlayingIndex,
    handleVideoPlay,
    setCurrentPlayingIndex,
    vasanaTypeAssessment,
    introExtroAssessment,
    isiAssessment,
    onActiveIndexChange,
    activeCards,
    downloadAllReports,
    assessmentsSection,
    reportLoaders,
    handleAssessmentClick,
    toggleReport,
  };
};

export default vasanaTypeResultContainer;
